import React from 'react';
import deburr from 'lodash/deburr';
import { ListItem, ListItemText } from '@material-ui/core';

const AutocompleteItems = parent => {
  function handleClick(item) {
    parent.onSelectItem(item);
  }
  const inputValue = deburr(parent.contains.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  const items =
    !Array.isArray(parent.data) || inputLength === 0
      ? []
      : parent.data.filter(suggestion => {
          const keep = count < 5;
          if (keep) {
            count += 1;
          }

          return keep;
        });
  return items.map((item, index) => (
    <ListItem button key={`acItem-${index}`} onClick={() => handleClick(item)}>
      <ListItemText primary={item.value} />
    </ListItem>
  ));
};

export default AutocompleteItems;
