import React, { Component } from 'react';
import MaterialTable from 'material-table';

import PropTypes from 'prop-types';
import SortIcon from './SortIcon';

export class ReportTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { data, config, handleSort, sortType, sortTitle } = this.props;
    return (
      <MaterialTable
        options={{
          headerStyle: {
            backgroundColor: '#707070',
            color: '#ffffff',
            fontWeight: 'bold',
            fontSize: '1em',
          },
          paging: false,
          search: false,
          showTitle: false,
          toolbar: false,
          draggable: true,
        }}
        columns={config}
        data={data}
        title={<p className="material-table-header">Notes</p>}
        components={{
          Header: props => (
            <thead className="material-header-row">
              <tr>
                {config.map((column, index) => (
                  <td key={index} onClick={() => handleSort(column)} className={`${sortTitle === 'row' && 'header-disabled'} `}>
                    <p className={`${column.title === sortTitle ? 'selected' : ''}`}>
                      {column.title}
                      <span>
                        <SortIcon column={column.title} sortTitle={sortTitle} sortType={sortType} />
                      </span>
                    </p>
                  </td>
                ))}
              </tr>
            </thead>
          ),
        }}
      />
    );
  }
}

export default ReportTable;

ReportTable.propTypes = {
  data: PropTypes.array,
  config: PropTypes.array,
  handleSort: PropTypes.func,
  sortTitle: PropTypes.string,
  sortType: PropTypes.string,
};
