import React from 'react';
import { Link } from 'react-router-dom';

export const reportConfigs = {
  'Call Requests': [
    {
      title: 'Row',
      field: 'index',
      render: data => data.tableData.id + 1,
    },
    { title: 'Sender', field: 'sender' },
    { title: 'Receiver', field: 'receiver' },
    { title: 'Call Request Sent', field: 'startdatetime' },
    { title: 'Status', field: 'status' },
    { title: 'Call Request Response', field: 'enddatetime' },
  ],
  'Voice Calling': [
    {
      title: 'Row',
      field: 'index',
      render: data => data.tableData.id + 1,
    },
    { title: 'Sender', field: 'sender' },
    { title: 'Receiver', field: 'receiver' },
    { title: 'Call Placed', field: 'startdatetime' },
    { title: 'Status', field: 'status' },
    { title: 'Duration', field: 'duration' },
    { title: 'Call Ended', field: 'enddatetime' },
  ],
  Messaging: [
    {
      title: 'Row',
      field: 'index',
      render: data => data.tableData.id + 1,
    },
    { title: 'Member 1', field: 'sender' },
    { title: 'Member 2', field: 'receiver' },
    { title: 'Conversation Started', field: 'startdatetime' },
    { title: 'Most Recent Message', field: 'enddatetime' },
    { title: 'Message Count', field: 'messagecount', render: data => <Link to={{ pathname: `/reports/Conversation Details/${data.messageid}` }}>{data.messagecount}</Link> },
  ],
  'VCard Sharing': [
    {
      title: 'Row',
      field: 'index',
      render: data => data.tableData.id + 1,
    },
    { title: 'Sender', field: 'sender' },
    { title: 'Receiver', field: 'receiver' },
    { title: 'VCard Sent', field: 'startdatetime' },
    { title: 'Status', field: 'status' },
    { title: 'VCard Response', field: 'enddatetime' },
  ],
  'Broadcast Messages': [
    {
      title: 'Row',
      field: 'index',
      render: data => data.tableData.id + 1,
    },
    { title: 'Content', field: 'content' },
    { title: 'Recipient Count', field: 'recipientcount', render: data => <Link to={{ pathname: `/reports/Broadcast Details/${data.broadcastmessageid}` }}>{data.recipientcount}</Link> },
    { title: 'Sent Date', field: 'startdatetime' },
  ],
};

export const CSVConfig = (data, reportType) => {
  let csvData = [];
  switch (reportType) {
    case 'Call Requests':
      csvData = data.map(row => ({
        Sender: row.sender,
        Receiver: row.receiver,
        'Call Request Sent': row.startdatetime,
        Status: row.status,
        'Call Request Response': row.enddatetime,
      }));
      break;
    case 'Voice Calling':
      csvData = data.map(row => ({
        Sender: row.sender,
        Receiver: row.receiver,
        'Call Placed': row.startdatetime,
        Status: row.status,
        Duration: row.duration,
        'Call Ended': row.enddatetime,
      }));
      break;
    case 'Messaging':
      csvData = data.map(row => ({
        'Member 1': row.sender,
        'Member 2': row.receiver,
        'Conversation Started': row.startdatetime,
        'Most Recent Message': row.enddatetime,
        'Message Count': row.messagecount,
      }));
      break;
    case 'VCard Sharing':
      csvData = data.map(row => ({
        Sender: row.sender,
        Receiver: row.receiver,
        'VCard Sent': row.startdatetime,
        Status: row.status,
        'Response Date': row.enddatetime,
      }));
      break;
    case 'Broadcast Messages':
      csvData = data.map(row => ({
        'Message Content': row.content,
        'Recipient Count': row.recipientcount,
        'Conversation Started': row.startdatetime,
      }));
      break;
    case 'Conversation Details':
      csvData = data.map(row => ({
        Sender: row.sender,
        Receiver: row.receiver,
        Sent: row.startdatetime,
      }));
      break;
    case 'Broadcast Details':
      csvData = data.map(row => ({
        Receiver: row.receiver,
      }));
      break;
    default:
      break;
  }
  return csvData;
};

export const conversationDetailsConfig = [
  {
    title: 'Row',
    field: 'index',
    render: data => data.tableData.id + 1,
  },
  { title: 'Sender', field: 'sender' },
  { title: 'Receiver', field: 'receiver' },
  { title: 'Sent', field: 'startdatetime' },
];
export const broadcastDetailsConfig = [
  {
    title: 'Row',
    field: 'index',
    render: data => data.tableData.id + 1,
  },
  { title: 'Receiver', field: 'receiver' },
];
