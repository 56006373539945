import { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { ConversationQueries } from '../../queries/Conversation';
import AuthHelper from '../../services/AuthHelper';

class ConversationsProps extends Component {
  _intervalId = 0;

  constructor(props) {
    super(props);
    this.state = {
      sub: '',
      loading: false,
      options: undefined,
      data: [],
    };
  }

  async componentDidMount() {
    const sub = await AuthHelper.getUserSub();
    this.setState({ sub }, () => {
      this.fetchConversations();
      this._intervalId = setInterval(() => this.fetchConversations(), 5000);
    });
  }

  componentDidUpdate(prevProps) {
    const { contains, refresh, selectedConversationKey } = this.props;
    if (contains !== prevProps.contains || refresh !== prevProps.refresh) {
      this.fetchConversations();
    }
    if (selectedConversationKey !== prevProps.selectedConversationKey && selectedConversationKey > 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(prevState => ({
        data: prevState.data.map(el => (el.conversationkey === selectedConversationKey ? { ...el, selected: true } : { ...el, selected: false })),
      }));
    }
  }

  componentWillUnmount() {
    clearInterval(this._intervalId);
  }

  async fetchConversations() {
    const { client, contains, conversationType } = this.props;
    const { sub } = this.state;

    this.setState({ loading: true });
    const conversations = await client.query({
      query: ConversationQueries.GetConversations,
      variables: { sub, contains, conversationtype: conversationType },
    });

    this.setState({
      loading: false,
      data: conversations.data.getConversations,
    });
  }

  // eslint-disable-next-line react/prop-types
  // eslint-disable-next-line react/destructuring-assignment
  render = () => this.props.children({ ...this.props, ...this.state });
}

ConversationsProps.propTypes = {
  client: PropTypes.object.isRequired,
  contains: PropTypes.string,
  children: PropTypes.any,
  refresh: PropTypes.bool,
  conversationType: PropTypes.string,
  selectedConversationKey: PropTypes.number,
};

export default withApollo(ConversationsProps);
