import gql from 'graphql-tag';

const SendMessage = gql`
  mutation MessageMutation($conversationkey: Int!, $sub: String!, $content: String!, $type: String) {
    sendMessage(conversationkey: $conversationkey, sub: $sub, content: $content, type: $type)
  }
`;

export const MessageMutations = {
  SendMessage,
};
