import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dropzone from 'react-dropzone';
import { withSnackbar } from 'notistack';
import { withApollo } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Paper } from '@material-ui/core';
import { DocumentMutations } from '../../mutations/Document';
import { DocumentQueries } from '../../queries/Document';
import S3Helper from '../../services/S3Helper';
import AuthHelper from '../../services/AuthHelper';
import DocumentPhoto from '../DocumentPhoto/DocumentPhoto';
import * as SchemaConstants from '../../services/schemaConstants';

const uuidv4 = require('uuid/v4');

class EntityPhotoManager extends Component {
  _defaultState = {
    photos: [],
    photoSelections: [],
    uploading: false,
    uploaded: true,
    dialogOpen: false,
    deleteDialogOpen: false,
  };

  constructor(props) {
    super(props);

    this.state = this._defaultState;
  }

  async componentDidMount() {
    this.fetchEntityPhotos();
    const identityId = await AuthHelper.getIdentityId();
    this.setState({ identityId });
  }

  componentDidUpdate(prevProps) {
    const { entitykey } = this.props;
    if (entitykey !== prevProps.entitykey) {
      this.fetchEntityPhotos();
      return true;
    }
    return false;
  }

  fetchEntityPhotos = async () => {
    const { client, entitykey, entitytype } = this.props;

    if (entitykey === 0) {
      this.setState(this._defaultState);
      return;
    }
    try {
      const result = await client.query({
        query: DocumentQueries.GetEntityImages,
        variables: { entitykey, entitytype },
      });

      this.setState({ photos: result.data.getEntityImages });
    } catch (error) {
      this.showError('Error Fetching Entity Detail');
    }
  };

  openDialog = () => {
    this.setState({ dialogOpen: true, uploading: false, uploaded: false });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false, photoSelections: [], uploading: false, uploaded: false });
  };

  setPhotoSelections = photos => {
    this.setState({ photoSelections: photos });
  };

  uploadPhotos = async () => {
    const { client, entitykey, entitytype, sub } = this.props;
    const { photoSelections, identityId } = this.state;

    let uploadSuccessful = false;
    try {
      this.setState({ uploading: true });

      await Promise.all(
        photoSelections.map(async file => {
          const filename = `${uuidv4()}.${file.name.split('.')[1]}`;
          const params = {
            name: filename,
            object: file,
            path: file.preview,
            access: 'protected',
            identityId,
            progressFunc: null,
          };

          const uploadResult = await S3Helper.putObject(params);

          if (uploadResult) {
            const entityImageArgs = {
              entitykey,
              entitytype,
              sub,
              name: filename,
              accesslevel: 'protected',
              isbanner: false,
            };

            const result = await client.mutate({
              mutation: DocumentMutations.UploadDocument,
              variables: entityImageArgs,
            });

            uploadSuccessful = result.data.uploadEntityImage > 0;
          }
        })
      );
    } catch (error) {
      this.setState({ uploading: false });
      this.showError('Error Uploading Photo');
    }

    this.setState({ uploading: false, photoSelections: [] });

    if (uploadSuccessful) {
      this.setState({ uploaded: true });
    }

    this.fetchEntityPhotos();
  };

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'success' });
  };

  confirmDeletePhoto = documentkey => {
    this.setState({ photoToDelete: documentkey, deleteDialogOpen: true });
  };

  closeDeleteDialog = () => {
    this.setState({ photoToDelete: null, deleteDialogOpen: false });
  };

  deletePhoto = async () => {
    const { client } = this.props;
    const { photoToDelete } = this.state;

    try {
      const result = await client.mutate({
        mutation: DocumentMutations.DeleteDocument,
        variables: { documentkey: photoToDelete },
      });

      if (result && result !== undefined && result.data && result.data !== undefined && result.data.deleteDocument && result.data.deleteDocument !== undefined) {
        if (result.data.deleteDocument.wassuccessful === true) {
          this.closeDeleteDialog();
          this.showSuccess(`Photo Deleted`);
          this.fetchEntityPhotos();
        } else {
          throw new Error('Invalid API response');
        }
      }
    } catch (error) {
      this.closeDeleteDialog();
      this.showError('Error Deleting Photo');
    }
  };

  render() {
    const { photos, dialogOpen, deleteDialogOpen, photoSelections, uploading, uploaded } = this.state;
    const { title } = this.props;

    let emptyPhotoText = null;

    if (!photos || photos === undefined || photos.length === 0) {
      emptyPhotoText = <div className="photo-empty-text">No photos have been added yet</div>;
    }

    let dialogTitleFeedback = null;

    if (uploading === true) {
      dialogTitleFeedback = <CircularProgress className="event-photo-upload-feedback" size={20} />;
    } else if (uploaded === true) {
      dialogTitleFeedback = <DoneIcon className="event-photo-upload-feedback" />;
    }

    return (
      <Paper className="paper-base">
        <form className="event-photos-card">
          <div className="row section">
            <h5 id="photos">{title}</h5>
            <Dialog open={dialogOpen} onClose={this.closeDialog}>
              <DialogTitle id="photo-dialog-title">Upload News Photos {dialogTitleFeedback}</DialogTitle>
              <DialogContent dividers>
                <Dropzone
                  accept="image/*"
                  onDrop={acceptedFiles =>
                    this.setPhotoSelections(
                      acceptedFiles.map(file =>
                        Object.assign(file, {
                          preview: URL.createObjectURL(file),
                        })
                      )
                    )
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="photo-drop-container">
                      <input {...getInputProps()} />
                      <p>Drag and drop photos here, or click to select files</p>
                    </div>
                  )}
                </Dropzone>

                <div className="preview-container">
                  {photoSelections.map(photo => (
                    <img src={photo.preview} className="event-preview-image" alt="upload-preview" />
                  ))}
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeDialog} className="event-dialog-button">
                  Cancel
                </Button>
                <Button onClick={this.uploadPhotos} className="event-dialog-button primary" color="primary" disabled={!(photoSelections && photoSelections.length > 0) || uploading === true}>
                  Upload
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={deleteDialogOpen} onClose={this.closeDeleteDialog}>
              <DialogTitle>Delete News Photo</DialogTitle>
              <DialogContent dividers>
                <DialogContentText className="event-dialogue-text">Are you sure you want to delete this photo?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeDeleteDialog} className="event-dialog-button">
                  Cancel
                </Button>
                <Button onClick={this.deletePhoto} color="primary" className="event-dialog-button error">
                  Delete
                </Button>
              </DialogActions>
            </Dialog>

            <Tooltip title="Add Photo">
              <IconButton variant="contained" component="span" className="plus-button" onClick={this.openDialog}>
                <AddBoxIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </div>

          {emptyPhotoText}

          <div className="event-image-gallery">
            {photos.map(photo => (
              <div className="event-photo-container" key={photo.documentkey.toString()}>
                <DocumentPhoto document={photo} />
                <Tooltip title="Delete Photo">
                  <IconButton variant="contained" component="span" className="event-photo-delete-button" onClick={() => this.confirmDeletePhoto(photo.documentkey)}>
                    <DeleteForeverIcon fontSize="inherit" className="photo-delete-icon" />
                  </IconButton>
                </Tooltip>
              </div>
            ))}
          </div>
        </form>
      </Paper>
    );
  }
}

export default withSnackbar(withApollo(EntityPhotoManager));

EntityPhotoManager.propTypes = {
  client: PropTypes.object.isRequired,
  entitykey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  entitytype: PropTypes.oneOf([SchemaConstants.GLOBAL_SEARCH_TYPE_ASSET, SchemaConstants.GLOBAL_SEARCH_TYPE_EVENT, SchemaConstants.GLOBAL_SEARCH_TYPE_NEWS]).isRequired,
  sub: PropTypes.string.isRequired,
  title: PropTypes.string,
  enqueueSnackbar: PropTypes.func.isRequired,
};
