import React from 'react';
import moment from 'moment';
import { Paper, Typography } from '@material-ui/core';

const ConversationItems = parent => {
  function handleClick(item) {
    parent.getConversation(item);
  }

  return parent.data.map((item, index) => (
    <Paper key={index} className={`paper-base two-col-item-container ${item.selected ? 'paper-selected' : ''}`} onClick={() => handleClick(item)}>
      <div className="two-column details">
        <ul className="title-date">
          <li className="title">{item.lastmessage.fromname}</li>
          <li className="date">{moment(item.lastmessage.createddate).format('MMM D h:mm a')}</li>
        </ul>
        <Typography className="content" component="p">
          {item.lastmessage.content}
        </Typography>
      </div>
    </Paper>
  ));
};

export default ConversationItems;
