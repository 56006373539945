import gql from 'graphql-tag';

const CreatePerson = gql`
  mutation($input: NewPersonInput!) {
    createPerson(input: $input) {
      personkey
      userkey
      personstatuskey
      firstname
      lastname
      company
      city
      country
      industry
      status
    }
  }
`;

const SetMemberDetails = gql`
  mutation($input: PersonInput!) {
    setMemberDetails(input: $input) {
      isdisabled
      personkey
      userkey
      firstname
      lastname
      company
      city
      state
      country
      about
      industry
      address
      memberstartdate
      memberexpiredate
      showinmobiledirectory
      dateofbirth
      islefthanded
      postalcode
      status
      phone
      email
      contactpreference
      passportnumber
      passportissuedate
      passportexpirydate
      gender
      bcc1
      bcc2
      user {
        sub
        deviceid
      }
    }
  }
`;

const DeletePerson = gql`
  mutation($personkey: Int!) {
    deletePerson(personkey: $personkey) {
      wassuccessful
      error
    }
  }
`;

export const PersonMutations = {
  SetMemberDetails,
  CreatePerson,
  DeletePerson,
};
