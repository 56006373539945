import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { Button, TextField, FormControl, Paper } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Done from '@material-ui/icons/Done';
import Publish from '@material-ui/icons/Publish';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DocumentMutations } from '../../mutations/Document';
import { NewsMutations } from '../../mutations/News';
import S3Helper from '../../services/S3Helper';
import DocumentPhoto from '../../components/DocumentPhoto/DocumentPhoto';
import { NewsQueries } from '../../queries/News';

const uuidv4 = require('uuid/v4');

class NewsForm extends Component {
  _defaultState = {
    file: undefined,
    fileUrl: '',
    form: {
      title: '',
      content: '',
    },
    featuredToDelete: null,
    isValidated: 'pending',
    ispublished: false,
    loading: false,
    showConfirmationDialog: false,
  };

  constructor(props) {
    super(props);
    this.state = this._defaultState;
  }

  async componentDidMount() {
    this.fetchNewsItem();
  }

  componentDidUpdate(prevProps) {
    const { newsKey } = this.props;
    if (newsKey !== prevProps.newsKey) {
      this.fetchNewsItem();
      return true;
    }
    return false;
  }

  closeConfirmDialog = () => {
    this.setState({ showConfirmationDialog: false });
  };

  async fetchNewsItem() {
    try {
      this.setState({ loading: true });

      const { client, newsKey } = this.props;

      if (newsKey === 0) {
        this.setState(this._defaultState);
        return;
      }

      const news = await client.query({
        query: NewsQueries.GetNewsById,
        variables: { newskey: newsKey },
      });

      const newsItem = news.data.getNewsById;

      if (newsItem) {
        this.setState({ form: { ...newsItem }, loading: false });
        if (!newsItem.photo) {
          this.setState({ fileUrl: '' });
        }
      } else {
        throw new Error('No news item returned');
      }
    } catch (error) {
      console.log('Error fetching news article: ', error);
      const { snackbar } = this.props;
      snackbar('Error Loading News Article', 'error');
    }
  }

  validateForm = () => {
    const { form, file } = this.state;
    if (form.title.length > 0 && form.content.length > 0) {
      this.setState({ isValidated: true });
    } else {
      this.setState({ isValidated: false });
    }
  };

  handleValueChange = event => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      form: { ...prevState.form, [name]: value },
    }));
  };

  setFeatured = event => {
    const { files } = event.target;
    const file = files[0];
    this.setState({ file, fileUrl: URL.createObjectURL(file) });
  };

  resetFeatured = event => {
    event.preventDefault();
    const {
      form: { photo },
    } = this.state;
    this.setState(prevState => ({
      file: undefined,
      fileUrl: '',
      form: { ...prevState.form, photo: undefined },
      featuredToDelete: { ...photo },
    }));
  };

  onCancel = event => {
    event.preventDefault();
    const { newsKey } = this.props;
    if (newsKey > 0) {
      this.fetchNewsItem();
    } else {
      this.setState(this._defaultState);
    }
  };

  onDelete = event => {
    event.preventDefault();
    this.setState({ loading: true });
    this.deleteNews();
  };

  async uploadPhoto() {
    const { file, fileUrl } = this.state;
    const { identityId, snackbar } = this.props;
    const photo = {
      name: `${uuidv4()}.${file.name.split('.')[1]}`,
      object: file,
      path: fileUrl,
      access: 'protected',
      identityId,
      progressFunc: null,
    };

    const uploadedToS3 = await S3Helper.putObject(photo);

    if (!uploadedToS3) {
      snackbar('There was an error uploading your image.', 'error');
    }

    return photo;
  }

  async createNews() {
    const { form } = this.state;
    const { client, handleFormChange } = this.props;
    const res = await client.mutate({
      mutation: NewsMutations.CreateNews,
      variables: { title: form.title, content: form.content, isactive: true },
    });
    handleFormChange();
    return res.data.createNews;
  }

  async updateNews() {
    const { form } = this.state;
    const { client, newsKey, handleFormChange } = this.props;
    const result = await client.mutate({
      mutation: NewsMutations.EditNewsById,
      variables: { title: form.title, content: form.content, newskey: newsKey, isactive: true },
    });

    handleFormChange();

    return result.data.editNewsById;
  }

  async deleteNews() {
    const { form } = this.state;
    const { client, newsKey, handleFormChange, setNewsKey, snackbar } = this.props;

    const result = await client.mutate({
      mutation: NewsMutations.EditNewsById,
      variables: { title: form.title, content: form.content, newskey: newsKey, isactive: false },
    });

    if (result && result.data && result.data.editNewsById && result.data.editNewsById.wassuccessful) {
      snackbar('News Deleted', 'success');

      this.setState(this._defaultState, () => {
        setNewsKey(0);
        handleFormChange();
      });
    } else {
      snackbar('Error Deleting News', 'error');
    }
  }

  async createFeaturedImage(newsKey, featured) {
    const { file } = this.state;
    const { client, sub, handleFormChange } = this.props;
    const result = await client.mutate({
      mutation: DocumentMutations.UploadDocument,
      variables: {
        entitykey: newsKey,
        entitytype: 'News',
        name: featured.name,
        displayname: 'Featured Image',
        accesslevel: 'protected',
        isbanner: true,
        sub,
      },
    });

    handleFormChange();

    return result.data.uploadDocument;
  }

  async deleteFeatured() {
    const { featuredToDelete } = this.state;
    const { client, handleFormChange } = this.props;

    const result = await client.mutate({
      mutation: DocumentMutations.DeleteDocument,
      variables: {
        documentkey: featuredToDelete.documentkey,
      },
    });

    return result.data.deleteDocument;
  }

  onSubmit = async event => {
    const { snackbar, newsKey, setNewsKey, handleFormChange } = this.props;

    try {
      this.setState({ loading: true });
      event.preventDefault();

      await this.validateForm();

      const { isValidated, file, featuredToDelete, form } = this.state;
      let featured = null;

      if (!isValidated) {
        snackbar('A subject and message are required.', 'error');
        this.setState({ loading: false });
        return;
      }

      if (file !== undefined) {
        featured = await this.uploadPhoto();
      }

      if (newsKey === 0) {
        const result = await this.createNews();

        if (result && result.wassuccessful) {
          this.setState({ loading: false }, () => {
            setNewsKey(result.key);
          });
          snackbar('News Saved', 'success');

          if (file) {
            const photoResult = await this.createFeaturedImage(result.key, featured);

            if (!photoResult || !photoResult.wassuccessful) {
              snackbar('Error Uploading Featured Image', 'error');
            }
          }
          this.fetchNewsItem();
        } else {
          throw new Error('Save Not Successful');
        }
      } else {
        const result = await this.updateNews();

        if (result && result.wassuccessful) {
          this.setState({ loading: false });
          snackbar('News Saved', 'success');

          if (file) {
            if (!form.photo) {
              const photoResult = await this.createFeaturedImage(newsKey, featured);

              if (!photoResult || !photoResult.wassuccessful) {
                snackbar('Error Uploading Featured Image', 'error');
              }
            } else if (featuredToDelete && form.photo) {
              const photoResult = await this.createFeaturedImage(newsKey, featured);

              if (!photoResult || !photoResult.wassuccessful) {
                snackbar('Error Uploading Featured Image', 'error');
              }
            }
          }
          if (featuredToDelete) {
            const deletedResult = await this.deleteFeatured();
            if (!deletedResult || !deletedResult.wassuccessful) {
              snackbar('Error deleting featured photo', 'error');
            } else {
              await this.setState({ featuredToDelete: null });
              handleFormChange();
            }
          }
          this.fetchNewsItem();
        } else {
          throw new Error('Save Not Successful');
        }

        this.setState({ loading: false });
      }
    } catch (error) {
      console.log('Error saving news');
      this.setState({ loading: false });
      snackbar('Error Saving News Article.', 'error');
    }
  };

  publish = async () => {
    const { newsKey, snackbar, client, handleFormChange } = this.props;

    try {
      this.setState({ loading: true });
      const result = await client.mutate({
        mutation: NewsMutations.PublishNews,
        variables: { newskey: newsKey },
      });

      if (result && result.data && result.data.publishNews && result.data.publishNews.wassuccessful) {
        snackbar('News Article Published', 'success');
        const { form } = this.state;

        form.ispublished = true;

        this.setState({ loading: false, form });
        handleFormChange();
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log('Error publishing news: ', error);
      snackbar('Error Publishing News', 'error');
    }
  };

  renderPublishSection = () => {
    const { newsKey } = this.props;
    const { loading } = this.state;

    if (!newsKey) {
      return null;
    }

    const { form } = this.state;

    if (form.ispublished) {
      return (
        <div className="flex mb-1">
          <Done style={{ color: 'green' }} fontSize="inherit" /> &nbsp; News Published
        </div>
      );
    }
    return (
      <div className="flex mb-1">
        <Button color="secondary" variant="contained" component="label" disabled={loading} onClick={this.publish}>
          Publish to Mobile App
          <Publish className="ml-1" />
        </Button>
      </div>
    );
  };

  renderFeaturedPhotoSection = () => {
    const { form, fileUrl, loading } = this.state;

    if (form.photo || fileUrl) {
      return (
        <div className="mb-1">
          <div className="thumbnail mb-1 flex">
            {form.photo ? <DocumentPhoto document={form.photo} defaultPhoto="generic" carouselDisabled /> : <img src={fileUrl} alt="featured" className="thumbnail" />}
          </div>
          <p className="mb-1" style={{ color: 'red' }}>
            Please upload horizontal photos only
          </p>
          <div className="flex">
            <Button color="primary" variant="outlined" onClick={this.resetFeatured} disabled={loading}>
              Remove Featured Image
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div>
        <p className="mb-1" style={{ color: 'red' }}>
          Please upload horizontal photos only
        </p>
        <div className="flex mb-1">
          <Button color="secondary" variant="contained" component="label" disabled={loading}>
            Upload Featured Image
            <PhotoCamera className="ml-1" />
            <input type="file" hidden accept="image/*" onChange={this.setFeatured} />
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { form, fileUrl, file, isValidated, loading, showConfirmationDialog } = this.state;
    const { newsKey } = this.props;

    return (
      <React.Fragment>
        <Paper className="paper-base">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <h5 id="photos">{newsKey ? 'Edit News Article' : 'Create News Article'}</h5>
            {loading ? <CircularProgress size={20} /> : null}
          </div>
          <br />
          <form noValidate autoComplete="off">
            <div className="flex mb-1">
              <TextField
                className="flex-1"
                name="title"
                label="Subject"
                value={form.title}
                onChange={this.handleValueChange}
                error={!isValidated && !form.title}
                helperText={!isValidated && !form.title && 'News title is required'}
                required
              />
            </div>
            <div className="flex mb-1">
              <FormControl className="flex-100">
                <TextField
                  name="content"
                  label="Write a message..."
                  multiline
                  fullWidth
                  onChange={this.handleValueChange}
                  value={form.content}
                  error={!isValidated && !form.content}
                  helperText={!isValidated && !form.content && 'News message is required'}
                  required
                />
              </FormControl>
            </div>
            <br />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
              {this.renderFeaturedPhotoSection()}
              {this.renderPublishSection()}
            </div>
            <br />
            <div className="flex justify-between">
              {newsKey ? (
                <Button color="primary" variant="outlined" onClick={() => this.setState({ showConfirmationDialog: true })} disabled={loading}>
                  Delete
                </Button>
              ) : null}
              <div className="flex flex-1 justify-end">
                <Button color="primary" variant="outlined" onClick={this.onCancel}>
                  Reset
                </Button>
                <div className="ml-1">
                  <Button color="primary" variant="contained" onClick={this.onSubmit} disabled={loading}>
                    {newsKey ? 'Save' : 'Create'}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Paper>
        <Dialog open={showConfirmationDialog} onClose={this.closeConfirmDialog}>
          <DialogTitle>Delete News Article</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>Are you sure you want to delete this news article?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeConfirmDialog}>Cancel</Button>
            <Button onClick={this.onDelete} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

NewsForm.propTypes = {
  client: PropTypes.object.isRequired,
  newsKey: PropTypes.number.isRequired,
  snackbar: PropTypes.func,
  identityId: PropTypes.string.isRequired,
  sub: PropTypes.string.isRequired,
  handleFormChange: PropTypes.func,
  setNewsKey: PropTypes.func.isRequired,
};

export default withApollo(NewsForm);
