import Assets from './Assets/Assets';
import Dietary from './Dietary/Dietary';
import Documents from './Documents/Documents';
import MemberEvents from './MemberEvents/MemberEvents';
import Interests from './Interests/Interests';
import Measurements from './Measurements/Measurements';
import Notes from './Notes/Notes';
import PersonalInfo from './PersonalInfo/PersonalInfo';
import VCard from './VCard/VCard';

const MemberDetailsForm = {
  Assets,
  Dietary,
  Documents,
  MemberEvents,
  Interests,
  Measurements,
  PersonalInfo,
  Notes,
  VCard,
};

export default MemberDetailsForm;
