import gql from 'graphql-tag';

const createPollOption = gql`
  mutation PollOptionMutation($input: PollOptionInput!) {
    createPollOption(input: $input)
  }
`;

const updatePollOption = gql`
  mutation PollOptionMutation($input: PollOptionInput!) {
    updatePollOption(input: $input)
  }
`;

const deletePollOption = gql`
  mutation PollOptionMutation($polloptionkey: Int!) {
    deletePollOption(polloptionkey: $polloptionkey)
  }
`;

export const PollOptionMutations = {
  createPollOption,
  updatePollOption,
  deletePollOption,
};
