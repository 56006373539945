import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Subheader extends Component {
  render() {
    const { title, displayBlock } = this.props;
    return <div className={`subheader-container ${displayBlock ? 'main-header' : 'secondary-header'}`}>{<h1>{title}</h1>}</div>;
  }
}

Subheader.propTypes = {
  title: PropTypes.string,
  displayBlock: PropTypes.bool,
};
