import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withSnackbar } from 'notistack';
import Moment from 'moment';
import { Paper, Checkbox, FormControlLabel, Button, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import { PollQueries } from '../../queries/Poll';
import PollOptionForm from './PollOptionForm';
import { PollMutations } from '../../mutations/Poll';
import MaterialDatePicker from '../DatePicker';

class PollForm extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  initialState = () => ({
    loading: false,
    types: [{ key: 'Multiple.Single', label: 'Multiple options, single select.' }, { key: 'Multiple.Multiple', label: 'Multiple options. multiple select.' }],
    form: {
      pollkey: 0,
      title: '',
      question: '',
      type: { key: '', label: '' },
      closeddate: new Date(),
      ispublished: false,
    },
    isValidated: false,
  });

  componentDidMount() {
    this._isMounted = true;
    this.getPoll();
  }

  componentDidUpdate(prevProps) {
    const { pollKey } = this.props;
    if (pollKey !== prevProps.pollKey) {
      this.getPoll();
    }
  }

  async getPoll() {
    const { client, pollKey } = this.props;
    const { types } = this.state;
    if (pollKey === 0) {
      this.setState(this.initialState);
      return;
    }

    const poll = await client.query({
      query: PollQueries.getPoll,
      variables: { pollkey: pollKey },
    });

    if (this._isMounted) {
      const form = { ...poll.data.getPoll };
      const typeKey = poll.data.getPoll.type;
      form.closeddate = new Date(poll.data.getPoll.closeddate);
      form.type = types.find(x => x.key === typeKey);
      this.setState({ form }, () => this.validateForm());
    }
  }

  handleValueChange = event => {
    const { name, value } = event.target;
    this.setState(prevState => ({ form: { ...prevState.form, [name]: value } }), () => this.validateForm());
  };

  handleCheckedChange = event => {
    const { name, checked } = event.target;
    this.setState(prevState => ({
      form: { ...prevState.form, [name]: checked },
    }));
  };

  handleTypeClick = item => {
    this.setState(
      prevState => ({
        form: { ...prevState.form, type: item.target.value },
      }),
      () => this.validateForm()
    );
  };

  handleClosedDate = selectedDate => {
    this.setState(prevState => ({ form: { ...prevState.form, closeddate: new Date(selectedDate) } }));
  };

  validateForm = () => {
    let isValidated = false;
    const { form } = this.state;
    if (form.title.length > 0 && form.question.length > 0 && form.type.key.length > 0) {
      isValidated = true;
    }
    // eslint-disable-next-line react/no-unused-state
    this.setState({ isValidated });
    return isValidated;
  };

  async creatPoll(input) {
    const { client, onSaved } = this.props;
    const response = await client.mutate({
      mutation: PollMutations.createPoll,
      variables: { input },
    });
    const pollKey = response.data.createPoll;

    onSaved(pollKey);
    this.setState(prevState => ({ form: { ...prevState.form, pollkey: pollKey } }));
  }

  async updatePoll(input) {
    const { client } = this.props;
    await client.mutate({
      mutation: PollMutations.updatePoll,
      variables: { input },
    });
  }

  handleSubmit = async event => {
    const { enqueueSnackbar } = this.props;

    event.preventDefault();
    if (!this.validateForm()) {
      enqueueSnackbar('A poll title, question, and type are required', { variant: 'error' });
      return;
    }

    const { pollKey } = this.props;
    const { form } = this.state;
    const input = {
      title: form.title,
      question: form.question,
      type: form.type.key,
      closeddate: Moment(form.closeddate).format('YYYY-MM-DD HH:mm:ss'),
      ispublished: form.ispublished,
    };
    if (pollKey === 0) {
      await this.creatPoll(input);
      enqueueSnackbar('Poll created.', { variant: 'success' });
      return;
    }

    input.pollkey = pollKey;
    await this.updatePoll(input);
    enqueueSnackbar('Poll updated.', { variant: 'success' });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { form, types } = this.state;
    const { pollKey } = this.props;
    return (
      <div>
        <form onSubmit={this.handleSubmit} autoComplete="off">
          <Paper className="paper-base">
            <div className="form-card">
              <FormControl className="form-control">
                <TextField id="title" name="title" label="Title *" value={form.title} onChange={this.handleValueChange} />
              </FormControl>
            </div>
          </Paper>
          <Paper className="paper-base">
            <div className="form-card">
              <FormControl className="form-control">
                <TextField id="question" name="question" label="Question *" value={form.question} onChange={this.handleValueChange} />
              </FormControl>
            </div>
          </Paper>
          <Paper className="paper-base">
            <div className="form-card">
              <FormControl className="form-control">
                <InputLabel htmlFor="type">Poll Type *</InputLabel>
                <Select
                  value={form.type}
                  onChange={this.handleTypeClick}
                  disabled={pollKey > 0}
                  inputProps={{
                    name: 'type',
                    id: 'type',
                  }}
                >
                  {types.map(item => (
                    <MenuItem key={item.key} value={item}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className="form-control">
                <MaterialDatePicker onChangeFunc={this.handleClosedDate} value={form.closeddate} label="Close Date *" required />
              </FormControl>
              <FormControl className="form-control">
                <FormControlLabel name="ispublished" checked={form.ispublished} onChange={this.handleCheckedChange} control={<Checkbox color="primary" />} label="Publish" labelPlacement="top" />
              </FormControl>
            </div>
            <div className="form-submit-row">
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </div>
          </Paper>
          <Paper className="paper-base">
            <h4 className="form-card-header">Response Options:</h4>
            <PollOptionForm pollKey={pollKey} pollType={form.type} />
          </Paper>
        </form>
      </div>
    );
  }
}

PollForm.propTypes = {
  client: PropTypes.object.isRequired,
  pollKey: PropTypes.number.isRequired,
  onSaved: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func,
};

export default withSnackbar(withApollo(PollForm));
