import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withSnackbar } from 'notistack';
import { TextField, Button, Paper, MenuItem, Menu, MenuList } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PersonQueries } from '../../../queries/Person';
import { PersonMutations } from '../../../mutations/Person';
import InputHelper from '../../../helpers/InputHelper';
import { countryToCCA } from '../../../helpers/countryHelper';

const fields = {
  vcardfirstname: { title: 'First Name', required: true },
  vcardlastname: { title: 'Last Name', required: true },
  vcardaddress: { title: 'Company Address', required: false },
  vcardcity: { title: 'City', required: false },
  vcardstate: { title: 'State', required: false },
  vcardcountry: { title: 'Country', required: false },
  vcardpostalcode: { title: 'Postal Code', required: false },
  vcardemail: { title: 'Email', required: true },
  vcardphone: { title: 'Company Phone', required: true },
  vcardassistantemail: { title: 'Assistant Email', required: false },
  vcardassistantphone: { title: 'Assistant Phone', required: false },
  vcardassistantname: { title: 'Assistant Name', required: false },
  vcardmobilephone: { title: 'Mobile Phone', required: false },
};

const emptyErrorSet = {};
Object.keys(fields).forEach(key => {
  emptyErrorSet[key] = null;
});

class VCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: { ...emptyErrorSet },
      initialCard: {},
      isSaving: false,
      loading: true,
      vCard: {},
    };
  }

  componentDidMount = () => {
    this.fetchVCard();
  };

  fetchVCard = async () => {
    const { client, personkey } = this.props;
    this.setState({ loading: true });

    try {
      const result = await client.query({
        query: PersonQueries.GetMemberDetails,
        variables: { personkey },
      });

      if (result && result !== undefined) {
        const { data } = result;

        if (data && data !== undefined && data.getMemberDetails && data.getMemberDetails !== undefined) {
          const memberDetails = data.getMemberDetails;
          const fetchedVCard = this.vCardReducer(memberDetails);
          this.setState({
            vCard: fetchedVCard,
            initialCard: fetchedVCard,
            loading: false,
          });
        } else {
          console.log('Unexpected response format');
          this.setState({ loading: false });
          throw new Error('Error loading vcard data: unexpected response format');
        }
      }
    } catch (error) {
      console.log('Error fetching user data: ', error);
      this.setState({ loading: false });
      this.showError('Error Loading vCard Data');
    }
  };

  vCardReducer = person => {
    const fetchedVCard = {
      status: person.status,
      userkey: parseInt(person.userkey),
      firstname: person.firstname,
      lastname: person.lastname,
      personkey: parseInt(person.personkey),
      vcardcompany: person.vcardcompany,
      vcardaddress: person.vcardaddress,
      vcardcity: person.vcardcity,
      vcardstate: person.vcardstate,
      vcardcountry: person.vcardcountry,
      vcardpostalcode: person.vcardpostalcode,
      vcardemail: person.user.vcardemail,
      vcardphone: person.user.vcardphone,
      vcardassistantemail: person.vcardassistantemail,
      vcardassistantphone: person.vcardassistantphone,
      vcardassistantname: person.vcardassistantname,
      vcardfirstname: person.vcardfirstname,
      vcardlastname: person.vcardlastname,
      vcardmobilephone: person.vcardmobilephone,
    };
    return fetchedVCard;
  };

  handleCancel = () => {
    const resetCard = { ...this.state.initialCard };
    this.setState({ vCard: { ...resetCard } });
  };

  handleChange = event => {
    const { name, value } = event.target;
    const { vCard } = this.state;
    const newVCard = vCard;
    newVCard[name] = value;
    this.setState({ vCard: newVCard });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const formIsValid = await this.validateForm();

    if (formIsValid) {
      await this.setState({ isSaving: true });

      const { client } = this.props;
      const { vCard } = this.state;

      try {
        const result = await client.mutate({
          mutation: PersonMutations.SetMemberDetails,
          variables: { input: vCard },
        });
        if (result && result !== undefined) {
          const { data } = result;

          if (data && data !== undefined && data.setMemberDetails && data.setMemberDetails !== undefined) {
            this.showSuccess('VCard Details Saved Successfully');
            this.setState({
              initialCard: { ...vCard },
              loading: false,
              isSaving: false,
            });
          } else {
            this.showError('Error updating user data');
            this.setState({ loading: false, isSaving: false });
          }
        }
      } catch (error) {
        this.showError('Error updating user data');
        this.setState({ loading: false, isSaving: false });
      }
    } else {
      this.showError('Error updating vcard');
    }
  };

  validateForm = () =>
    new Promise(resolve => {
      const { vCard, errors } = this.state;

      let isValid = true;

      Object.keys(fields).forEach(key => {
        const value = vCard[key];

        if (fields[key].required === true) {
          if (InputHelper.isEmptyOrWhitespace(value)) {
            errors[key] = `${fields[key].title} is required`;
            isValid = false;
          } else {
            errors[key] = null;
          }
        }
      });

      if (vCard.vcardphone && !InputHelper.isNotLetter(vCard.vcardphone)) {
        isValid = false;
        errors.vcardphone = 'No letters allowed in vcard phone.';
      }

      if (vCard.vcardmobilephone && !InputHelper.isNotLetter(vCard.vcardmobilephone)) {
        isValid = false;
        errors.vcardmobilephone = 'No letters allowed in vcard phone.';
      }

      if (vCard.vcardassistantphone && !InputHelper.isNotLetter(vCard.vcardassistantphone)) {
        isValid = false;
        errors.vcardassistantphone = 'No letters allowed in vcard phone.';
      }

      if (vCard.vcardemail && !InputHelper.isValidEmail(vCard.vcardemail)) {
        isValid = false;
        errors.vcardemail = 'Please enter a valid email address';
      }

      if (vCard.vcardassistantemail && !InputHelper.isValidEmail(vCard.vcardassistantemail)) {
        isValid = false;
        errors.vcardassistantemail = 'Please enter a valid email address';
      }

      this.setState({ errors });

      resolve(isValid);
    });

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'success' });
  };

  render() {
    const { vCard, isSaving, loading, errors } = this.state;
    return (
      <Paper className="paper-base">
        <form className="details-card" autoComplete="off">
          <div className="row section" style={{ height: '2em', justifyContent: 'space-between' }}>
            <h5 className="paper-header">Member VCard</h5>
            {isSaving || loading ? <CircularProgress size={20} /> : null}
          </div>
          <div className="row">
            <div className="flex-2 column">
              <div className="row justify-between">
                <TextField
                  name="vcardfirstname"
                  label="First Name"
                  className="member-input divided-row mr-1"
                  value={vCard.vcardfirstname || ''}
                  onChange={this.handleChange}
                  required
                  error={errors.vcardfirstname}
                  helperText={errors.vcardfirstname}
                />
                <TextField
                  name="vcardlastname"
                  label="Last Name"
                  className="member-input divided-row"
                  value={vCard.vcardlastname || ''}
                  onChange={this.handleChange}
                  required
                  error={errors.vcardlastname}
                  helperText={errors.vcardlastname}
                />
              </div>
              <TextField name="vcardcompany" label="Company" className="member-input" value={vCard.vcardcompany || ''} onChange={this.handleChange} />
              <TextField name="vcardaddress" label="Company Address" className="member-input" value={vCard.vcardaddress || ''} onChange={this.handleChange} />
              <TextField name="vcardcity" label="City" className="member-input" value={vCard.vcardcity || ''} onChange={this.handleChange} />
              <div className="row justify-between">
                <TextField name="vcardstate" label="State" className="member-input divided-row" value={vCard.vcardstate || ''} onChange={this.handleChange} />
                <TextField name="vcardpostalcode" label="Postal Code" className="member-input divided-row" value={vCard.vcardpostalcode || ''} onChange={this.handleChange} />
              </div>
              <TextField name="vcardcountry" label="Country" className="member-input" value={vCard.vcardcountry || ''} onChange={this.handleChange} select>
                {Object.keys(countryToCCA).map((item, index) => (
                  <MenuItem value={item || ''} key={`${item} ${index}`}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="flex-1 column">
              <TextField
                name="vcardphone"
                label="Company Phone"
                className="member-input"
                value={vCard.vcardphone || ''}
                onChange={this.handleChange}
                required
                error={errors.vcardphone}
                helperText={errors.vcardphone}
              />
              <TextField
                name="vcardmobilephone"
                label="Mobile Phone"
                className="member-input"
                value={vCard.vcardmobilephone || ''}
                onChange={this.handleChange}
                error={errors.vcardmobilephone}
                helperText={errors.vcardmobilephone}
              />
              <TextField
                name="vcardemail"
                label="Email"
                className="member-input"
                value={vCard.vcardemail || ''}
                onChange={this.handleChange}
                required
                error={errors.vcardemail}
                helperText={errors.vcardemail}
              />
            </div>
            <div className="flex-1 column">
              <TextField name="vcardassistantname" label="Assistant Name" className="member-input" value={vCard.vcardassistantname || ''} onChange={this.handleChange} />
              <TextField
                name="vcardassistantemail"
                label="Assistant Email"
                className="member-input"
                value={vCard.vcardassistantemail || ''}
                onChange={this.handleChange}
                error={errors.vcardassistantemail}
                helperText={errors.vcardassistantemail}
              />
              <TextField
                name="vcardassistantphone"
                label="Assistant Phone"
                className="member-input"
                value={vCard.vcardassistantphone || ''}
                error={errors.vcardassistantphone}
                helperText={errors.vcardassistantphone}
                onChange={this.handleChange}
              />
              <div className="row justify-evenly">
                {!isSaving && !loading ? (
                  <div className="button">
                    <Button color="primary" variant="contained" onClick={this.handleSubmit}>
                      Save
                    </Button>
                  </div>
                ) : (
                  <div className="button">
                    <Button color="secondary" variant="contained" disabled>
                      Save
                    </Button>
                  </div>
                )}
                <div className="button">
                  <Button color="primary" variant="outlined" onClick={this.handleCancel}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Paper>
    );
  }
}

export default withSnackbar(withApollo(VCard));

VCard.propTypes = {
  personkey: PropTypes.number,
  client: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
};
