import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Storage, Auth, Analytics } from 'aws-amplify';
import { SnackbarProvider } from 'notistack';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Login from './containers/Login/Login';
import AppWithAuth from './AppWithAuth';
import config from './config';

require('source-sans-pro/source-sans-pro.css');

const theme = createMuiTheme({
  palette: {
    primary: { main: '#0033A0' },
    secondary: { main: '#d9d9d9' },
    error: { main: '#e52626' },
  },
  typography: {
    fontFamily: ['Helvetica Neue LT Std 45 Light'].join(','),
  },
});

Storage.configure({
  AWSS3: {
    bucket: config.mobile.aws_storage_bucket,
    region: config.mobile.aws_project_region,
  },
});

Analytics.configure({
  AWSPinpoint: {
    appId: config.admin.pinpoint_app_id,
    region: config.admin.aws_project_region,
  },
});

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticating: true,
      isAuthenticated: false,
    };
    this.userHasAuthenticated = this.userHasAuthenticated.bind(this);
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    } catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
    this.setState({ isAuthenticating: false });
  }

  // takes a boolean
  userHasAuthenticated(authenticated) {
    this.setState({ isAuthenticated: authenticated });
  }

  render() {
    const { isAuthenticating, isAuthenticated } = this.state;
    return (
      !isAuthenticating && (
        <BrowserRouter>
          <div>
            <Switch>
              <Route
                path="/"
                render={props =>
                  isAuthenticated ? (
                    <SnackbarProvider maxSnack={1} autoHideDuration={2000}>
                      <MuiThemeProvider theme={theme}>
                        <AppWithAuth userHasAuthenticated={this.userHasAuthenticated} />
                      </MuiThemeProvider>
                    </SnackbarProvider>
                  ) : (
                    <SnackbarProvider maxSnack={1} autoHideDuration={2000}>
                      <MuiThemeProvider theme={theme}>
                        <Login {...props} userHasAuthenticated={this.userHasAuthenticated} />
                      </MuiThemeProvider>
                    </SnackbarProvider>
                  )
                }
              />
              <Route path="/" component={AppWithAuth} />
            </Switch>
            {/* {renderRedirect} */}
          </div>
        </BrowserRouter>
      )
    );
  }
}

export default (process.env.NODE_ENV === 'development' ? hot(withRouter(App)) : withRouter(App));