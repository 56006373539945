import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardActionArea, CardContent, Typography, CircularProgress } from '@material-ui/core';

const ReportSummaryCard = ({ theme, summaryHeader, summary, openReport, isLoading }) => (
  <Card className="wrapping-row-item report-summary-card">
    <CardActionArea
      onClick={() => {
        if (openReport) openReport(summaryHeader);
      }}
    >
      <div className={`header-${theme} summary-header`}>
        <Typography variant="body2" component="p">
          {summaryHeader.toUpperCase()}
        </Typography>
      </div>
      <div className="report-summary-card-content">
        <CardContent>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Typography variant="h3" component="h3" className={`text-${theme}`}>
              {summary || 'No Results'}
            </Typography>
          )}
        </CardContent>
      </div>
    </CardActionArea>
  </Card>
);

export default ReportSummaryCard;

ReportSummaryCard.propTypes = {
  theme: PropTypes.string,
  summaryHeader: PropTypes.string,
  summary: PropTypes.number,
  openReport: PropTypes.func,
  isLoading: PropTypes.bool,
};
