import gql from 'graphql-tag';

const SetUserIdentityId = gql`
  mutation SetIdentity($sub: String!, $identityId: String!) {
    setUserIdentityId(sub: $sub, identityId: $identityId) {
      wassuccessful
      error
    }
  }
`;

export const UserMutations = {
  SetUserIdentityId,
};
