import { Storage, Auth } from 'aws-amplify';
import axios from 'axios';
import config from '../config';

function validateDocument(document) {
  return new Promise((resolve, reject) => {
    try {
      if (!document.name || document.name === undefined) {
        resolve(false);
        throw new Error('document has no name');
      }

      if (!document.accesslevel || document.accesslevel === undefined) {
        resolve(false);
        throw new Error('document has no access level');
      }

      if (document.accesslevel === 'protected' && (!document.identityid || document.identityid === undefined)) {
        resolve(false);
        throw new Error('document is protected, but no identityid provided');
      }

      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
}

const getObject = document =>
  new Promise(async (resolve, reject) => {
    try {
      if (!document || document === undefined) {
        resolve(null);
        // throw new Error('document is required');
      }
      const isValidated = await validateDocument(document);
      if (!isValidated) {
        resolve(null);
      }

      const options = { level: document.accesslevel };

      if (document.accesslevel === 'protected') {
        options.identityId = document.identityid;
      }

      Storage.get(`original/${document.name}`, options)
        .then(result => {
          resolve(result);
        })
        .catch(err => {
          console.log('S3 Get Error: ', err);
          reject(err);
        });
    } catch (error) {
      console.log('Error fetching document: ', error);
      reject(error);
    }
  });

const putObject = async ({ name, object, path, access, identityId, progressFunc }) =>
  new Promise(async (resolve, reject) => {
    if (!name || name === undefined) {
      reject(new Error('name is required'));
    } else if ((!object || object === undefined) && (!path || path === undefined)) {
      reject(new Error('object is required'));
    } else {
      let accessLevel = 'public';

      if (access && access !== undefined) {
        accessLevel = access;
      }

      const putOptions = {
        level: accessLevel,
        identityId,
        progressCallback(progress) {
          if (progressFunc && progressFunc !== undefined) {
            progressFunc(progress);
          }
        },
      };

      Storage.put(`original/${name}`, object, putOptions)
        .then(res => {
          console.log('S3 Upload Result: ', res);
          resolve(true);
        })
        .catch(err => {
          console.log('S3 Upload Error: ', err);
          resolve(false);
        });
    }
  });

const S3Helper = {
  getObject,
  putObject,
};

export default S3Helper;
