import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { TextField, List, InputAdornment } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import AutocompleteItems from './AutocompleteItems';
import AdminFilterProps from './AdminFilterProps';
import * as SchemaConstants from '../../../services/schemaConstants';

class AdminAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      inputValue: '',
    };
  }

  handleInputChange = event => {
    const { value } = event.target;
    this.setState({ inputValue: value, isOpen: true });
  };

  selectItemHandler = async item => {
    let route = '/';
    switch (item.type) {
      case SchemaConstants.GLOBAL_SEARCH_TYPE_MEMBER:
        route += `memberdetails/${item.key}`;
        break;
      case SchemaConstants.GLOBAL_SEARCH_TYPE_EVENT:
        route += `events/${item.key}`;
        break;
      case SchemaConstants.GLOBAL_SEARCH_TYPE_NEWS:
        route += 'news';
        break;
      case SchemaConstants.GLOBAL_SEARCH_TYPE_NOTE:
        route += `memberdetails/${item.key}#notes`;
        break;
      case SchemaConstants.GLOBAL_SEARCH_TYPE_POLL:
        route += 'polls';
        break;
      default:
        break;
    }

    window.location = route;
  };

  handleBlur = () => {
    setTimeout(() => this.setState({ inputValue: '', isOpen: false }), 500);
  };

  renderInput = () => {
    const { label } = this.props;
    const { inputValue, isOpen } = this.state;

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      <div className="downshift-header" tabIndex={0} onBlur={this.handleBlur}>
        <TextField
          variant="outlined"
          label={label}
          onChange={this.handleInputChange}
          value={inputValue}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        {isOpen ? (
          <div className="downshift-list downshift-header-list">
            <List>
              <AdminFilterProps contains={inputValue} children={AutocompleteItems} onSelectItem={this.selectItemHandler} />
            </List>
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    return <Downshift>{() => <div className="downshift-input">{this.renderInput()}</div>}</Downshift>;
  }
}

AdminAutocomplete.propTypes = {
  label: PropTypes.string.isRequired,
};

export default AdminAutocomplete;
