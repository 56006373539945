import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Moment from 'moment';
import DoneIcon from '@material-ui/icons/Done';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, CircularProgress } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { DocumentMutations } from '../../mutations/Document';
import { DocumentQueries } from '../../queries/Document';
import S3Helper from '../../services/S3Helper';
import AuthHelper from '../../services/AuthHelper';

const uuidv4 = require('uuid/v4');

const tableColumns = [{ title: 'Name', field: 'displayname' }, { title: 'Date Added', field: 'createddate' }];

class Documents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documents: [],
      documentSelections: [],
      uploading: false,
      uploaded: true,
      dialogOpen: false,
      deleteDialogOpen: false,
    };
  }

  async componentDidMount() {
    this.fetchDocuments();

    const identityId = await AuthHelper.getIdentityId();
    const sub = await AuthHelper.getUserSub();

    this.setState({ identityId, sub });
  }

  fetchDocuments = async () => {
    const { client, entityKey, entityType } = this.props;

    try {
      const documents = await client.query({
        query: DocumentQueries.GetDocuments,
        variables: { entitykey: entityKey, entitytype: entityType },
      });

      if (documents && documents.data && documents.data.getDocuments) {
        this.formatDocuments(documents.data.getDocuments);
      }
    } catch (error) {
      console.log('Error Fetching Documents: ', error);
      this.showError('Error Fetching Documents');
    }
  };

  formatDocuments = documents => {
    const formattedDocuments = [];

    if (documents) {
      documents.forEach(doc => {
        const baseDoc = { ...doc };

        const createdDate = new Date(0);
        createdDate.setUTCMilliseconds(doc.createddate);

        baseDoc.createddate = Moment.utc(createdDate)
          .local()
          .format('MMMM D, YYYY h:m a');

        formattedDocuments.push(baseDoc);
      });
    }

    this.setState({ documents: formattedDocuments });
  };

  downloadDocument = async document => {
    try {
      const url = await S3Helper.getObject(document);

      if (url && url !== undefined) {
        const win = window.open(url, '_blank');
        win.focus();
      } else {
        throw new Error('No url returned');
      }
    } catch (error) {
      console.log('Error fetching document: ', error);
      this.showError('Error Downloading Document');
    }
  };

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'success' });
  };

  openDialog = () => {
    this.setState({ dialogOpen: true, uploading: false, uploaded: false });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false, documentSelections: [], uploading: false, uploaded: false });
  };

  setDocumentSelections = documents => {
    this.setState({ documentSelections: documents });
  };

  uploadDocuments = async () => {
    const { client, entityKey, entityType } = this.props;
    const { documentSelections, identityId, sub } = this.state;
    let uploadSuccessful = true;

    try {
      this.setState({ uploading: true });
      await Promise.all(
        documentSelections.map(async file => {
          const filename = `${uuidv4()}.${file.name.split('.')[1]}`;

          const params = {
            name: filename,
            object: file,
            path: file.preview,
            access: 'protected',
            identityId,
            progressFunc: null,
          };

          const uploadResult = await S3Helper.putObject(params);

          if (uploadResult) {
            const document = {
              name: filename,
              displayname: file.name,
              sub,
              accesslevel: 'protected',
              entitykey: entityKey,
              entitytype: entityType,
            };

            const result = await client.mutate({
              mutation: DocumentMutations.UploadDocument,
              variables: document,
            });

            if (result && result.data && result.data.uploadDocument) {
              if (result.data.uploadDocument.wassuccessful === true) {
                this.showSuccess(`${file.name} uploaded`);
              } else {
                this.showError(`Error uploading ${file.name}`);
                uploadSuccessful = false;
              }
            }
          } else {
            this.showError(`Error uploading ${file.name}`);
            uploadSuccessful = false;
          }
        })
      );
    } catch (error) {
      this.setState({ uploading: false });
      this.showError('Error Uploading Document');
    }

    this.setState({ uploading: false, documentSelections: [] });

    if (uploadSuccessful) {
      this.setState({ uploaded: true });
    }

    this.fetchDocuments();
  };

  confirmDeleteDocument = documentkey => {
    this.setState({ documentToDelete: documentkey, deleteDialogOpen: true });
  };

  closeDeleteDialog = () => {
    this.setState({ documentToDelete: null, deleteDialogOpen: false });
  };

  deleteDocument = async () => {
    const { client } = this.props;
    const { documentToDelete } = this.state;

    try {
      const result = await client.mutate({
        mutation: DocumentMutations.DeleteDocument,
        variables: { documentkey: documentToDelete },
      });

      if (result && result !== undefined && result.data && result.data !== undefined && result.data.deleteDocument && result.data.deleteDocument !== undefined) {
        if (result.data.deleteDocument.wassuccessful === true) {
          this.closeDeleteDialog();
          this.showSuccess(`Document Deleted`);
          this.fetchDocuments();
        } else {
          throw new Error('Invalid API response');
        }
      }
    } catch (error) {
      this.closeDeleteDialog();
      console.log('ERROR: ', error);
      this.showError('Error Deleting Document');
    }
  };

  render() {
    const { documents, dialogOpen, deleteDialogOpen, documentSelections, uploading, uploaded } = this.state;
    const { entityType } = this.props;

    let dialogTitleFeedback = null;

    if (uploading === true) {
      dialogTitleFeedback = <CircularProgress className="document-upload-feedback" />;
    } else if (uploaded === true) {
      dialogTitleFeedback = <DoneIcon className="document-upload-feedback" />;
    }

    let headerText = 'Documents';

    if (entityType === 'member') {
      headerText = <p className="material-table-header">Member Documents</p>;
    } else if (entityType === 'event') {
      headerText = <p className="material-table-header">Event Documents</p>;
    }

    return (
      <div>
        <Dialog open={dialogOpen} onClose={this.closeDialog}>
          <DialogTitle>Upload Documents {dialogTitleFeedback}</DialogTitle>
          <DialogContent dividers>
            <Dropzone
              onDrop={acceptedFiles =>
                this.setDocumentSelections(
                  acceptedFiles.map(file =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    })
                  )
                )
              }
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="document-drop-container">
                  <input {...getInputProps()} />
                  <p>Drag and drop files here, or click to select files</p>
                </div>
              )}
            </Dropzone>

            <div className="preview-container">
              <ul>
                {documentSelections.map(doc => (
                  <li>{doc.name}</li>
                ))}
              </ul>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} className="document-dialog-button">
              Cancel
            </Button>
            <Button onClick={this.uploadDocuments} className="document-dialog-button" color="primary" disabled={!(documentSelections && documentSelections.length > 0) || uploading === true}>
              Upload
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={deleteDialogOpen} onClose={this.closeDeleteDialog}>
          <DialogTitle>Delete Document</DialogTitle>
          <DialogContent dividers>
            <DialogContentText className="document-dialog-text">Are you sure you want to delete this document?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDeleteDialog} className="event-dialog-button">
              Cancel
            </Button>
            <Button onClick={this.deleteDocument} className="event-dialog-button danger-text">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <div className="paper-table">
          <MaterialTable
            style={{ padding: '1em' }}
            title={headerText}
            columns={tableColumns}
            data={documents}
            actions={[
              {
                icon: () => <AddBox />,
                tooltip: 'Add Document',
                isFreeAction: true,
                onClick: () => this.openDialog(),
              },
              {
                icon: () => <CloudDownload />,
                tooltip: 'Download',
                onClick: (event, rowData) => this.downloadDocument(rowData),
              },
              {
                icon: () => <DeleteOutline />,
                tooltip: 'Delete Document',
                onClick: (event, rowData) => this.confirmDeleteDocument(rowData.documentkey),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default withSnackbar(withApollo(Documents));

Documents.propTypes = {
  client: PropTypes.object.isRequired,
  entityKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  entityType: PropTypes.oneOf(['member', 'event']).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};
