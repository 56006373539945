import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { Paper, TextField, Button, FormControl } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { MessageMutations } from '../../mutations/Message';

class MessageSender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
    };
  }

  handleChange = event => {
    const { value } = event.target;
    if (value || value !== undefined) {
      this.setState({ message: value });
    }
  };

  sendMessage = async () => {
    const { client, sub, conversationKey, onMessageSent, enqueueSnackbar } = this.props;
    const { message } = this.state;

    if (!message || message === undefined || message.length === 0) {
      enqueueSnackbar('Message required.', { variant: 'error' });
      return;
    }

    if (conversationKey === 0) {
      enqueueSnackbar('No conversation is selected.', { variant: 'error' });
      return;
    }

    await client.mutate({
      mutation: MessageMutations.SendMessage,
      variables: { conversationkey: conversationKey, sub, content: message, type: 'support' },
    });

    this.setState({ message: '' }, () => {
      onMessageSent();
      enqueueSnackbar('Message sent', { variant: 'success' });
    });
  };

  render() {
    const { conversationKey } = this.props;
    const { message } = this.state;

    return (
      <Paper className="compose-message-container paper-base">
        <div className="form-card">
          <FormControl className="form-control">
            <TextField
              label="Write a message..."
              inputProps={{
                id: 'message',
              }}
              multiline
              fullWidth
              className="message-text"
              onChange={this.handleChange}
              value={message}
            />
            <p className={message && message.length >= 1001 ? 'message-warn' : ''}>{`${message ? message.length : 0}/1000`}</p>
            <div>
              <Button disabled={conversationKey === 0 || message.length === 0 || message.length >= 1001} variant="contained" className="message-submit" onClick={this.sendMessage} color="primary">
                Send
              </Button>
            </div>
          </FormControl>
        </div>
      </Paper>
    );
  }
}
MessageSender.propTypes = {
  client: PropTypes.object.isRequired,
  sub: PropTypes.string.isRequired,
  conversationKey: PropTypes.number.isRequired,
  enqueueSnackbar: PropTypes.func,
  onMessageSent: PropTypes.func.isRequired,
};

export default withSnackbar(withApollo(MessageSender));
