import { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { NewsQueries } from '../../queries/News';

class NewsProps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      options: undefined,
      data: [],
    };
  }

  async componentDidMount() {
    this.fetchNews();
  }

  componentDidUpdate(prevProps) {
    const { contains, refresh, handleFormChange } = this.props;
    if (contains !== prevProps.contains) {
      this.fetchNews();
      return true;
    }
    if (refresh !== prevProps.refresh && refresh === true) {
      this.fetchNews();
      handleFormChange();
    }
    return false;
  }

  fetchNews = async () => {
    const { client, contains } = this.props;

    this.setState({ loading: true });
    const news = await client.query({
      query: NewsQueries.GetNews,
      variables: { contains },
    });
    this.setState({
      loading: false,
      data: [...news.data.getNews],
    });
  };

  // eslint-disable-next-line react/destructuring-assignment
  render = () => this.props.children({ ...this.props, ...this.state });
}

NewsProps.propTypes = {
  client: PropTypes.object.isRequired,
  contains: PropTypes.string,
  children: PropTypes.any,
  refresh: PropTypes.bool,
  handleFormChange: PropTypes.func,
};

export default withApollo(NewsProps);
