import gql from 'graphql-tag';

const GetFilteredReportMetrics = gql`
  query GetFilteredReportMetrics($input: GetFilteredReportInput!) {
    getFilteredReportMetrics(input: $input) {
      sender
      receiver
      status
      startdatetime
      enddatetime
      duration
      messagecount
      offset
      messageid
      content
      recipientcount
      broadcastmessageid
    }
  }
`;
const GetSummaryReportMetrics = gql`
  query GetSummaryReportMetrics($input: GetSummaryReportInput!) {
    getSummaryReportMetrics(input: $input) {
      requestSummary
      voiceCallSummary
      videoCallSummary
      vCardSummary
      broadcastSummary
      messagingSummary
    }
  }
`;

export const ReportFilterQueries = {
  GetFilteredReportMetrics,
  GetSummaryReportMetrics,
};
