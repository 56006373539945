/* eslint-disable react/display-name */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import EventBusy from '@material-ui/icons/EventBusy';
import Launch from '@material-ui/icons/Launch';
import Moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withRouter } from 'react-router-dom';
import DocumentPhoto from '../../DocumentPhoto/DocumentPhoto';
import { EventMutations } from '../../../mutations/Events';
import { EventQueries } from '../../../queries/Events';

const tableColumns = [
  {
    title: '',
    field: 'profilephoto',
    cellStyle: {
      width: '5em',
    },
    render: rowData => (
      <div className="event-attendee-photo">
        <DocumentPhoto document={rowData.profilepicture} defaultPhoto="person" />
      </div>
    ),
  },
  { title: 'Member', field: 'personname' },
  { title: 'Date Confirmed', field: 'dateconfirmed' },
  { title: 'Details', field: 'details' },
];

class EventAttendance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attendees: [],
      personkey: null,
      personname: null,
      dialogOpen: false,
    };
  }

  componentDidMount() {
    this.fetchEventAttendees();
  }

  fetchEventAttendees = async () => {
    const { client, eventkey } = this.props;

    try {
      const attendees = await client.query({
        query: EventQueries.GetEventAttendees,
        variables: { eventkey },
      });

      if (attendees && attendees.data && attendees.data.getEventAttendees) {
        this.setState({
          attendees: attendees.data.getEventAttendees.map(attendee => {
            const formattedAttendee = { ...attendee };

            if (attendee.dateconfirmed) {
              formattedAttendee.dateconfirmed = Moment.utc(attendee.dateconfirmed)
                .local()
                .format('MMMM D, YYYY h:mm a');
            } else {
              formattedAttendee.dateconfirmed = '-';
            }

            if (!attendee.details) {
              formattedAttendee.details = '-';
            }

            return formattedAttendee;
          }),
        });
      }
    } catch (error) {
      console.log('Error fetching event attendees: ', error);
      this.showError('Error Fetching Event Attendees');
    }
  };

  viewProfile = personkey => {
    const { history } = this.props;
    const detailLink = `/memberdetails/${personkey}`;

    history.push({
      pathname: detailLink,
      state: {
        personkey: parseInt(personkey),
      },
    });
  };

  cancelAttendance = async () => {
    const { client, eventkey } = this.props;
    const { personkey, personname } = this.state;

    try {
      const result = await client.mutate({
        mutation: EventMutations.CancelEventAttendance,
        variables: { eventkey, personkey },
      });

      if (result && result.data && result.data.cancelEventAttendance) {
        const response = result.data.cancelEventAttendance;

        if (response.wassuccessful === true) {
          this.showSuccess(`Attendance Canceled for ${personname}`);
          this.fetchEventAttendees();
          this.closeDialog();
        } else {
          this.showError('Could Not Cancel Attendance');
        }
      }
    } catch (error) {
      console.log('Error canceling event attendance: ', error);
      this.showError('Error Canceling Attendance');
    }
  };

  confirmCancelEvent = (personkey, name) => {
    this.setState({ personkey, personname: name, dialogOpen: true });
  };

  closeDialog = () => {
    this.setState({ personkey: null, personname: null, dialogOpen: false });
  };

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'success' });
  };

  render() {
    const { attendees, dialogOpen, personname } = this.state;

    return (
      <div className="event-attendees-card paper-table">
        <Dialog open={dialogOpen} onClose={this.closeDialog}>
          <DialogTitle>Cancel Event Attendance</DialogTitle>
          <DialogContent dividers>
            <DialogContentText className="document-dialog-text">Are you sure you want to cancel attendance for {personname}?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog}>Back</Button>
            <Button onClick={this.cancelAttendance} color="primary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>
        <MaterialTable
          style={{ padding: '1em' }}
          title={<p className="material-table-header">Expected Attendance</p>}
          columns={tableColumns}
          data={attendees}
          actions={[
            {
              icon: () => <Launch />,
              tooltip: 'View Profile',
              onClick: (event, rowData) => this.viewProfile(rowData.personkey),
            },
            {
              icon: () => <EventBusy />,
              tooltip: 'Cancel Attendance',
              onClick: (event, rowData) => this.confirmCancelEvent(rowData.personkey, rowData.personname),
            },
          ]}
        />
      </div>
    );
  }
}

export default withSnackbar(withRouter(withApollo(EventAttendance)));

EventAttendance.propTypes = {
  client: PropTypes.object.isRequired,
  eventkey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  history: PropTypes.any,
};
