import gql from 'graphql-tag';

const GetEvents = gql`
  query($upcoming: Boolean) {
    getEvents(upcoming: $upcoming) {
      eventkey
      name
      adjustedstartdate
      adjustedenddate
      location
      timezone {
        title
        codename
        gmtoffset
        daylightsavings
      }
    }
  }
`;

const GetEventById = gql`
  query GetEventById($eventkey: ID!) {
    getEventById(eventkey: $eventkey) {
      eventkey
      name
      location
      description
      adjustedstartdate
      adjustedstarttime
      adjustedstartdatetime
      adjustedenddate
      adjustedendtime
      adjustedenddatetime
      seatsavailable
      ispublished
      timezone {
        timezonekey
      }
      venue {
        venuekey
        street
        phonenumber
        city
        state
        emailaddress
        country
        postalcode
        description
      }
      bannerphoto {
        documentkey
        name
        accesslevel
        identityid
      }
    }
  }
`;

const GetMemberEvents = gql`
  query GetMemberEvents($personkey: ID!) {
    getMemberEvents(personkey: $personkey) {
      eventkey
      eventname
      confirmed
      dateconfirmed
      datecanceled
      details
    }
  }
`;

const GetEventAttendees = gql`
  query EventAttendees($eventkey: ID!) {
    getEventAttendees(eventkey: $eventkey) {
      personkey
      eventkey
      personname
      dateconfirmed
      details
      profilepicture {
        documentkey
        name
        accesslevel
        identityid
      }
    }
  }
`;

const GetEventFBOs = gql`
  query GetEventFBOs($eventkey: ID!) {
    getFBOs(eventkey: $eventkey) {
      fbokey
      name
      city
      state
      country
      milesfromvenue
    }
  }
`;

const GetEventPhotos = gql`
  query GetEventPhotos($eventkey: ID!) {
    getEventPhotos(eventkey: $eventkey) {
      documentkey
      name
      eventkey
      accesslevel
      identityid
    }
  }
`;

const GetTimezones = gql`
  query {
    getTimezones {
      timezonekey
      title
      codename
      description
      daylightsavings
      gmtoffset
    }
  }
`;

export const EventQueries = {
  GetEvents,
  GetEventById,
  GetMemberEvents,
  GetEventAttendees,
  GetEventFBOs,
  GetEventPhotos,
  GetTimezones,
};
