export const LoggingConstants = {
  TYPE: { ERROR: 'ERROR', EVENT: 'EVENT', ACTION: 'ACTION' },
  AREA: {
    COGNITO: 'COGNITO',
    DOWNLOAD: 'DOWNLOAD',
    PERSON: 'PERSON',
    SES: 'SES',
    SNS: 'SNS',
  },
};
