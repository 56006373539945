import gql from 'graphql-tag';

const CreateAssets = gql`
  mutation CreateAssets($location: String!, $isactive: Boolean!, $personkey: Int, $assettypekey: Int, $name: String) {
    createAssets(location: $location, isactive: $isactive, personkey: $personkey, assettypekey: $assettypekey, name: $name) {
      assetkey
    }
  }
`;

const EditAssetById = gql`
  mutation EditAssetById($location: String!, $assetkey: Int!, $description: String!, $assettypekey: Int, $name: String) {
    editAssetById(location: $location, assetkey: $assetkey, description: $description, assettypekey: $assettypekey, name: $name)
  }
`;

const DeleteAssetById = gql`
  mutation DeleteAssetById($assetkey: Int!) {
    deleteAssetById(assetkey: $assetkey)
  }
`;

const InsertAssetImage = gql`
  mutation InsertAssetImage($assetkey: Int!, $documentkey: Int, $name: String!, $userkey: Int!, $accesslevel: String!, $isactive: Boolean!) {
    insertAssetImage(assetkey: $assetkey, documentkey: $documentkey, userkey: $userkey, accesslevel: $accesslevel, name: $name, isactive: $isactive)
  }
`;

const UploadAssetPhoto = gql`
  mutation UploadAssetPhoto($assetkey: Int!, $sub: String!, $name: String!, $accesslevel: String!, $isbanner: Boolean!) {
    uploadAssetPhoto(assetkey: $assetkey, sub: $sub, name: $name, accesslevel: $accesslevel, isbanner: $isbanner) {
      photo {
        documentkey
        name
        assetkey
        accesslevel
        identityid
        isbanner
      }
      wassuccessful
      error
    }
  }
`;

export const AssetMutations = {
  CreateAssets,
  EditAssetById,
  DeleteAssetById,
  InsertAssetImage,
  UploadAssetPhoto,
};
