import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { CircularProgress, Card, CardActionArea, CardContent, Button, Typography, CardActions } from '@material-ui/core';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withSnackbar } from 'notistack';
import DocumentPhoto from '../../components/DocumentPhoto/DocumentPhoto';
import { PersonMutations } from '../../mutations/Person';
import { PersonQueries } from '../../queries/Person';
import Subheader from '../../components/Subheader/Subheader';

export class Members extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      personList: [],
      showConfirmationDialog: false,
    };
  }

  componentDidMount() {
    this.fetchPersons();
  }

  componentDidUpdate(prevProps) {
    const { refresh, refreshMembers } = this.props;
    if (refresh !== prevProps.refresh && refresh === true) {
      this.fetchPersons();
      refreshMembers();
    }
    return false;
  }

  handleError = message => {
    this.setState({ error: message });
    console.log('An Error Occurred: ', message);
  };

  closeConfirmDialog = () => {
    this.setState({ showConfirmationDialog: false, personToDelete: null });
  };

  dataToState(personList) {
    if (personList && personList !== undefined) {
      const { data } = personList;

      if (data && data !== undefined && Array.isArray(data.getMembers)) {
        const users = data.getMembers;

        this.setState({ personList: users, loading: false });
      } else {
        this.handleError('Unexpected list format');
        this.setState({ loading: false });
      }
    } else {
      this.setState({ loading: false });
      console.log('No Users Returned');
    }
  }

  async fetchPersons(isRefreshing) {
    const { client } = this.props;
    if (!isRefreshing) {
      this.setState({ loading: true });
    }

    try {
      const personList = await client.query({
        query: PersonQueries.GetMembers,
      });
      this.dataToState(personList);
    } catch (error) {
      this.setState({ loading: false });
      this.handleError(error);
    }
  }

  deletePerson = async person => {
    const { client } = this.props;
    try {
      const result = await client.mutate({
        mutation: PersonMutations.DeletePerson,
        variables: { personkey: person.personkey },
      });
      console.log(result);
      const { wassuccessful } = result.data.deletePerson;

      if (wassuccessful) {
        this.showSuccess('Member Deleted');
        const isRefreshing = true;
        this.fetchPersons(isRefreshing);
        this.closeConfirmDialog();
      } else {
        this.showError('Error Deleting Member');
      }
    } catch (error) {
      this.showError('Error Deleting Member');
      this.handleError(error);
    }
  };

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'success' });
  };

  renderContent = () => {
    const { history } = this.props;
    const { personList, loading, error } = this.state;

    if (loading) {
      return <CircularProgress />;
    }

    if (error) {
      return <div>Error Loading Directory</div>;
    }

    if (personList && personList !== undefined && Array.isArray(personList)) {
      return personList.map((person, index) => {
        console.log(person);
        const detailLink = `/memberdetails/${person.personkey}`;
        const notesLink = `/memberdetails/${person.personkey}/#notes`;

        return (
          <Card className="justify-between wrapping-row-item column" key={index}>
            <CardActionArea onClick={() => history.push(detailLink)}>
              <div className="card-details row">
                <div className="member-photo-container column">
                  <div className={person.identityid ? 'green-circle' : 'red-circle'} />
                  <DocumentPhoto document={person.profilepicture} defaultPhoto="person" />
                </div>
                <div className="col card-info">
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {`${person.firstname} ${person.lastname}`}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {person.personstatus.title}
                      <br />
                      {person.company}
                    </Typography>
                  </CardContent>
                </div>
              </div>
            </CardActionArea>
            <CardActions>
              <div className="row ">
                <div className="mr-05">
                  <Button size="small" color="primary" href={detailLink} variant="outlined">
                    Details
                  </Button>
                </div>
                <div className="mr-05">
                  <Button size="small" color="primary" href={notesLink} variant="outlined">
                    Note
                  </Button>
                </div>
                <div className="mr-05">
                  <Button size="small" color="primary" variant="outlined" onClick={() => this.setState({ personToDelete: person, showConfirmationDialog: true })}>
                    Delete
                  </Button>
                </div>
              </div>
            </CardActions>
          </Card>
        );
      });
    }
  };

  render() {
    const { showConfirmationDialog, personToDelete } = this.state;

    const persons = this.renderContent();
    return (
      <div className="master-container">
        <div className="master-content">
          <Subheader title="Membership Directory" />
          <div className="card-wrap-container">{persons}</div>

          <Dialog open={showConfirmationDialog} onClose={this.closeConfirmDialog}>
            <DialogTitle>Delete Member</DialogTitle>
            <DialogContent dividers>
              <DialogContentText>Are you sure you want to delete this member?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeConfirmDialog}>Cancel</Button>
              <Button onClick={() => this.deletePerson(personToDelete)} color="primary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default withSnackbar(withApollo(Members));

Members.propTypes = {
  client: PropTypes.object,
  refresh: PropTypes.bool,
  refreshMembers: PropTypes.func,
  history: PropTypes.object,
  enqueueSnackbar: PropTypes.func.isRequired,
};
