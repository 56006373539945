import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from 'apollo-boost';
import { onError } from 'apollo-link-error';
import config from '../config';

export default class ApolloAuth {
  constructor(token, history) {
    this.token = token;
    this.history = history;
  }

  async init() {
    const defaultOptions = {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    };

    const httpLink = new HttpLink({
      uri: config.admin.graphql_endpoint,
    });

    const errorLink = onError(({ networkError = {}, graphQLErrors }) => {
      if (graphQLErrors) graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`));

      if (networkError) {
        // Dumping back to root triggers apollo auth to reinitialize with fresh credentials
        console.log(`[Network error]: ${networkError}`);

        // eslint-disable-next-line no-restricted-globals
        // location.href = '/';
      }
    });

    const authLink = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          authorization: this.token ? `Bearer ${this.token}` : '',
        },
      });

      return forward(operation);
    });

    const link = authLink.concat(errorLink.concat(httpLink));

    const client = new ApolloClient({
      link,
      cache: new InMemoryCache(),
      defaultOptions,
    });

    return client;
  }
}
