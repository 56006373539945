import React, { Component } from 'react';
import { Paper, TextField, Button, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withSnackbar } from 'notistack';
import Subheader from '../../components/Subheader/Subheader';
import { AssetQueries } from '../../queries/Assets';
import { AssetMutations } from '../../mutations/Assets';
import AssetPhotos from '../../components/MemberDetails/Assets/AssetPhotos';
import DocumentPhoto from '../../components/DocumentPhoto/DocumentPhoto';
import PhotoUploader from '../../components/MemberDetails/PersonalInfo/PhotoUploader';

export class AssetDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      assetTypes: {},
      asset: {},
      initialAsset: {},
      isValidated: true,
      featuredPhoto: {},
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { assetkey } = match.params;

    this.getAssetTypes();
    this.fetchAssetById(assetkey);
  }

  getAssetTypes = async () => {
    const { client } = this.props;

    try {
      const result = await client.query({
        query: AssetQueries.GetAssetTypes,
      });
      const { getAssetTypes } = result.data;
      const assetTypes = {};

      getAssetTypes.forEach(item => {
        assetTypes[item.title] = item.assettypekey;
      });

      this.setState({ assetTypes });
    } catch (error) {
      this.showError('Server error fetching asset types');
      console.log('ERROR', error);
    }
  };

  fetchAssetById = async assetkey => {
    const { client } = this.props;
    try {
      const result = await client.query({
        query: AssetQueries.GetAssetById,
        variables: {
          assetkey: parseInt(assetkey),
        },
      });
      if (result && result.data && result.data.getAssetById) {
        const asset = result.data.getAssetById;
        const setInitialAsset = { ...asset };
        this.setState({ asset, initialAsset: { ...setInitialAsset }, featuredPhoto: asset.featuredphoto });
      }
    } catch (error) {
      this.showError('Error fetching asset details');
      console.log('ERROR', error);
    }
  };

  setFeaturedPhoto = featuredPhoto => {
    const { match } = this.props;
    const { assetkey } = match.params;
    this.fetchAssetById(assetkey);
    this.setState({ featuredPhoto });
  };

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'success' });
  };

  handleChange = event => {
    const { name, value } = event.target;
    const { asset } = this.state;
    asset[name] = value;
    this.setState({ asset });
  };

  validateAsset = () => {
    const { asset } = this.state;
    const { title, location } = asset;
    if (title === '' || location === '') {
      this.setState({ isValidated: false });
    } else {
      this.setState({ isValidated: true });
    }
  };

  handleSubmit = async event => {
    event.preventDefault();
    const { client, history } = this.props;
    const { asset, assetTypes } = this.state;
    const { assetkey, title, description, location } = asset;
    await this.validateAsset();
    const { isValidated } = this.state;
    if (isValidated) {
      this.setState({ isSaving: true });
      try {
        const result = await client.mutate({
          mutation: AssetMutations.EditAssetById,
          variables: {
            assetkey,
            location,
            assettypekey: assetTypes[title],
            description,
          },
        });
        if (result && result.data && result.data.editAssetById) {
          const { editAssetById } = result.data;
          if (editAssetById > 0) {
            this.showSuccess('Asset Details Saved');
            this.setState({ initialAsset: asset, isSaving: false });
            history.goBack();
          } else {
            this.setState({ isSaving: false });
          }
        }
      } catch (error) {
        this.showError('Error fetching asset details');
        this.setState({ isSaving: false });
        console.log('ERROR', error);
      }
    }
  };

  handleCancel = event => {
    const { initialAsset } = this.state;
    const { history } = this.props;
    this.setState({ asset: { ...initialAsset } });
    history.goBack();
  };

  render() {
    const { asset, isSaving, assetTypes, isValidated, featuredPhoto } = this.state;
    const { title, description, location, assetkey, personkey } = asset;
    return (
      <div className="master-container">
        <div className="master-content">
          <Subheader title="Asset Details" />
          <Paper className="paper-base asset-details-card">
            <div className="asset-form">
              <div className="asset-header">
                <h5>Asset Summary Form</h5>
              </div>
              <form>
                <div className="row wrap justify-between">
                  <TextField name="title" label="Type" className="asset-input" select value={title || ''} onChange={this.handleChange} required error={!isValidated && title === ''}>
                    {[...Object.keys(assetTypes)].map((item, index) => (
                      <MenuItem value={item || ''} key={`${item} ${index}`}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField name="location" label="Location" className="asset-input" value={location || ''} onChange={this.handleChange} required error={!isValidated && location === ''} />
                </div>
                <div className="asset-header mb-1">
                  <h5>Featured Image</h5>
                </div>
                <div className="featured-image">
                  <DocumentPhoto defaultPhoto="generic" document={featuredPhoto} disableCarousel />
                  {/* <img className="image-selector" source={cameraIcon} alt="change profile pic button" /> */}
                  <PhotoUploader personkey={personkey} assetkey={assetkey} setFeaturedPhoto={this.setFeaturedPhoto} />
                </div>
                <p className="mb-1 mt-1" style={{ color: 'red' }}>
                  Please upload horizontal photos only
                </p>
                <div className="asset-header mt-2">
                  <h5>Asset Description</h5>
                </div>
                <div className="row mt-1">
                  <div className="column flex-3">
                    <textarea name="description" id="bio" rows="10" value={description || ''} onChange={this.handleChange} />
                  </div>
                  <div className="column flex-1 justify-evenly align-center">
                    {!isSaving ? (
                      <Button color="primary" variant="contained" onClick={this.handleSubmit}>
                        Save
                      </Button>
                    ) : (
                      <Button color="secondary" variant="contained" disabled>
                        Save
                      </Button>
                    )}
                    <Button color="primary" variant="outlined" onClick={this.handleCancel}>
                      Reset
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Paper>
          <h2>Photos</h2>
          <Paper className="paper-base">
            <div className="asset-form">
              <div className="asset-header">
                <h5>Asset Photos</h5>
              </div>
              {assetkey && <AssetPhotos assetkey={assetkey} />}
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

export default withSnackbar(withApollo(AssetDetails));

AssetDetails.propTypes = {
  client: PropTypes.object,
  match: PropTypes.object,
  enqueueSnackbar: PropTypes.any,
  history: PropTypes.object,
};
