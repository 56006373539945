import React, { Component } from 'react';
import { TextField, FormControl, Paper, Fab, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Subheader from '../../components/Subheader/Subheader';
import AuthHelper from '../../services/AuthHelper';
import EntityPhotoManager from '../../components/Photos/EntityPhotoManager';
import NewsItems from '../../components/News/NewsItems';
import NewsProps from './NewsProps';
import * as SchemaConstants from '../../services/schemaConstants';
import NewsForm from './NewsForm';

export class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contains: '',
      newsKey: 0,
      sub: '',
      identityId: '',
      refresh: false,
    };
  }

  async componentDidMount() {
    const { match } = this.props;
    const newsKey = match.params.newsKey || 0;
    const sub = await AuthHelper.getUserSub();
    const identityId = await AuthHelper.getIdentityId();
    this.setState({ sub, newsKey, identityId });
  }

  getNewsItemHandler = item => {
    this.setState({ newsKey: item.newskey });
  };

  onNewStory = () => {
    this.setState({ newsKey: 0 });
  };

  handleSnackbar = (msg, variant) => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(msg, { variant });
  };

  handleFormChange = () => {
    const { refresh } = this.state;
    this.setState({ refresh: !refresh });
  };

  setNewsKey = newsKey => {
    this.setState({ newsKey });
  };

  render() {
    const { contains, sub, newsKey, identityId, refresh } = this.state;

    return (
      <div className="master-container">
        <div className="master-content">
          <Subheader title="News" />
          <div className="news-container two-col-container">
            <div className="column col-one">
              <Paper className="paper-base">
                <div className="form-card search-action" style={{ marginBottom: '1em' }}>
                  <FormControl className="form-control" />
                  <Fab color="primary" aria-label="Add" size="small" onClick={this.onNewStory}>
                    <AddIcon />
                  </Fab>
                </div>
                <NewsProps handleFormChange={this.handleFormChange} refresh={refresh} children={NewsItems} contains={contains} sub={sub} getNewsItem={this.getNewsItemHandler} />
              </Paper>
            </div>
            <div className="column col-two">
              <NewsForm handleFormChange={this.handleFormChange} newsKey={newsKey} snackbar={this.handleSnackbar} identityId={identityId} sub={sub} setNewsKey={this.setNewsKey} />
              {newsKey ? <EntityPhotoManager title="News Photos" entitykey={newsKey} entitytype={SchemaConstants.GLOBAL_SEARCH_TYPE_NEWS} sub={sub} /> : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
News.propTypes = {
  match: PropTypes.any,
  enqueueSnackbar: PropTypes.func,
};

export default withSnackbar(News);
