import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import { EventQueries } from '../../../queries/Events';
import { EventMutations } from '../../../mutations/Events';

const states = require('../../../assets/lib/states');

const formattedStates = states.map(state => state.abbreviation);

const tableColumns = [
  { title: 'Name', field: 'name' },
  { title: 'City', field: 'city' },
  {
    title: 'State',
    field: 'state',
    lookup: { ...formattedStates },
  },
  { title: 'Country', field: 'country' },
  { title: 'Miles From Venue', field: 'milesfromvenue' },
];

class Travel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fbos: [],
    };
  }

  componentDidMount() {
    this.fetchFBOs();
  }

  fetchFBOs = async () => {
    const { client, eventkey } = this.props;

    try {
      const fbos = await client.query({
        query: EventQueries.GetEventFBOs,
        variables: { eventkey },
      });

      if (fbos && fbos !== undefined && fbos.data && fbos.data !== undefined && fbos.data.getFBOs && fbos.data.getFBOs !== undefined) {
        this.setState({ fbos: fbos.data.getFBOs });
      }
    } catch (error) {
      console.log('Error Fetching FBOs: ', error);
      this.showError('Error Fetching Airports/FBOs');
    }
  };

  addFBO = fbo =>
    new Promise(async resolve => {
      const { client, eventkey } = this.props;

      try {
        if (!fbo.name || fbo.name === undefined) {
          this.showError('FBO Name is required');
        } else {
          const newFBO = {
            eventkey,
            name: fbo.name,
            city: fbo.city,
            state: fbo.state,
            country: fbo.country,
            milesfromvenue: fbo.milesfromvenue,
          };

          const result = await client.mutate({
            mutation: EventMutations.CreateFBO,
            variables: { input: newFBO },
          });

          if (result && result !== undefined && result.data && result.data !== undefined && result.data.createFBO && result.data.createFBO !== undefined) {
            if (result.data.createFBO.wassuccessful === true) {
              this.showSuccess('FBO Added');
              this.fetchFBOs();
            }
          }
        }

        resolve(true);
      } catch (error) {
        console.log('Error Creating FBO: ', error);
        this.showError('Error Creating FBO');
      }
    });

  updateFBO = (newFBO, oldFBO) =>
    new Promise(async resolve => {
      const { client } = this.props;

      try {
        if (!newFBO.name || newFBO.name === undefined) {
          this.showError('FBO Name is required');
        } else {
          const { fbokey } = oldFBO;

          const fboForUpdate = {
            fbokey,
            name: newFBO.name,
            city: newFBO.city,
            state: newFBO.state,
            country: newFBO.country,
            milesfromvenue: newFBO.milesfromvenue,
          };

          const result = await client.mutate({
            mutation: EventMutations.UpdateFBO,
            variables: { input: fboForUpdate },
          });

          if (result && result !== undefined && result.data && result.data !== undefined && result.data.updateFBO && result.data.updateFBO !== undefined) {
            if (result.data.updateFBO.wassuccessful === true) {
              this.showSuccess('FBO Updated');
              this.fetchFBOs();
            }
          }
        }

        resolve(true);
      } catch (error) {
        console.log('Error Saving FBO: ', error);
        this.showError('Error Updating FBO');
      }
    });

  deleteFBO = fbo =>
    new Promise(async resolve => {
      const { client } = this.props;

      try {
        const { fbokey } = fbo;

        const result = await client.mutate({
          mutation: EventMutations.DeleteFBO,
          variables: { fbokey },
        });

        if (result && result !== undefined && result.data && result.data !== undefined && result.data.deleteFBO && result.data.deleteFBO !== undefined) {
          if (result.data.deleteFBO.wassuccessful === true) {
            this.showSuccess('FBO Deleted');
            this.fetchFBOs();
          }
        }

        resolve(true);
      } catch (error) {
        console.log('Error Saving FBO: ', error);
        this.showError('Error Updating FBO');
      }
    });

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'success' });
  };

  render() {
    const { fbos } = this.state;

    return (
      <div className="paper-table">
        <MaterialTable
          style={{ padding: '1em' }}
          title={<p className="material-table-header">FBO #</p>}
          columns={tableColumns}
          data={fbos}
          editable={{
            onRowAdd: fbo => this.addFBO(fbo),
            onRowUpdate: (newFBO, oldFBO) => this.updateFBO(newFBO, oldFBO),
            onRowDelete: fbo => this.deleteFBO(fbo),
          }}
        />
      </div>
    );
  }
}

export default withSnackbar(withApollo(Travel));

Travel.propTypes = {
  client: PropTypes.object.isRequired,
  eventkey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};
