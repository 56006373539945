import gql from 'graphql-tag';

const GetNews = gql`
  query NewsQuery($contains: String) {
    getNews(contains: $contains) {
      isactive
      title
      content
      newskey
      datecreated
      name
      documentkey
      accesslevel
      identityid
      ispublished
    }
  }
`;

const GetNewsById = gql`
  query NewsQuery($newskey: Int!) {
    getNewsById(newskey: $newskey) {
      title
      content
      isactive
      ispublished
      newskey
      datecreated
      identityid
      photo {
        documentkey
        name
        isactive
        isnewsbanner
        accesslevel
        identityid
      }
    }
  }
`;

export const NewsQueries = {
  GetNews,
  GetNewsById,
};
