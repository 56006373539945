const memberDetails = [
  {
    label: 'Personal Info',
    icon: 'account_box',
    uri: '#personal-info',
  },
  {
    label: 'Notes',
    icon: 'note',
    uri: '#notes',
  },
  {
    label: 'Interests',
    icon: 'local_activity',
    uri: '#interests',
  },
  {
    label: 'Events',
    icon: 'event',
    uri: '#events',
  },
  {
    label: 'Assets',
    icon: 'web_asset',
    uri: '#assets',
  },
  {
    label: 'VCard',
    icon: 'contact_mail',
    uri: '#vcard',
  },
];

const eventDetails = [
  {
    label: 'Event Details',
    icon: 'event',
    uri: '#event-details',
  },
  {
    label: 'Venue Details',
    icon: 'location_city',
    uri: '#venue-location',
  },
  {
    label: 'FBO',
    icon: 'local_airport',
    uri: '#fbo',
  },
  {
    label: 'Documents',
    icon: 'file_copy',
    uri: '#documents',
  },
  {
    label: 'Attendees',
    icon: 'people',
    uri: '#attendees',
  },
  {
    label: 'Notes',
    icon: 'note',
    uri: '#notes',
  },
];

export const DrawerIcons = {
  memberDetails,
  eventDetails,
};
