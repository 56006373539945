// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';

export const EditMeasurements = gql`
  mutation EditMeasurements($input: MeasurementsInput!) {
    editMeasurements(input: $input) {
      wassuccessful
    }
  }
`;
