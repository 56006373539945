import gql from 'graphql-tag';

const createPoll = gql`
  mutation PollMutation($input: PollInput!) {
    createPoll(input: $input)
  }
`;

const updatePoll = gql`
  mutation PollMutation($input: PollInput!) {
    updatePoll(input: $input)
  }
`;

export const PollMutations = {
  createPoll,
  updatePoll,
};
