import gql from 'graphql-tag';

const GetSettingByCodename = gql`
  query GetSettingByCodename($codename: String!) {
    getSettingByCodename(codename: $codename) {
      settingkey
      value
    }
  }
`;

const GetSettingsByCodenames = gql`
  query GetSettingsByCodenames($codenames: [String!]) {
    getSettingsByCodenames(codenames: $codenames) {
      settingkey
      value
    }
  }
`;

export const SettingQueries = {
  GetSettingByCodename,
  GetSettingsByCodenames,
};
