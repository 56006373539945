import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import defaultHeadshot from '../../assets/images/default_headshot.svg';

export class ProfilePic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageLoading: true,
    };
  }

  componentDidMount() {
    const { document } = this.props;
    this.fetchProfilePic(document);
  }

  async fetchProfilePic() {
    let imageUrl = defaultHeadshot;
    if (!imageUrl || imageUrl === undefined) {
      imageUrl = defaultHeadshot;
    }
    this.setState({ imageUrl, imageLoading: false });
  }

  render() {
    const { imageUrl, imageLoading } = this.state;
    return imageLoading ? <CircularProgress /> : <img src={imageUrl} alt="profile" />;
  }
}

export default ProfilePic;

ProfilePic.propTypes = {
  document: PropTypes.object,
};
