import { Auth } from 'aws-amplify';

const getUserSub = () =>
  new Promise(async resolve => {
    try {
      const userInfo = await Auth.currentUserInfo();

      if (!userInfo || userInfo === undefined) {
        throw new Error('Could not get authenticated user');
      }

      const { attributes } = userInfo;

      if (!attributes || attributes === undefined) {
        throw new Error('Could not get user attributes');
      }

      const { sub } = attributes;

      if (!sub || sub === undefined) {
        throw new Error('sub not available on payload');
      }

      resolve(sub);
    } catch (error) {
      console.log('Error getting userSub: ', error);
      throw error;
    }
  });

const getIdentityId = async () =>
  new Promise(async resolve => {
    try {
      const userInfo = await Auth.currentUserInfo();
      console.log('USERINFO', userInfo);
      if (!userInfo || userInfo === undefined) {
        throw new Error('Could not get authenticated user');
      }

      const { id } = userInfo;

      if (!id || id === undefined) {
        throw new Error('identityId not available on userInfo object');
      }

      resolve(id);
    } catch (error) {
      console.log('Error getting identityId');
      throw error;
    }
  });

const AuthHelper = {
  getUserSub,
  getIdentityId,
};

export default AuthHelper;
