import React from 'react';
import moment from 'moment';
import { Paper, Typography } from '@material-ui/core';
import { ProfilePic } from '../Messages/ProfilePic';
import DocumentPhoto from '../DocumentPhoto/DocumentPhoto';

const NewsItems = parent => {
  function handleClick(item) {
    parent.getNewsItem(item);
  }
  if (!Array.isArray(parent.data) || !parent.data.length) {
    return null;
  }

  return parent.data.map((item, index) => (
    <Paper key={item.newskey.toString()} className="paper-base two-col-item-container" onClick={() => handleClick(item)}>
      <div className="two-column profile-pic">
        <DocumentPhoto document={{ name: item.name, documentkey: item.documentkey, accesslevel: item.accesslevel, identityid: item.identityid }} disableCarousel defaultPhoto="generic" />
      </div>
      <div className="two-column details">
        <ul className="title-date">
          <li className="title">{item.title}</li>
          <li className="date">{moment(item.datecreated).format('MMM D')}</li>
        </ul>
        <Typography className="content" component="p">
          {item.content}
        </Typography>
      </div>
    </Paper>
  ));
};

export default NewsItems;
