import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import EventDetailsForm from '../../components/EventDetails/index';
import Notes from '../../components/MemberDetails/Notes/Notes';
import Documents from '../../components/Documents/Documents';
import Subheader from '../../components/Subheader/Subheader';
import LeftDrawer from '../../components/Drawer/LeftDrawer';
import { DrawerIcons } from '../../components/Drawer/DrawerIcons';

export class EventDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerOpen: false,
    };
  }

  setBannerContent = content => {
    this.setState({ ...content });
  };

  toggleDrawer = () => {
    this.setState(prevState => ({ drawerOpen: !prevState.drawerOpen }));
  };

  render() {
    const { match } = this.props;
    const { eventkey } = match.params;

    const { Details, Travel, EventAttendance } = EventDetailsForm;
    const { drawerOpen } = this.state;

    return (
      <div className="master-container">
        <LeftDrawer toggleDrawer={this.toggleDrawer} open={drawerOpen} children={DrawerIcons.eventDetails} />
        <div className={`master-content ${drawerOpen ? '' : 'master-content-full'}`}>
          <Subheader title="Event Details" />
          <div className="event-details-container">
            <h2 id="event-details" className="anchor">
              Event
            </h2>
            <Details eventkey={eventkey} setBannerContent={this.setBannerContent} />

            <h2 id="fbo" className="anchor">
              FBO
            </h2>
            <Travel eventkey={eventkey} />

            <h2 id="documents" className="anchor">
              Documents
            </h2>
            <Documents entityKey={eventkey} entityType="event" />

            <h2 id="attendees" className="anchor">
              Attendees
            </h2>
            <EventAttendance eventkey={eventkey} />

            <h2 id="notes" className="anchor">
              Notes
            </h2>
            <Notes entitykey={parseInt(eventkey)} keyType="event" />
          </div>
        </div>
      </div>
    );
  }
}

EventDetails.propTypes = {
  match: PropTypes.object,
};

export default withApollo(withRouter(EventDetails));
