import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withSnackbar } from 'notistack';
import { FormControl, TextField, IconButton, Button } from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { PollOptionQueries } from '../../queries/PollOption';
import { PollOptionMutations } from '../../mutations/PollOption';

class PollOptionForm extends Component {
  _ismounted = false;

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      canAddOption: false,
    };
  }

  componentDidMount() {
    this._ismounted = true;
    this.getPollOptions();
  }

  componentDidUpdate(prevProps) {
    const { pollKey, pollType } = this.props;
    if (pollKey !== prevProps.pollKey) {
      this.getPollOptions();
    }
    if (pollType.key !== prevProps.pollType.key) {
      this.validate();
    }
  }

  async getPollOptions() {
    const { client, pollKey } = this.props;
    if (pollKey === 0) {
      this.setState({ data: [], canAddOption: false });
      return;
    }

    const pollOptions = await client.query({
      query: PollOptionQueries.getPollOptions,
      variables: { pollkey: pollKey },
    });

    if (this._ismounted) {
      this.setState(
        {
          data: pollOptions.data.getPollOptions,
        },
        () => this.validate()
      );
    }
  }

  async createPollOption(input) {
    const { client, enqueueSnackbar } = this.props;
    const { data } = this.state;
    input.order = data.length + 1;

    let response = null;
    try {
      response = await client.mutate({
        mutation: PollOptionMutations.createPollOption,
        variables: { input },
      });
    } catch (err) {
      enqueueSnackbar('The poll options must match the poll type.', { variant: 'error' });
      return 0;
    }

    return response.data.createPollOption;
  }

  async updatePollOption(input) {
    const { client } = this.props;
    await client.mutate({
      mutation: PollOptionMutations.updatePollOption,
      variables: { input },
    });
  }

  validate = () => {
    let additionalOptionAllowed = false;
    const { pollKey, pollType } = this.props;
    const { data } = this.state;
    switch (pollType.key) {
      case 'Single':
        additionalOptionAllowed = data.length < 2;
        break;
      case 'Multiple.Single':
      case 'Multiple.Multiple':
        additionalOptionAllowed = data.length < 5;
        break;
      default:
        break;
    }

    const canAddOption = additionalOptionAllowed && pollKey > 0;
    this.setState({ canAddOption });
  };

  addOption = async () => {
    const { pollKey } = this.props;
    this.setState(
      prevState => ({
        data: [
          ...prevState.data,
          {
            polloptionkey: 0,
            pollkey: pollKey,
            text: '',
            order: prevState.data.length + 1,
          },
        ],
      }),
      () => this.validate()
    );
  };

  removeOption = async option => {
    const { client } = this.props;
    let response = null;

    try {
      response = await client.mutate({
        mutation: PollOptionMutations.deletePollOption,
        variables: { polloptionkey: option.polloptionkey },
      });
    } catch (err) {
      console.log('err');
      return;
      // enqueueSnackbar();
    }
    const wasUpdated = response.data.deletePollOption;

    if (wasUpdated) {
      this.setState(
        prevState => ({
          data: prevState.data.filter(o => o.polloptionkey !== option.polloptionkey),
        }),
        () => this.validate()
      );
    }
  };

  handleBlur = async event => {
    const { pollKey } = this.props;
    const { data } = this.state;
    let polloptionkey = parseInt(event.target.name.split('-')[1]);
    const index = data.findIndex(x => x.polloptionkey === polloptionkey);
    const input = {
      pollkey: pollKey,
      polloptionkey,
      text: data[index].text,
    };

    if (polloptionkey === 0) {
      polloptionkey = await this.createPollOption(input);
      if (polloptionkey === 0) {
        return;
      }
      // add update helper or lodash?
      const items = data;
      const item = { ...items[index] };
      item.polloptionkey = polloptionkey;
      items[index] = item;
      this.setState({ data: items });
      return;
    }
    await this.updatePollOption(input);
  };

  handleChange = event => {
    const { name, value } = event.target;
    const { data } = this.state;
    const polloptionkey = Number(name.split('-')[1]);
    const index = data.findIndex(x => x.polloptionkey === polloptionkey);
    if (index === -1) {
      throw new Error('out of range exception on updating poll options.');
    }
    this.setState({
      data: [...data.slice(0, index), Object.assign({}, data[index], { text: value }), ...data.slice(index + 1)],
    });
  };

  componentWillUnmount() {
    this._ismounted = false;
  }

  render() {
    const { data, canAddOption } = this.state;

    return (
      <div>
        {data.map((item, index) => {
          const name = `option-${item.polloptionkey}`;
          return (
            <div className="form-card" key={index}>
              <FormControl className="form-control option-row">
                <TextField id={name} name={name} value={item.text} onChange={this.handleChange} onBlur={this.handleBlur} />
                <IconButton className="option-row-btn" onClick={() => this.removeOption(item)}>
                  <DeleteOutline color="error" />
                </IconButton>
              </FormControl>
            </div>
          );
        })}
        <div className="form-submit-row">
          <Button color="primary" onClick={this.addOption} disabled={!canAddOption}>
            Add Response
          </Button>
        </div>
      </div>
    );
  }
}

PollOptionForm.propTypes = {
  client: PropTypes.object.isRequired,
  pollKey: PropTypes.number.isRequired,
  pollType: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func,
};

export default withSnackbar(withApollo(PollOptionForm));
