// eslint-disable-next-line import/no-extraneous-dependencies
import { Auth } from 'aws-amplify';

const AWS = require('aws-sdk');

AWS.config.region = process.env.REGION == null ? 'us-east-1' : process.env.REGION;

const getParametersByPath = async (data, errorCallback) =>
  new Promise(async (resolve, reject) => {
    const request = {
      Path: data,
      WithDecryption: true,
      Recursive: true,
    };

    Auth.currentCredentials().then(creds => {
      const ssm = new AWS.SSM({
        region: 'us-west-2',
        credentials: creds,
      });

      ssm.getParametersByPath(request, function(err, res) {
        if (err) {
          console.log(`Error from getParametersByPath: ${err}`);
          reject();
          return;
        }

        const params = {};

        for (const p of res.Parameters) {
          params[p.Name] = p.Value;
        }

        resolve(params);
      });
    });
  }).catch(error => {
    console.log(`Error getting parameters: ${error}`);
    return null;
  });

const SSMHelper = {
  getParametersByPath,
};

export default SSMHelper;
