import EventSubHeader from './EventSubheader/EventSubheader';
import EventDetails from './EventDetails/EventDetails';
import Travel from './Travel/Travel';
import EventPhotos from './EventPhotos/EventPhotos';
import EventAttendance from './EventAttendance/EventAttendance';

const EventDetailsForm = {
  Subheader: EventSubHeader,
  Details: EventDetails,
  Travel,
  EventPhotos,
  EventAttendance,
};

export default EventDetailsForm;
