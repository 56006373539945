import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
import { LoggingConstants } from '../constants/loggingConstants';
import LogHelper from '../helpers/loggingHelper';

const sendTextMessage = async payload =>
  new Promise((resolve, reject) => {
    try {
      const params = {
        Message: payload.Message,
        PhoneNumber: payload.PhoneNumber,
      };

      Auth.currentCredentials().then(creds => {
        const publishTextPromise = new AWS.SNS({
          apiVersion: '2010-03-31',
          region: 'us-west-2',
          credentials: creds,
        })
          .publish(params)
          .promise();

        publishTextPromise
          .then(function(data) {
            console.log(`MessageID is ${data.MessageId}`);
            resolve();
          })
          .catch(function(err) {
            console.error(err, err.stack);
            throw err;
          });
      });
    } catch (error) {
      LogHelper.logError(`Error sending text to ${payload.PhoneNumber}-${payload.Message}: ${error}`, LoggingConstants.AREA.SNS);
      reject(error);
    }
  });

const SNSHelper = {
  sendTextMessage,
};

export default SNSHelper;
