import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles, Divider, IconButton, Icon } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const drawerWidth = '15em';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    paddingTop: '7.5em',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    paddingTop: '7.5em',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
  iconButton: {
    width: '2em',
    paddingLeft: '0.8em',
    marginLeft: '0.2em',
    paddingRight: '0.8em',
  },
}));
export default function LeftDrawer(props) {
  const classes = useStyles();
  const { open, children, toggleDrawer } = props;
  return (
    <Drawer
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      anchor="left"
      variant="permanent"
      open={open}
    >
      <IconButton className={classes.iconButton}>
        {open ? <ChevronLeftIcon fontSize="inherit" color="primary" onClick={toggleDrawer} /> : <ChevronRightIcon fontSize="inherit" color="primary" onClick={toggleDrawer} />}
      </IconButton>
      <Divider />
      <List>
        {children.map((item, index) => (
          <ListItem button key={index} component="a" href={item.uri}>
            <ListItemIcon>
              <Icon>{item.icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

LeftDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.any,
  toggleDrawer: PropTypes.func,
};
