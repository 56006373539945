import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, MenuItem } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import MaterialDatePicker from '../DatePicker';

const ReportFilters = ({
  reportType,
  data,
  handleChange,
  content,
  handleDateChange,
  clearFilters,
  sender,
  status,
  receiver,
  startDateTime,
  endDateTime,
  isLoadingCSV,
  handleCSVDownload,
  csvDownload,
}) => (
  <div className="flex justify-between mb-1 align-center report-summary-filters row">
    {reportType === 'Call Requests' && (
      <div className="wrap">
        <TextField name="sender" label="Filter by Sender" className="filter-input" value={sender} onChange={handleChange} />
        <TextField name="receiver" label="Filter by Receiver" className="filter-input" value={receiver} onChange={handleChange} />
        <TextField name="status" label="Filter by Status" className="filter-input" value={status} onChange={handleChange} select>
          <MenuItem value="approved">Approved</MenuItem>
          <MenuItem value="denied">Denied</MenuItem>
          <MenuItem value="pending">Pending</MenuItem>
        </TextField>
        <div className="filter-input">
          <MaterialDatePicker onChangeFunc={handleDateChange} value={startDateTime || null} label="Start Date:" fieldname="startDateTime" />
        </div>
        <div className="filter-input">
          <MaterialDatePicker onChangeFunc={handleDateChange} value={endDateTime || null} label="End Date:" fieldname="endDateTime" />
        </div>
      </div>
    )}
    {reportType === 'Voice Calling' && (
      <div className="wrap">
        <TextField name="sender" label="Filter by Sender" className="filter-input" value={sender} onChange={handleChange} />
        <TextField name="receiver" label="Filter by Receiver" className="filter-input" value={receiver} onChange={handleChange} />
        <TextField name="status" label="Filter by Status" className="filter-input" value={status} onChange={handleChange} select>
          <MenuItem value="completed">Completed</MenuItem>
          <MenuItem value="busy">Busy</MenuItem>
          <MenuItem value="no-answer">No-Answer</MenuItem>
        </TextField>
        <div className="filter-input">
          <MaterialDatePicker onChangeFunc={handleDateChange} value={startDateTime || null} label="Start Date:" fieldname="startDateTime" />
        </div>
        <div className="filter-input">
          <MaterialDatePicker onChangeFunc={handleDateChange} value={endDateTime || null} label="End Date:" fieldname="endDateTime" />
        </div>
      </div>
    )}
    {reportType === 'Messaging' && (
      <div className="wrap">
        <TextField name="sender" label="Filter by Member1" className="filter-input" value={sender} onChange={handleChange} />
        <TextField name="receiver" label="Filter by Member2" className="filter-input" value={receiver} onChange={handleChange} />

        <div className="filter-input">
          <MaterialDatePicker onChangeFunc={handleDateChange} value={startDateTime || null} label="Start Date:" fieldname="startDateTime" />
        </div>
        <div className="filter-input">
          <MaterialDatePicker onChangeFunc={handleDateChange} value={endDateTime || null} label="End Date:" fieldname="endDateTime" />
        </div>
      </div>
    )}
    {reportType === 'VCard Sharing' && (
      <div className="wrap">
        <TextField name="sender" label="Filter by Sender" className="filter-input" value={sender} onChange={handleChange} />
        <TextField name="receiver" label="Filter by Receiver" className="filter-input" value={receiver} onChange={handleChange} />
        <TextField name="status" label="Filter by Status" className="filter-input" value={status} onChange={handleChange} select>
          <MenuItem value="accepted">Accepted</MenuItem>
          <MenuItem value="denied">Denied</MenuItem>
          <MenuItem value="pending">Pending</MenuItem>
        </TextField>
        <div className="filter-input">
          <MaterialDatePicker onChangeFunc={handleDateChange} value={startDateTime || null} label="Start Date:" fieldname="startDateTime" />
        </div>
        <div className="filter-input">
          <MaterialDatePicker onChangeFunc={handleDateChange} value={endDateTime || null} label="End Date:" fieldname="endDateTime" />
        </div>
      </div>
    )}
    {reportType === 'Broadcast Messages' && (
      <div className="wrap">
        <TextField name="content" label="Message Content" className="filter-input" value={content} onChange={handleChange} />
        <div className="filter-input">
          <MaterialDatePicker onChangeFunc={handleDateChange} value={startDateTime || null} label="Start Date:" fieldname="startDateTime" />
        </div>
        <div className="filter-input">
          <MaterialDatePicker onChangeFunc={handleDateChange} value={endDateTime || null} label="End Date:" fieldname="endDateTime" />
        </div>
      </div>
    )}
    {reportType === 'Conversation Details' && (
      <div className="wrap">
        <div className="filter-input">
          <MaterialDatePicker onChangeFunc={handleDateChange} value={startDateTime || null} label="Start Date:" fieldname="startDateTime" />
        </div>
        <div className="filter-input">
          <MaterialDatePicker onChangeFunc={handleDateChange} value={endDateTime || null} label="End Date:" fieldname="endDateTime" />
        </div>
      </div>
    )}
    <div className="flex report-buttons-container row">
      <div className="mr-1 report-table-button">
        <Button variant="outlined" color="primary" size="small" onClick={clearFilters}>
          CLEAR FILTERS
        </Button>
      </div>
      <div className="report-table-button">
        <Button variant="contained" color="primary" size="small" disabled={isLoadingCSV} onClick={handleCSVDownload}>
          EXPORT VIEW
        </Button>
        <CSVLink style={{ display: 'none' }} ref={csvDownload} data={data} filename={`${reportType}-${new Date().toLocaleDateString()}.csv`} />
      </div>
    </div>
  </div>
);

export default ReportFilters;

ReportFilters.propTypes = {
  handleChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  clearFilters: PropTypes.func,
  reportType: PropTypes.string,
  data: PropTypes.array,
  sender: PropTypes.string,
  receiver: PropTypes.string,
  status: PropTypes.string,
  startDateTime: PropTypes.any,
  endDateTime: PropTypes.any,
  isLoadingCSV: PropTypes.bool,
  handleCSVDownload: PropTypes.func,
  csvDownload: PropTypes.any,
  content: PropTypes.string,
};
