const isEmptyOrWhitespace = str => str === null || str === undefined || str.trim() === '';

const isNotLetter = str => {
  if (str.match(/[a-z/A-Z]/g)) return false;
  return true;
};

// thanks to https://emailregex.com/
const isValidEmail = str => {
  if (str.match(/[^@\s]+@[^@\s]+\.[^@\s]+/)) {
    return true;
  }
  return false;
};

const InputHelper = {
  isEmptyOrWhitespace,
  isNotLetter,
  isValidEmail,
};

export default InputHelper;
