import gql from 'graphql-tag';

const GetNotesByPerson = gql`
  query GetNotesByPerson($personkey: Int!) {
    getNotesByPerson(personkey: $personkey) {
      notekey
      title
      details
      createddate
    }
  }
`;

const GetNotesByEvent = gql`
  query GetNotesByEvent($eventkey: Int!) {
    getNotesByEvent(eventkey: $eventkey) {
      notekey
      title
      details
      createddate
    }
  }
`;

export const NotesQueries = {
  GetNotesByPerson,
  GetNotesByEvent,
};
