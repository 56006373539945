import gql from 'graphql-tag';

const CreateEvent = gql`
  mutation($input: NewEventInput!) {
    createEvent(input: $input) {
      key
      wassuccessful
      error
    }
  }
`;

const UpdateEvent = gql`
  mutation($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      wassuccessful
      error
    }
  }
`;

const PublishEvent = gql`
  mutation($eventkey: ID!) {
    publishEvent(eventkey: $eventkey) {
      wassuccessful
      error
    }
  }
`;

const DeleteEvent = gql`
  mutation($eventkey: Int!) {
    deleteEvent(eventkey: $eventkey) {
      wassuccessful
      error
    }
  }
`;

const CreateFBO = gql`
  mutation($input: NewFBOInput!) {
    createFBO(input: $input) {
      key
      wassuccessful
      error
    }
  }
`;

const UpdateFBO = gql`
  mutation($input: UpdateFBOInput!) {
    updateFBO(input: $input) {
      wassuccessful
      error
    }
  }
`;

const DeleteFBO = gql`
  mutation($fbokey: ID!) {
    deleteFBO(fbokey: $fbokey) {
      wassuccessful
      error
    }
  }
`;

const ConfirmEventAttendance = gql`
  mutation ConfirmEventAttendance($eventkey: ID!, $personkey: ID!) {
    confirmEventAttendance(eventkey: $eventkey, personkey: $personkey) {
      wassuccessful
      error
    }
  }
`;

const CancelEventAttendance = gql`
  mutation CancelEventAttendance($eventkey: ID!, $personkey: ID!) {
    cancelEventAttendance(eventkey: $eventkey, personkey: $personkey) {
      wassuccessful
      error
    }
  }
`;

const UploadEventPhoto = gql`
  mutation UploadEventPhoto($eventkey: ID!, $sub: String!, $name: String!, $accesslevel: String!, $isbanner: Boolean!) {
    uploadEventPhoto(eventkey: $eventkey, sub: $sub, name: $name, accesslevel: $accesslevel, isbanner: $isbanner) {
      photo {
        documentkey
        name
        eventkey
        accesslevel
        identityid
      }
      wassuccessful
      error
    }
  }
`;

export const EventMutations = {
  CreateEvent,
  UpdateEvent,
  PublishEvent,
  DeleteEvent,

  CreateFBO,
  UpdateFBO,
  DeleteFBO,

  ConfirmEventAttendance,
  CancelEventAttendance,

  UploadEventPhoto,
};
