import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

class EventSubheader extends Component {
  render() {
    const { name, location, date } = this.props;

    return (
      <div className="event-detail-subheader">
        <div className="subheader-container">
          <div className="event-subheader-summary">
            <div className="event-subheader-details">
              <h2>{name}</h2>
              <h5>{location}</h5>
              <h5>{date}</h5>
            </div>
          </div>
          <div className="event-header-buttons">
            <a href="#notes">
              <Button variant="contained" color="secondary" size="medium" onClick={() => null}>
                Add Note
              </Button>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

EventSubheader.propTypes = {
  location: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
};

export default EventSubheader;
