import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import InterestAutocomplete from '../Filters/InterestAutocomplete';
import * as SchemaConstants from '../../../services/schemaConstants';

export default class Interests extends Component {
  render() {
    const { personKey } = this.props;
    return (
      <Paper className="paper-base">
        <div className="row section">
          <h5 className="paper-header">Member Interests</h5>
        </div>
        <div className="tagform-container">
          <InterestAutocomplete personKey={personKey} filtertype={SchemaConstants.MEMBER_FILTER_INTEREST_PERSONAL} label="Personal #" placeholder="Select multiple personal interests..." />
          <InterestAutocomplete personKey={personKey} filtertype={SchemaConstants.MEMBER_FILTER_INTEREST_FINANCIAL} label="Investments #" placeholder="Select multiple investment interests..." />
          {/* <InterestAutocomplete personKey={personKey} filtertype={SchemaConstants.MEMBER_FILTER_INTEREST_PHILANTHROPY} label="Philanthropy" placeholder="Select multiple philanthropy interests..." /> */}
        </div>
      </Paper>
    );
  }
}

Interests.propTypes = {
  personKey: PropTypes.number.isRequired,
};
