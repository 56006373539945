import React, { Component } from 'react';
// import PropTypes from 'prop-types';

export default class Footer extends Component {
  // static propTypes = {
  //   prop: PropTypes,
  // };

  render() {
    return (
      <div className="footer-container">
        <h3>&#169; R10 Organization</h3>
      </div>
    );
  }
}
