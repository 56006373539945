import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Fab } from '@material-ui/core';
import Camera from '@material-ui/icons/CameraEnhanceOutlined';
import DoneIcon from '@material-ui/icons/Done';
import Dropzone from 'react-dropzone';
import { withSnackbar } from 'notistack';
import { withApollo } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DocumentMutations } from '../../../mutations/Document';
import { AssetMutations } from '../../../mutations/Assets';

import S3Helper from '../../../services/S3Helper';
import AuthHelper from '../../../services/AuthHelper';

const uuidv4 = require('uuid/v4');

class PhotoUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoSelections: [],
      uploading: false,
      uploaded: true,
      dialogOpen: false,
    };
  }

  async componentDidMount() {
    const identityId = await AuthHelper.getIdentityId();
    const sub = await AuthHelper.getUserSub();

    this.setState({ identityId, sub });
  }

  uploadPhotos = async () => {
    const { client, personkey, fetchProfilePhoto, setFeaturedPhoto, assetkey } = this.props;
    const { photoSelections, identityId, sub } = this.state;

    let uploadSuccessful = true;

    try {
      this.setState({ uploading: true });

      await Promise.all(
        photoSelections.map(async file => {
          const filename = `${uuidv4()}.${file.name.split('.')[1]}`;
          const params = {
            name: filename,
            object: file,
            path: file.preview,
            access: 'protected',
            identityId,
            progressFunc: null,
          };
          const uploadResult = await S3Helper.putObject(params);

          if (uploadResult && !assetkey) {
            const profilePhoto = {
              personkey,
              name: filename,
              sub,
              accesslevel: 'protected',
            };

            const result = await client.mutate({
              mutation: DocumentMutations.SaveProfilePicture,
              variables: profilePhoto,
            });

            if (result && result !== undefined && result.data && result.data !== undefined && result.data.saveProfilePicture && result.data.saveProfilePicture !== undefined) {
              if (result.data.saveProfilePicture.wassuccessful === true) {
                this.showSuccess(`${file.name} uploaded`);
                const { profilepicture } = result.data.saveProfilePicture;
                fetchProfilePhoto(profilepicture);
                this.closeDialog();
              } else {
                this.showError(`Error uploading ${file.name}`);
                uploadSuccessful = false;
              }
            }
          } else if (uploadResult && assetkey) {
            const featuredPhoto = {
              entitykey: assetkey,
              entitytype: 'asset',
              name: filename,
              sub,
              accesslevel: 'protected',
              isbanner: true,
            };
            const result = await client.mutate({
              mutation: DocumentMutations.UploadDocument,
              variables: featuredPhoto,
            });

            if (result && result !== undefined && result.data && result.data !== undefined && result.data.uploadDocument) {
              if (result.data.uploadDocument.wassuccessful === true) {
                this.showSuccess(`${file.name} uploaded`);
                const { featuredphoto } = result.data.uploadDocument;
                setFeaturedPhoto(featuredphoto);
                this.closeDialog();
              } else {
                this.showError(`Error uploading ${file.name}`);
                uploadSuccessful = false;
              }
            }
          } else {
            this.showError(`Error uploading ${file.name}`);
            uploadSuccessful = false;
          }
        })
      );
    } catch (error) {
      console.log(error);
      this.setState({ uploading: false });
      this.showError('Error Uploading Photo');
    }

    this.setState({ uploading: false, photoSelections: [] });

    if (uploadSuccessful) {
      this.setState({ uploaded: true });
    }
  };

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'success' });
  };

  openDialog = () => {
    this.setState({ dialogOpen: true, uploading: false, uploaded: false });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false, photoSelections: [], uploading: false, uploaded: false });
  };

  setPhotoSelections = photos => {
    this.setState({ photoSelections: photos });
  };

  render() {
    const { dialogOpen, photoSelections, uploading, photo, uploaded } = this.state;
    let emptyPhotoText = null;

    if (!photo || photo.length === 0) {
      emptyPhotoText = <div className="photo-empty-text">No photos have been added yet</div>;
    }

    let dialogTitleFeedback = null;

    if (uploading === true) {
      dialogTitleFeedback = <CircularProgress className="event-photo-upload-feedback" />;
    } else if (uploaded === true) {
      dialogTitleFeedback = <DoneIcon className="event-photo-upload-feedback" />;
    }
    return (
      <React.Fragment>
        <Dialog open={dialogOpen} onClose={this.closeDialog}>
          <DialogTitle id="photo-dialog-title">Upload Photo {dialogTitleFeedback}</DialogTitle>
          <DialogContent dividers>
            <Dropzone
              accept="image/*"
              onDrop={acceptedFiles =>
                this.setPhotoSelections(
                  acceptedFiles.map(file =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    })
                  )
                )
              }
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="photo-drop-container">
                  <input {...getInputProps()} />
                  <p>Drag and drop profile photo here, or click to select from file</p>
                </div>
              )}
            </Dropzone>

            <div className="preview-container">
              {photoSelections.map(profilePhoto => (
                <img src={profilePhoto.preview} className="event-preview-image" alt="upload-preview" />
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} className="event-dialog-button">
              Cancel
            </Button>
            <Button onClick={this.uploadPhotos} className="event-dialog-button primary" color="primary" disabled={!(photoSelections && photoSelections.length > 0) || uploading === true}>
              Upload
            </Button>
          </DialogActions>
        </Dialog>
        <div className="add-photo">
          <Tooltip title="Add Photo">
            <Fab color="secondary" size="small" className="plus-button" onClick={this.openDialog}>
              <Camera color="primary" />
            </Fab>
          </Tooltip>
        </div>
      </React.Fragment>
    );
  }
}

export default withApollo(withSnackbar(PhotoUploader));

PhotoUploader.propTypes = {
  enqueueSnackbar: PropTypes.func,
  client: PropTypes.object,
  fetchProfilePhoto: PropTypes.func,
  personkey: PropTypes.number,
};
