import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import Moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
import Tooltip from '@material-ui/core/Tooltip';
import { withRouter } from 'react-router';
import { EventQueries } from '../../../queries/Events';

class UpcomingEvents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
    };
  }

  componentDidMount() {
    this.fetchEvents();
  }

  dataToState(eventList) {
    if (eventList && eventList !== undefined) {
      const { data } = eventList;

      if (data && data !== undefined && Array.isArray(data.getEvents)) {
        const events = data.getEvents;

        this.setState({ events, loading: false });
      } else {
        this.handleError('Unexpected list format');
        this.setState({ loading: false });
      }
    } else {
      this.setState({ loading: false });
      console.log('No Events Returned');
    }
  }

  async fetchEvents() {
    const { client } = this.props;

    this.setState({ loading: true });

    try {
      const events = await client.query({
        query: EventQueries.GetEvents,
        variables: { upcoming: true },
      });

      this.dataToState(events);
    } catch (error) {
      this.setState({ loading: false });
      this.handleError(error);
    }
  }

  renderEvents = () => {
    const { events, error } = this.state;

    if (error) {
      return <div>Error Loading Events</div>;
    }

    if (events && Array.isArray(events) && events.length > 0) {
      return events.map((event, index) => {
        const startMoment = Moment(event.adjustedstartdate);
        const endMoment = Moment(event.adjustedenddate);

        const startDateText = startMoment.format('DD');
        const endDateText = endMoment.format('DD');

        let dateText = startDateText;

        if (startDateText !== endDateText) {
          dateText += `-${endDateText}`;
        }

        const startMonth = startMoment.format('MMMM');
        const endMonth = endMoment.format('MMMM');

        let displayDate = (
          <React.Fragment key={`${event}-${index}`}>
            <p className="upcoming-event-month">{startMonth}</p>
            <p>{dateText}</p>
          </React.Fragment>
        );

        if (startMonth !== endMonth) {
          displayDate = (
            <React.Fragment key={`${event}-${index}`}>
              <p className="upcoming-event-month">{`${startMonth} ${startDateText}`}</p>
              <p>{`${endMonth} ${endDateText}`}</p>
            </React.Fragment>
          );
        }
        // const detailLink = `/events/${event.eventkey}`;
        return (
          <div className="upcoming-event-row" key={`${event}-${index}`}>
            <div className="upcoming-event-date">{displayDate}</div>
            <div className="upcoming-event-location">{event.location}</div>
            <Tooltip title="View Details">
              <IconButton variant="contained" component="span" className="upcoming-event-detail-button" onClick={() => this.navigateToEvent(event.eventkey)}>
                <LaunchIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </div>
        );
      });
    }

    return <div>No events scheduled</div>;
  };

  navigateToEvent = eventkey => {
    const { history } = this.props;

    history.push({
      pathname: `./events/${eventkey}`,
    });
  };

  handleError = message => {
    this.setState({ error: message });
    console.log('An Error Occurred: ', message);
  };

  renderLoader = () => (
    <div className="spinner-container">
      <CircularProgress />
    </div>
  );

  render() {
    const { loading } = this.state;

    return (
      <div className="upcoming-events-card">
        <div className="row section">
          <h5>Upcoming Events</h5>
        </div>
        <div className="upcoming-events-grid">{loading ? this.renderLoader() : this.renderEvents()}</div>
      </div>
    );
  }
}

export default withRouter(withApollo(UpcomingEvents));

UpcomingEvents.propTypes = {
  client: PropTypes.object.isRequired,
  history: PropTypes.any,
};
