/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Carousel, { Modal, ModalGateway } from 'react-images';
import CircularProgress from '@material-ui/core/CircularProgress';
import S3Helper from '../../services/S3Helper';
import defaultHeadshot from '../../assets/images/default_headshot.svg';
import defaultImage from '../../assets/images/default_image.png';

export default class DocumentPhoto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: null,
      imageLoading: true,
      document: null,
      documentkey: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newDoc = nextProps.document;

    if (newDoc && newDoc !== undefined && newDoc.documentkey !== prevState.documentkey) {
      return { document: nextProps.document, imageLoading: true };
    }

    return null;
  }

  componentDidUpdate() {
    const { document, documentkey } = this.state;

    if (document && document !== undefined && documentkey !== document.documentkey) {
      this.setState({ documentkey: document.documentkey }, () => {
        this.fetchImage(document);
      });
    }
  }

  componentDidMount() {
    const { document, defaultPhoto } = this.props;

    if (!document || document === undefined || !document.documentkey || document.documentkey === undefined || !document.identityid) {
      let imageUrl = '';
      if (defaultPhoto === 'person') {
        imageUrl = defaultHeadshot;
      } else {
        imageUrl = defaultImage;
      }

      this.setState({ imageUrl, imageLoading: false });
    } else {
      this.fetchImage(document);
    }
  }

  async fetchImage(document) {
    try {
      const imageUrl = await S3Helper.getObject(document);
      if (imageUrl && imageUrl !== undefined) {
        this.setState({ imageUrl, imageLoading: false });
      } else {
        this.setState({ imageLoading: false });
      }
    } catch (error) {
      this.setState({ imageLoading: false });
    }
  }

  openLightbox = () => {
    this.setState({ lightboxOpen: true });
  };

  closeLightbox = () => {
    this.setState({ lightboxOpen: false });
  };

  updateUrl = () => {
    const { document, defaultPhoto } = this.props;
    const { imageUrl } = this.state;
    let newImageUrl = imageUrl;

    if (!document || document === undefined || !document.documentkey || document.documentkey === undefined || !document.identityid) {
      if (defaultPhoto === 'person') {
        newImageUrl = defaultHeadshot;
      } else {
        newImageUrl = defaultImage;
      }
    }
    return newImageUrl;
  };

  render() {
    const { imageUrl, imageLoading, lightboxOpen } = this.state;
    const renderedImageUrl = this.updateUrl();
    const { disableCarousel } = this.props;
    if (imageLoading) {
      return <CircularProgress className="photo-loader" />;
    }
    if (imageUrl && imageUrl !== undefined) {
      return (
        <React.Fragment>
          <img src={renderedImageUrl} className={!disableCarousel ? 'carousel-photo' : 'document-photo'} alt="" onClick={this.openLightbox} />

          {!disableCarousel && (
            <ModalGateway>
              {lightboxOpen ? (
                <Modal
                  allowFullscreen={false}
                  onClose={this.closeLightbox}
                  styles={{
                    blanket: base => ({
                      ...base,
                      backgroundColor: 'rgba(255,255,255,0.85)',
                    }),
                    dialog: base => ({
                      ...base,
                      maxWidth: 640,
                    }),
                  }}
                >
                  <Carousel
                    currentIndex={0}
                    frameProps={{ autoSize: 'height' }}
                    views={[{ src: imageUrl }]}
                    styles={{
                      footer: base => ({
                        ...base,
                        background: 'none !important',
                        color: '#666',
                        padding: 0,
                        paddingTop: 20,
                        position: 'static',

                        '& a': {
                          color: 'black',
                        },
                      }),
                      header: base => ({
                        ...base,
                        background: 'none !important',
                        padding: 0,
                        paddingBottom: 10,
                        position: 'static',
                      }),
                      headerClose: base => ({
                        ...base,
                        color: '#666',

                        ':hover': { color: '#DE350B' },
                      }),
                      view: base => ({
                        ...base,
                        maxHeight: 480,
                        overflow: 'hidden',
                      }),
                    }}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          )}
        </React.Fragment>
      );
    }

    return null;
  }
}

DocumentPhoto.propTypes = {
  document: PropTypes.any,
  defaultPhoto: PropTypes.oneOf(['person', 'generic']),
  disableCarousel: PropTypes.bool,
};
