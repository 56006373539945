import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import InterestAutocomplete from '../Filters/InterestAutocomplete';
import * as SchemaConstants from '../../../services/schemaConstants';

export default class Dietary extends Component {
  render() {
    const { personKey } = this.props;
    return (
      <Paper className="paper-base">
        <div className="row section">
          <h5 className="paper-header">Member Dietary</h5>
        </div>
        <div className="tagform-container">
          <InterestAutocomplete personKey={personKey} filtertype={SchemaConstants.MEMBER_FILTER_DIETARY_ALLERGY} label="Allergies" placeholder="Select multiple dietary allergies..." />
          <InterestAutocomplete personKey={personKey} filtertype={SchemaConstants.MEMBER_FILTER_DIETARY_PREFERENCE} label="Preferences" placeholder="Select multiple dietary preferences..." />
          <InterestAutocomplete personKey={personKey} filtertype={SchemaConstants.MEMBER_FILTER_DIETARY_RESTRICTION} label="Restrictions" placeholder="Select multiple dietary restrictions..." />
        </div>
      </Paper>
    );
  }
}

Dietary.propTypes = {
  personKey: PropTypes.number.isRequired,
};
