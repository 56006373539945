// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';

export const GetMeasurements = gql`
  query GetMeasurements($personkey: Int!) {
    getMeasurements(personkey: $personkey) {
      measurementskey
      personkey
      height
      weight
      shirtsize
      necksize
      sleevelength
      shoesize
      fitdetails
      pantsize
    }
  }
`;
