import gql from 'graphql-tag';

const GetDocuments = gql`
  query($entitykey: ID!, $entitytype: String!) {
    getDocuments(entitykey: $entitykey, entitytype: $entitytype) {
      documentkey
      name
      displayname
      accesslevel
      identityid
      eventkey
      personkey
      createddate
    }
  }
`;

const GetEntityImages = gql`
  query($entitykey: Int!, $entitytype: String!) {
    getEntityImages(entitykey: $entitykey, entitytype: $entitytype) {
      documentkey
      name
      displayname
      identityid
      accesslevel
      entitykey
      entitytype
      personkey
      createddate
    }
  }
`;

export const DocumentQueries = {
  GetDocuments,
  GetEntityImages,
};
