export const NOTIFICATION_TYPE_CONVERSATION = 'Conversation';
export const NOTIFICATION_TYPE_TWILIO_VOICEMAIL = 'Twilio.Voicemail';
export const NOTIFICATION_TYPE_ADMIN_EVENT = 'Admin.Event';
export const NOTIFICATION_TYPE_ADMIN_NEWS = 'Admin.News';
export const NOTIFICATION_TYPE_ADMIN_SUPPORT = 'Admin.Support';
export const NOTIFICATION_TYPE_ADMIN_VCARD_REQUEST = 'Admin.vCard.Request';
export const NOTIFICATION_TYPE_ADMIN_VCARD_APPROVAL = 'Admin.vCard.Approval';
export const NOTIFICATION_TYPE_ADMIN_VCARD_DENIAL = 'Admin.vCard.Denial';

export const USER_ROLE_MEMBER = 'Member';
export const USER_ROLE_SUPER_ADMIN = 'Super Admin';
export const USER_ROLE_ADMIN = 'Admin';

export const POLL_TYPE_SINGLE = 'Single';
export const POLL_TYPE_MULTIPLE_SINGLE = 'Multiple.Single';
export const POLL_TYPE_MULTIPLE_MULTIPLE = 'Multiple.Multiple';

export const MEMBER_FILTER_INTEREST_PERSONAL = 'MemberFilter.PersonalInterest';
export const MEMBER_FILTER_INTEREST_FINANCIAL = 'MemberFilter.FinancialInterest';
export const MEMBER_FILTER_INTEREST_PHILANTHROPY = 'MemberFilter.PhilanthropyInterest';
export const MEMBER_FILTER_EVENT_ATTENDING = 'MemberFilter.AttendingEvent';
export const MEMBER_FILTER_META_NAME = 'MemberFilter.Meta.Name';
export const MEMBER_FILTER_DIETARY_ALLERGY = 'MemberFilter.DietaryAllergy';
export const MEMBER_FILTER_DIETARY_PREFERENCE = 'MemberFilter.DietaryPreference';
export const MEMBER_FILTER_DIETARY_RESTRICTION = 'MemberFilter.DietaryRestriction';

export const GLOBAL_SEARCH_TYPE_MEMBER = 'Member';
export const GLOBAL_SEARCH_TYPE_EVENT = 'Event';
export const GLOBAL_SEARCH_TYPE_NEWS = 'News';
export const GLOBAL_SEARCH_TYPE_POLL = 'Poll';
export const GLOBAL_SEARCH_TYPE_NOTE = 'Note';
export const GLOBAL_SEARCH_TYPE_ASSET = 'Asset';

const CONVERSATION_TYPE_DEFAULT = 'Conversation.Default';
const CONVERSATION_TYPE_SUPPORT = 'Conversation.Support';
const CONVERSATION_TYPE_BROADCAST = 'Conversation.Broadcast';

export const ConversationTypes = {
  CONVERSATION_TYPE_DEFAULT,
  CONVERSATION_TYPE_SUPPORT,
  CONVERSATION_TYPE_BROADCAST,
};
