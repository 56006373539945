/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { TextField, Button, MenuItem } from '@material-ui/core';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { PersonMutations } from '../../../mutations/Person';
import { PersonQueries } from '../../../queries/Person';
import { countryToCCA } from '../../../helpers/countryHelper';
import LogHelper from '../../../helpers/loggingHelper';
import { LoggingConstants } from '../../../constants/loggingConstants';

class AddMemberForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isValidated: 'pending',
      personStatuses: {},
      newPerson: {
        firstname: '',
        lastname: '',
        company: '',
        city: '',
        country: '',
        industry: '',
        status: '',
      },
    };
  }

  componentDidMount() {
    this.getPersonStatuses();
  }

  getPersonStatuses = async () => {
    const { client } = this.props;
    try {
      const result = await client.query({
        query: PersonQueries.GetPersonStatuses,
      });
      const { getPersonStatuses } = result.data;
      const personStatuses = {};
      getPersonStatuses.forEach(item => {
        personStatuses[item.title] = item.statuskey;
      });
      this.setState({ personStatuses });
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  handleSave = async isRerouted => {
    await this.validatePerson();
    const { isValidated, newPerson } = this.state;
    const { toggleOverlay, refreshMembers } = this.props;
    if (isValidated) {
      const data = await this.createPerson(newPerson);
      if (data && data !== undefined && data.createPerson && data.createPerson !== undefined) {
        const { personkey } = data.createPerson;
        const { history } = this.props;
        const clearedForm = {
          firstname: '',
          lastname: '',
          company: '',
          city: '',
          country: '',
          industry: '',
          status: '',
        };
        refreshMembers();
        if (!isRerouted) {
          this.setState({ newPerson: clearedForm });
        } else {
          history.push(`/`);
          history.push(`/memberdetails/${personkey}`);
          toggleOverlay();
        }
        this.setState({ isValidated: 'pending' });
        this.showSuccess('Member Saved');
      } else {
        this.showError('Error Processing Request');
      }
    } else {
      this.showError('Error Saving: Fields Required');
    }
  };

  validatePerson = () => {
    const { firstname, lastname, status, city, country, company, industry } = this.state.newPerson;
    if (firstname === '' || lastname === '' || status === '' || city === '' || country === '' || industry === '' || company === '') {
      this.setState({ isValidated: false });
    } else {
      this.setState({ isValidated: true });
    }
  };

  createPerson = async newPerson => {
    const { client } = this.props;
    try {
      const result = await client.mutate({
        mutation: PersonMutations.CreatePerson,
        variables: { input: newPerson },
      });
      if (result && result !== undefined) {
        const { data } = result;
        LogHelper.logEvent(`Success creating new person - ${newPerson.firstname} ${newPerson.lastname}`, LoggingConstants.AREA.PERSON);
        return data;
      }
    } catch (error) {
      LogHelper.logError(`Error creating new person - ${newPerson.firstname} ${newPerson.lastname}: ${error}`, LoggingConstants.AREA.PERSON);
    }
  };

  handleSelect = event => {
    const { value } = event.target;
    const { newPerson } = this.state;
    const updatedPerson = { ...newPerson };
    updatedPerson.status = value;
    this.setState({ newPerson: updatedPerson });
  };

  handleChange = event => {
    const { newPerson } = this.state;
    const { name, value } = event.target;
    const updatedPerson = { ...newPerson };
    updatedPerson[name] = value;
    this.setState({ newPerson: updatedPerson });
  };

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;

    enqueueSnackbar(message, { variant: 'success' });
  };

  render() {
    const { firstname, lastname, company, city, country, industry, status } = this.state.newPerson;
    const { isValidated, personStatuses } = this.state;
    const { toggleOverlay, isModal } = this.props;
    return (
      <div className="create-member-container">
        <div className="input-row">
          <div className="input-field">
            <TextField name="firstname" label="First Name" value={firstname} onChange={this.handleChange} className="flex-1 mb-1" error={!isValidated && !firstname} required />
          </div>
          <div className="input-field">
            <TextField name="lastname" label="Last Name" value={lastname} onChange={this.handleChange} className="flex-1 mb-1" error={!isValidated && !lastname} required />
          </div>
        </div>
        <div className="input-row">
          <div className="input-field">
            <TextField name="company" label="Company" value={company} onChange={this.handleChange} className="flex-1 mb-1" required error={!isValidated && !company} />
          </div>
          <div className="input-field">
            <TextField name="industry" label="Industry" value={industry} onChange={this.handleChange} className="flex-1 mb-1" required error={!isValidated && !industry} />
          </div>
        </div>
        <div className="input-row">
          <div className="input-field">
            <TextField name="city" label="Primary City" value={city} onChange={this.handleChange} className="flex-1 mb-1" error={!isValidated && !city} required />
          </div>
          <div className="input-field">
            <TextField
              name="country"
              label="Country"
              className="flex-1 mb-1"
              value={country || ''}
              onChange={this.handleChange}
              select
              required
              error={!isValidated && !country}
              helperText={!isValidated && !country}
            >
              {Object.keys(countryToCCA).map((item, index) => (
                <MenuItem value={item || ''} key={`${item}-${index}`}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
        <div className="input-row">
          <div className="input-field">
            <TextField id="status" className="status-select member-input" name="status" label="Status" onChange={this.handleChange} select required value={status} error={!isValidated && !status}>
              {Object.keys(personStatuses).map((personStatus, statusIndex) => (
                <MenuItem key={statusIndex} value={personStatus}>
                  {personStatus}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
        <div className="row mt-2 justify-end">
          {isModal && toggleOverlay ? (
            <div className="cancel-button">
              {
                <Button variant="outlined" color="primary" onClick={() => toggleOverlay()}>
                  Cancel
                </Button>
              }
            </div>
          ) : null}
          <div className="form-button">
            <Button variant="outlined" color="primary" onClick={() => this.handleSave(false)}>
              Save & New
            </Button>
          </div>
          <div className="form-button">
            <Button variant="contained" color="primary" onClick={() => this.handleSave(true)}>
              Save & View
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withSnackbar(withRouter(withApollo(AddMemberForm)));

AddMemberForm.propTypes = {
  client: PropTypes.object.isRequired,
  refreshMembers: PropTypes.func,
  toggleOverlay: PropTypes.func,
  isModal: PropTypes.bool,
  history: PropTypes.object,
  enqueueSnackbar: PropTypes.func.isRequired,
};
