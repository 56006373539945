import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

export default class MaterialDatePicker extends Component {
  render() {
    const { onChangeFunc, value, label, required, error, helperText, fieldname } = this.props;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          label={label}
          value={value}
          onChange={date => onChangeFunc(date, fieldname)}
          className="flex-1"
          format="MMMM d, yyyy"
          required={required === true}
          error={error === true}
          helperText={helperText && helperText !== undefined ? helperText : null}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

MaterialDatePicker.propTypes = {
  onChangeFunc: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.any,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  fieldname: PropTypes.string,
};
