import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import { withApollo } from 'react-apollo';
import Subheader from '../../components/Subheader/Subheader';
import ConversationsProps from '../../components/Messages/ConversationsProps';
import ConversationProps from '../../components/Messages/ConversationProps';
import ConversationItems from '../../components/Messages/ConversationItems';
import MessageItems from '../../components/Messages/MessageItems';
import MessageSender from '../../components/Messages/MessageSender';
import AuthHelper from '../../services/AuthHelper';
import { ConversationTypes } from '../../services/schemaConstants';

class SupportMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchContains: undefined,
      conversationKey: 0,
      sub: '',
      refreshConversation: false,
    };
  }

  async componentDidMount() {
    const { match } = this.props;
    const externalid = match.params.conversationkey;
    const sub = await AuthHelper.getUserSub();

    if (externalid) {
      const conversationKey = parseInt(externalid);
      this.setState({ conversationKey, sub });
    } else {
      this.setState({ sub });
    }
  }

  getConversationHandler = item => {
    this.setState({
      conversationKey: item.conversationkey,
    });
  };

  searchNotificationHandler = event => {
    const searchTerm = event.target.value;
    this.setState({ searchContains: searchTerm });
  };

  createConversationHandler = () => {
    this.setState({
      conversationKey: 0,
    });
  };

  messageSentHandler = () => {
    this.setState(prevState => ({
      refreshConversation: !prevState.refreshConversation,
    }));
  };

  render() {
    const { sub, searchContains, conversationKey, refreshConversation } = this.state;
    return (
      <div className="master-container">
        <div className="master-content">
          <Subheader title="Support Messages" />
          <div className="supportmessages-container two-col-container">
            <div className="column col-one">
              <Paper className="paper-base">
                <ConversationsProps
                  sub={sub}
                  contains={searchContains}
                  conversationType={ConversationTypes.CONVERSATION_TYPE_SUPPORT}
                  children={ConversationItems}
                  getConversation={this.getConversationHandler}
                  refresh={refreshConversation}
                />
              </Paper>
            </div>
            <div className="column col-two">
              <Paper className="conversation-container paper-base">
                <ConversationProps conversationKey={conversationKey} children={MessageItems} refresh={refreshConversation} />
              </Paper>
              <MessageSender sub={sub} conversationKey={conversationKey} onMessageSent={this.messageSentHandler} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SupportMessages.propTypes = {
  match: PropTypes.any,
};

export default withApollo(SupportMessages);
