import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { Select, MenuItem, FormControl, InputLabel, Chip, Button } from '@material-ui/core';
import { PersonQueries } from '../../queries/Person';
import AuthHelper from '../../services/AuthHelper';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class MembersSelectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      selected: [],
      checkedAll: false,
    };
  }

  async componentDidMount() {
    await this.fetchMembers();
  }

  componentDidUpdate(prevProps) {
    const { clear } = this.props;
    if (clear !== prevProps.clear) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selected: [], checkedAll: false });
    }
  }

  async fetchMembers() {
    const { client } = this.props;
    const sub = await AuthHelper.getUserSub();
    const members = await client.query({
      query: PersonQueries.GetMembersToMessage,
      variables: { sub },
    });

    this.setState({
      members: members.data.getMembersToMessage,
    });
  }

  handleChange = event => {
    const { onSelected } = this.props;
    const { value } = event.target;
    this.setState({ selected: value }, () => {
      const { selected } = this.state;
      onSelected(selected);
    });
  };

  selectAll = () => {
    const { onSelected } = this.props;
    const { members, checkedAll } = this.state;
    if (checkedAll) {
      this.setState({ selected: [], checkedAll: false }, () => {
        const { selected } = this.state;
        onSelected(selected);
      });
    } else {
      this.setState({ selected: members, checkedAll: true }, () => {
        const { selected } = this.state;
        onSelected(selected);
      });
    }
  };

  renderMenuItems = members =>
    members.map(item => (
      <MenuItem key={item.personkey} value={item}>
        {`${item.firstname} ${item.lastname}`}
      </MenuItem>
    ));

  renderChips = selectedMembers => (
    <div className="chips">
      {selectedMembers.map(member => (
        <Chip key={member.personkey} label={`${member.firstname} ${member.lastname}`} className="chip" />
      ))}
    </div>
  );

  render() {
    const { placeholder } = this.props;
    const { selected, members, checkedAll } = this.state;
    return (
      <div className="form-card member-selectlist-container">
        <FormControl className="form-control">
          <InputLabel htmlFor="add-member" className="input-label">
            {placeholder}
          </InputLabel>
          <Select
            inputProps={{
              id: 'add-member',
            }}
            multiple
            value={selected}
            onChange={this.handleChange}
            renderValue={selectedMembers => this.renderChips(selectedMembers)}
            MenuProps={MenuProps}
          >
            {this.renderMenuItems(members)}
          </Select>
          <div className="select-all-btn-container">
            <Button variant="contained" color="secondary" onClick={this.selectAll}>
              {checkedAll ? 'Select None' : 'Select All'}
            </Button>
          </div>
        </FormControl>
      </div>
    );
  }
}
MembersSelectList.propTypes = {
  client: PropTypes.object,
  placeholder: PropTypes.string,
  onSelected: PropTypes.func.isRequired,
  clear: PropTypes.bool.isRequired,
};

export default withApollo(MembersSelectList);
