import gql from 'graphql-tag';

const DeleteDocument = gql`
  mutation DeleteDocument($documentkey: ID!) {
    deleteDocument(documentkey: $documentkey) {
      wassuccessful
      error
    }
  }
`;

const UploadDocument = gql`
  mutation UploadDocument($entitykey: ID!, $entitytype: String!, $name: String!, $displayname: String, $accesslevel: String!, $sub: String!, $isbanner: Boolean) {
    uploadDocument(entitykey: $entitykey, entitytype: $entitytype, name: $name, displayname: $displayname, accesslevel: $accesslevel, sub: $sub, isbanner: $isbanner) {
      wassuccessful
      error
    }
  }
`;

const UploadEntityImage = gql`
  mutation DocumentMutation($entitykey: Int!, $entitytype: String!, $userkey: Int!, $name: String!) {
    uploadEntityImage(entitykey: $entitykey, entitytype: $entitytype, userkey: $userkey, name: $name)
  }
`;

const SaveProfilePicture = gql`
  mutation SaveProfilePicture($personkey: Int!, $name: String!, $accesslevel: String!, $sub: String!) {
    saveProfilePicture(personkey: $personkey, name: $name, accesslevel: $accesslevel, sub: $sub) {
      profilepicture {
        documentkey
        name
        personkey
        accesslevel
        identityid
      }
      wassuccessful
      error
    }
  }
`;

export const DocumentMutations = {
  DeleteDocument,
  SaveProfilePicture,
  UploadDocument,
  UploadEntityImage,
};
