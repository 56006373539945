import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { TextField, Button } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import logo from '../../assets/images/R10_Logo.svg';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ loading: true });
    const { username, password } = this.state;
    const { userHasAuthenticated, history } = this.props;

    try {
      const user = await Auth.signIn(username, password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(user, password);
      } else {
        userHasAuthenticated(true);
        history.push('/dashboard');
      }
    } catch (err) {
      this.setState({ loading: false, password: '' });

      if (err.code === 'PasswordResetRequiredException') {
        // The error happens when the password is reset in the Cognito console
      } else if (err.code === 'NotAuthorizedException') {
        // The error happens when the incorrect password is provided
        this.showError('Incorrect Password');
      } else if (err.code === 'UserNotFoundException') {
        this.showError('Username not found');
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        console.log(err.code);
        this.showError('Username or Password Not Found');
      }
    }
  };

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  render() {
    const { username, password, loading } = this.state;

    return (
      <div className="signin-container">
        <img className="login-img" src={logo} alt="logo" />
        <h2 className="login-directions">Please Log In To Your Account</h2>
        <form className="signin-form" label="Sign in to your account">
          <div className="login-input">
            <TextField name="username" label="Username" onChange={this.handleChange} value={username} autoComplete="off" required />
          </div>
          <div className="login-input">
            <TextField name="password" type="password" label="Password" onChange={this.handleChange} value={password} autoComplete="off" required />
          </div>
          <div className="row justify-center">
            <Button variant="contained" color="primary" text="Login" onClick={this.handleSubmit} disabled={loading}>
              Login
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withSnackbar(withRouter(Login));

Login.propTypes = {
  userHasAuthenticated: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  history: PropTypes.object,
};
