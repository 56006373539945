import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { withApollo } from 'react-apollo';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, IconButton, List, ListItem, Menu, MenuItem, Dialog, DialogContent, DialogTitle, SwipeableDrawer } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import logo from '../../assets/images/R10_Logo.svg';
import plus from '../../assets/images/new_plus_icon.svg';
import userIcon from '../../assets/images/user_icon.svg';
import AddMemberForm from '../Dashboard/AddMemberForm/AddMemberForm.js';
import AddEventForm from '../Dashboard/AddEventForm/AddEventForm';
import AdminAutocomplete from '../MemberDetails/Filters/AdminAutocomplete';
import Subheader from '../Subheader/Subheader';

function Header(props) {
  const classes = useStyles();
  const [anchorLogout, setAnchorLogout] = React.useState(null);
  const [anchorAdd, setAnchorAdd] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState(null);
  const [isDrawerOpen, setisDrawerOpen] = React.useState(false);
  const openLogout = !!anchorLogout;
  const openAdd = !!anchorAdd;
  const { history, client, refreshMembers, refreshEvents } = props;
  const { pathname } = history.location;

  const navigateTo = path => {
    handleClose('Add');
    history.push(path);
  };

  const handleOpenAdd = event => {
    setAnchorAdd(event.currentTarget);
  };

  const handleOpenLogout = event => {
    setAnchorLogout(event.currentTarget);
  };

  const handleLogout = () => {
    Auth.signOut();
    props.userHasAuthenticated(false);
    handleClose('Logout');
    history.push('/');
  };

  const handleClose = type => {
    console.log('CLOSE');
    if (type === 'Add') {
      setAnchorAdd(null);
    } else if (type === 'Logout') {
      setAnchorLogout(null);
    }
  };

  const toggleModal = type => {
    const modalStatus = !modalOpen;
    if (type === 'Member') {
      handleClose('Add');
      setModalType('Member');
    }
    if (type === 'Event') {
      handleClose('Add');
      setModalType('Event');
    }
    setModalOpen(modalStatus);
  };

  const toggleDrawer = () => {
    console.log('IS OPEN', isDrawerOpen);
    setisDrawerOpen(!isDrawerOpen);
  };

  const linksList = (
    <React.Fragment>
      <List className={classes.linksContainer}>
        <ListItem className={classes.linksContainer}>
          <Link className={pathname.includes('member') ? classes.isCurrent : classes.href} to="/members">
            Members
          </Link>
        </ListItem>
        <ListItem>
          <Link className={pathname.includes('events') ? classes.isCurrent : classes.href} to="/events">
            Events
          </Link>
        </ListItem>
        <ListItem>
          <Link className={pathname.includes('news') ? classes.isCurrent : classes.href} to="/news">
            News
          </Link>
        </ListItem>
        <ListItem>
          <Link className={pathname.includes('supportmessages') ? classes.isCurrent : classes.href} to="/supportmessages">
            Support Messages
          </Link>
        </ListItem>
        <ListItem>
          <Link className={pathname.includes('broadcastmessages') ? classes.isCurrent : classes.href} to="/broadcastmessages">
            Broadcast Messages
          </Link>
        </ListItem>
        <ListItem>
          <Link className={pathname.includes('polling') ? classes.isCurrent : classes.href} to="/polling">
            Polling
          </Link>
        </ListItem>
        <ListItem>
          <Link className={pathname.includes('reports') ? classes.isCurrent : classes.href} to="/reports">
            Reports
          </Link>
        </ListItem>
      </List>
    </React.Fragment>
  );

  const eventForm = (
    <DialogContent>
      <AddEventForm client={client} isModal toggleOverlay={toggleModal} refreshEvents={refreshEvents} />
    </DialogContent>
  );

  const memberForm = (
    <DialogContent>
      <AddMemberForm client={client} isModal toggleOverlay={toggleModal} refreshMembers={refreshMembers} />
    </DialogContent>
  );

  return (
    <div>
      <div className="header">
        <AppBar className={classes.container}>
          <Toolbar position="static" className={classes.toolbar}>
            <div>
              {useMediaQuery('(max-width:1080px)') && (
                <IconButton onClick={toggleDrawer}>
                  <MenuIcon />
                </IconButton>
              )}
              <IconButton>
                <Link to="/members" className="header-logo">
                  <img className="header-img" src={logo} alt="logo" />
                </Link>
              </IconButton>
            </div>
            {useMediaQuery('(min-width:1081px)') && linksList}
            <div className={classes.endBlock}>
              <AdminAutocomplete label="Global Search" navigateTo={navigateTo} />
              <IconButton aria-label="Add member/event" aria-controls="menu-add" aria-haspopup="true" onClick={handleOpenAdd} color="inherit">
                <img src={plus} alt="dropdown icon" className={classes.headerImage} />
              </IconButton>
              <IconButton className={classes.dropDown} aria-label="Logout" aria-controls="menu-logout" aria-haspopup="true" onClick={handleOpenLogout}>
                <img src={userIcon} alt="User-Icon" className={classes.headerImage} />
              </IconButton>
              <Menu
                id="menu-add"
                anchorEl={anchorAdd}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                className={classes.dropDown}
                open={openAdd}
                onClose={() => handleClose('Add')}
              >
                <MenuItem onClick={() => toggleModal('Member')}>Add Member</MenuItem>
                <MenuItem onClick={() => toggleModal('Event')}>Add Event</MenuItem>
                <MenuItem onClick={() => navigateTo('/news')}>Add News</MenuItem>
              </Menu>
              <Menu
                id="menu-logout"
                anchorEl={anchorLogout}
                className={classes.dropDown}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={openLogout}
                onClose={() => handleClose('Logout')}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
            <Dialog open={modalOpen} onClose={toggleModal} className={classes.paper} disableBackdropClick>
              <DialogTitle>
                <p>{modalType === 'Member' ? 'Add New Member' : 'Add New Event'}</p>
              </DialogTitle>
              {modalType === 'Member' ? memberForm : eventForm}
            </Dialog>
            <SwipeableDrawer className={classes.hamburger} anchor="top" open={isDrawerOpen} onClose={toggleDrawer} onOpen={toggleDrawer} onClick={toggleDrawer}>
              {linksList}
            </SwipeableDrawer>
          </Toolbar>
          <Subheader displayBlock />
        </AppBar>
      </div>
    </div>
  );
}

export default withApollo(withRouter(Header));

Header.propTypes = {
  history: PropTypes.object.isRequired,
  refreshMembers: PropTypes.func,
  refreshEvents: PropTypes.func,
  client: PropTypes.object,
  userHasAuthenticated: PropTypes.any,
};

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#f8f8f8',
    height: 80,
    zIndex: 9999,
  },
  dropDown: {
    zIndex: '10000 !important',
  },
  hamburger: {
    zIndex: '10000 !important',
  },
  toolbar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  endBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  header: {
    marginBottom: 50,
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  href: {
    color: '#3d3d3d',
    fontSize: 30,
    textDecoration: 'none',
  },
  isCurrent: {
    color: '#3d3d3d',
    fontSize: 30,
    textDecoration: 'none',
    paddingBottom: 3,
    borderBottomWidth: 4,
    borderBottomColor: '#0033a1',
    borderBottomStyle: 'solid',
  },
  disabled: {
    color: '#707070',
    fontSize: 30,
    textDecoration: 'none',
  },
  headerImage: {
    width: 25,
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    outline: 'none',
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    border: '2px solid #707070',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    color: '#3d3d3d',
  },
  searchIcon: {
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#3d3d3d',
    paddingLeft: 5,
  },
  inputRoot: {
    color: '#3d3d3d',
    margin: 'dense',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    color: '#3d3d3d',
  },
}));
