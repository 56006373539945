const local = {
  admin: {
    graphql_endpoint: 'http://localhost:4000/admingraphql',
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:c32cb9b0-bfae-4e24-b4a5-caa71d540d65',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_l10XoYp4J',
    aws_user_pools_web_client_id: '16dp0al55r2lrrs60lu8c5kdr8',
    pinpoint_app_id: '11405044831149689d7b68f3f542dddd',
  },
  mobile: {
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:8ae4c046-579f-4545-8263-b75ff1b4361d',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_hFzt0pJOn',
    aws_user_pools_web_client_id: '130daj3fpi0u8t57eg6t89komt',
    aws_storage_bucket: 'r10mobile-us-west-2-002279738858',
  },
};

const development = {
  admin: {
    graphql_endpoint: 'https://t7vvgyts4a.execute-api.us-west-2.amazonaws.com/development/admingraphql',
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:c32cb9b0-bfae-4e24-b4a5-caa71d540d65',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_l10XoYp4J',
    aws_user_pools_web_client_id: '16dp0al55r2lrrs60lu8c5kdr8',
    pinpoint_app_id: '11405044831149689d7b68f3f542dddd',
  },
  mobile: {
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:8ae4c046-579f-4545-8263-b75ff1b4361d',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_hFzt0pJOn',
    aws_user_pools_web_client_id: '130daj3fpi0u8t57eg6t89komt',
    aws_storage_bucket: 'r10mobile-us-west-2-002279738858-testing',
  },
};

const testing = {
  admin: {
    graphql_endpoint: 'https://yax1pisodh.execute-api.us-west-2.amazonaws.com/testing/admingraphql',
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:c32cb9b0-bfae-4e24-b4a5-caa71d540d65',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_l10XoYp4J',
    aws_user_pools_web_client_id: '16dp0al55r2lrrs60lu8c5kdr8',
    pinpoint_app_id: 'cade59c0d346471f8ff75e5c64e6bfab',
  },
  mobile: {
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:8ae4c046-579f-4545-8263-b75ff1b4361d',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_hFzt0pJOn',
    aws_user_pools_web_client_id: '130daj3fpi0u8t57eg6t89komt',
    aws_storage_bucket: 'r10mobile-us-west-2-002279738858',
  },
};

const staging = {
  admin: {
    graphql_endpoint: 'https://19qms6ex16.execute-api.us-west-2.amazonaws.com/staging/admingraphql',
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:2f391888-07fb-4e94-a25e-834b89d3e377',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_kkH66ag9y',
    aws_user_pools_web_client_id: '4pq7l5lb207ivcunonidsij316',
    pinpoint_app_id: '9790388ef139430f8f9fcbbd5f1f778a',
  },
  mobile: {
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:caf59294-0093-4b4f-8f76-eb355aa5edb4',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_U3Ftz52ln',
    aws_user_pools_web_client_id: '2hmgo47o0op3o8apljeb987v8m',
    aws_storage_bucket: 'r10mobile-us-west-2-322432604478',
  },
};

const production = {
  admin: {
    graphql_endpoint: 'https://67lddp6a3b.execute-api.us-west-2.amazonaws.com/production/admingraphql',
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:80961d52-6d7e-48e5-965c-1f1ba09c4cd7',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_Fxq1vhb4q',
    aws_user_pools_web_client_id: '3b9ncqm78tausnk0s9o8umenke',
    pinpoint_app_id: 'e9bcff3a40f24cd39785438bc79e5311',
  },
  mobile: {
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:c29e0bbb-74b0-4442-9e8b-47e48319175d',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_pvJ6f7WJZ',
    aws_user_pools_web_client_id: '690a1sijofip6k4085ohl43ouo',
    aws_storage_bucket: 'r10mobile-us-west-2-896198407773',
  },
};

// Default to development if not set
let config;

if (process.env.REACT_APP_STAGE === 'production') {
  config = production;
} else if (process.env.REACT_APP_STAGE === 'staging') {
  config = staging;
} else if (process.env.REACT_APP_STAGE === 'testing') {
  config = testing;
} else if (process.env.REACT_APP_STAGE === 'development') {
  config = development;
} else {
  config = local;
}

export default {
  ...config,
};
