import { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { FilterQueries } from '../../../queries/Filters';

class AdminFilterProps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { contains } = this.props;
    if (contains !== prevProps.contains && contains.length > 0) {
      this.fetchData();
    }
  }

  async fetchData() {
    const { client, contains } = this.props;
    this.setState({ loading: true });

    const data = await client.query({
      query: FilterQueries.globalSearch,
      variables: { contains },
    });

    this.setState({
      loading: false,
      data: data.data.globalSearch,
    });
  }

  // eslint-disable-next-line react/destructuring-assignment
  render = () => this.props.children({ ...this.state, ...this.props });
}

AdminFilterProps.propTypes = {
  client: PropTypes.object.isRequired,
  contains: PropTypes.string,
  children: PropTypes.any,
};

export default withApollo(AdminFilterProps);
