import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

export default class MaterialKeyboardDatePicker extends Component {
  onError = error => {
    const { fieldname, helperText, handleDateError } = this.props;
    if (helperText && error !== helperText) {
      if (handleDateError) handleDateError(error, fieldname);
    } else if (!helperText && error) {
      if (handleDateError) handleDateError(error, fieldname);
    }
  };

  render() {
    const { onChangeFunc, value, label, required, isError, helperText, fieldname } = this.props;
    const dateValue = value === 'Invalid date' ? null : value;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          label={label}
          value={dateValue}
          onChange={date => onChangeFunc(date, fieldname)}
          format="MM/dd/yyyy"
          required={required === true}
          error={isError === true}
          helperText={isError && helperText}
          onError={error => this.onError(error)}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

MaterialKeyboardDatePicker.propTypes = {
  onChangeFunc: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.any,
  isError: PropTypes.bool,
  helperText: PropTypes.string,
  fieldname: PropTypes.string,
  handleDateError: PropTypes.func,
};
