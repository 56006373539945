import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, Button } from '@material-ui/core';
import { withApollo } from 'react-apollo';
import Subheader from '../../components/Subheader/Subheader';
import ConversationsProps from '../../components/Messages/ConversationsProps';
import ConversationProps from '../../components/Messages/ConversationProps';
import ConversationItems from '../../components/Messages/ConversationItems';
import MessageItems from '../../components/Messages/MessageItems';
import BroadcastSender from '../../components/Messages/BroadcastSender';
import AuthHelper from '../../services/AuthHelper';
import { ConversationTypes } from '../../services/schemaConstants';
import MembersSelectList from '../../components/Messages/MembersSelectList';

class BroadcastMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchContains: undefined,
      conversationKey: 0,
      sub: '',
      refreshConversation: false,
      topersonkeys: [],
      clear: false,
    };
  }

  async componentDidMount() {
    const { match } = this.props;
    const externalid = match.params.conversationkey;
    const sub = await AuthHelper.getUserSub();

    if (externalid) {
      const conversationKey = parseInt(externalid);
      this.setState({ conversationKey, sub });
    } else {
      this.setState({ sub });
    }
  }

  newBroadcastMessage = () => {
    this.setState({ conversationKey: 0, refreshConversation: true });
  };

  getConversationHandler = item => {
    this.setState({
      conversationKey: item.conversationkey,
    });
  };

  searchNotificationHandler = event => {
    const searchTerm = event.target.value;
    this.setState({ searchContains: searchTerm });
  };

  createConversationHandler = () => {
    this.setState({
      conversationKey: 0,
    });
  };

  membersSelectedHandler = members => {
    const broadcastRecipientPersonKeys = members.map(m => m.personkey);
    this.setState({ topersonkeys: broadcastRecipientPersonKeys });
  };

  messageSentHandler = () => {
    this.setState(prevState => ({
      topersonkeys: [],
      refreshConversation: !prevState.refreshConversation,
      clear: !prevState.clear,
    }));
  };

  render() {
    const { sub, searchContains, conversationKey, refreshConversation, topersonkeys, clear } = this.state;
    return (
      <div className="master-container">
        <div className="master-content">
          <Subheader title="Broadcast Messages" />
          <div className="supportmessages-container two-col-container">
            <div className="column col-one">
              <Paper className="paper-base">
                <ConversationsProps
                  sub={sub}
                  contains={searchContains}
                  conversationType={ConversationTypes.CONVERSATION_TYPE_BROADCAST}
                  children={ConversationItems}
                  getConversation={this.getConversationHandler}
                  refresh={refreshConversation}
                  selectedConversationKey={conversationKey}
                />
              </Paper>
            </div>
            <div className="column col-two">
              {conversationKey === 0 ? (
                <Paper className="paper-base">
                  <MembersSelectList placeholder="Select Members" onSelected={this.membersSelectedHandler} clear={clear} />
                </Paper>
              ) : (
                <div>
                  <div className="new-broadcast-btn-container">
                    <Button variant="contained" color="primary" onClick={this.newBroadcastMessage}>
                      New Broadcast Message
                    </Button>
                  </div>
                  <Paper className="conversation-container paper-base">
                    <ConversationProps conversationKey={conversationKey} children={MessageItems} refresh={refreshConversation} />
                  </Paper>
                </div>
              )}
              <BroadcastSender sub={sub} conversationKey={conversationKey} topersonkeys={topersonkeys} onMessageSent={this.messageSentHandler} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BroadcastMessages.propTypes = {
  match: PropTypes.any,
};

export default withApollo(BroadcastMessages);
