import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';

const putItem = async (writeObject, tableName) =>
  new Promise((resolve, reject) => {
    try {
      const params = {
        Item: writeObject,
        TableName: tableName,
      };

      Auth.currentCredentials().then(creds => {
        const putItemPromise = new AWS.DynamoDB({
          apiVersion: '2012-08-10',
          region: 'us-west-2',
          credentials: creds,
        })
          .putItem(params)
          .promise();

        putItemPromise
          .then(function(data) {
            resolve(true);
          })
          .catch(function(err) {
            console.error(err, err.stack);
            throw err;
          });
      });
    } catch (error) {
      console.log('Error creating user: ', error);
      reject(error);
    }
  });

const DynamoHelper = {
  putItem,
};

export default DynamoHelper;
