/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { Paper } from '@material-ui/core';
import Subheader from '../../components/Subheader/Subheader';
import ConversationsProps from '../../components/Messages/ConversationsProps';
import NotificationDashboardItems from '../../components/Messages/NotificationDashboardItems';
import AddEventForm from '../../components/Dashboard/AddEventForm/AddEventForm';
import AddMemberForm from '../../components/Dashboard/AddMemberForm/AddMemberForm';
import UpcomingEvents from '../../components/Dashboard/UpcomingEvents/UpcomingEvents';

export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'member',
    };
  }

  handleTabChange = activeTab => {
    this.setState({ activeTab });
  };

  render() {
    const { activeTab } = this.state;
    return (
      <div className="master-container">
        <div className="master-content">
          <Subheader title="Dashboard" />
          <div className="dashboard-container">
            <Paper className="paper-base dashboard-paper">
              <h4>My Messages</h4>
              <ConversationsProps children={NotificationDashboardItems} />
            </Paper>
            <Paper className="paper-base dashboard-paper-no-right">
              <UpcomingEvents />
            </Paper>
          </div>
          <Paper className="paper-base dashboard-paper feature">
            <div className="new-card-header">
              <h3 className={activeTab === 'member' ? 'active' : 'inactive'} onClick={() => this.handleTabChange('member')}>
                Add New Member
              </h3>
              <h3 className={activeTab === 'event' ? 'active' : 'inactive'} onClick={() => this.handleTabChange('event')}>
                Add New Event
              </h3>
            </div>
            {activeTab === 'member' ? <AddMemberForm /> : <AddEventForm />}
          </Paper>
        </div>
      </div>
    );
  }
}
