import generatePassword from 'password-generator';

const maxLength = 6;
const minLength = 6;
const uppercaseMinCount = 1;
const lowercaseMinCount = 1;
const numberMinCount = 1;
const specialMinCount = 1;
const UPPERCASE_RE = /([A-Z])/g;
const LOWERCASE_RE = /([a-z])/g;
const NUMBER_RE = /([\d])/g;
const SPECIAL_CHAR_RE = /([?-])/g;
const NON_REPEATING_CHAR_RE = /([\w\d?-])\1{2,}/g;

function isStrongEnough(password) {
  const uc = password.match(UPPERCASE_RE);
  const lc = password.match(LOWERCASE_RE);
  const n = password.match(NUMBER_RE);
  const sc = password.match(SPECIAL_CHAR_RE);
  const nr = password.match(NON_REPEATING_CHAR_RE);

  return password.length >= minLength && !nr && uc && uc.length >= uppercaseMinCount && lc && lc.length >= lowercaseMinCount && n && n.length >= numberMinCount && sc && sc.length >= specialMinCount;
}

const createPassword = () => {
  let password = '';
  const randomLength = Math.floor(Math.random() * (maxLength - minLength)) + minLength;

  while (!isStrongEnough(password)) {
    password = generatePassword(randomLength, false, /[\w\d?-]/);
  }

  password = `R${password.replace('[^a-hp-zA-HP-Z0-9!&@#*?]+', '!')}c`;

  return password;
};

const PasswordHelper = {
  createPassword,
};

export default PasswordHelper;
