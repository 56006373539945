import gql from 'graphql-tag';

const getPollOptions = gql`
  query PollOptionQuery($pollkey: Int!) {
    getPollOptions(pollkey: $pollkey) {
      polloptionkey
      pollkey
      text
      order
      createddate
    }
  }
`;

export const PollOptionQueries = {
  getPollOptions,
};
