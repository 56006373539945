import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { IconButton, CircularProgress } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import ReportTable from '../../components/ReportTable/ReportTable';
import ReportFilters from '../../components/ReportFilters/ReportFilters.js';
import Subheader from '../../components/Subheader/Subheader';
import { reportConfigs, conversationDetailsConfig, broadcastDetailsConfig, CSVConfig } from '../../services/ReportConfigs';
import { ReportFilterQueries } from '../../queries/ReportFilters';

export class ReportDetails extends Component {
  constructor(props) {
    super(props);
    this.csvDownload = React.createRef();

    this.state = {
      isLoading: false,
      isLoadingCSV: false,
      config: [],
      data: [],
      csvData: [],
      sender: '',
      receiver: '',
      startDateTime: '',
      endDateTime: '',
      status: '',
      content: '',
      offset: 0,
      sortType: '',
      sortColumn: '',
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value, offset: 0 }, () => {
      const { sender, receiver, startDateTime, endDateTime, status, offset, content, sortColumn, sortType } = this.state;
      this.fetchResults({ sender, receiver, startDateTime, endDateTime, status, offset, content, sortColumn, sortType });
    });
  };

  handleDateChange = (date, fieldname) => {
    this.setState({ [fieldname]: date, offset: 0 }, () => {
      const { sender, receiver, startDateTime, endDateTime, status, offset, content, sortColumn, sortType } = this.state;
      this.fetchResults({ sender, receiver, startDateTime, endDateTime, status, offset, content, sortColumn, sortType });
    });
  };

  clearFilters = () => {
    this.setState(
      {
        sender: '',
        receiver: '',
        startDateTime: '',
        endDateTime: '',
        status: '',
        offset: 0,
        content: '',
        sortType: '',
        sortTitle: '',
        sortColumn: '',
      },
      () => {
        const { sender, receiver, startDateTime, endDateTime, status, offset, content, sortColumn, sortType } = this.state;
        this.fetchResults({ sender, receiver, startDateTime, endDateTime, status, offset, content, sortColumn, sortType });
      }
    );
  };

  componentDidMount() {
    const { match } = this.props;
    const { reportType, messageid, broadcastmessageid } = match.params;
    this.setState({ isLoading: true });
    this.fetchResults();
    if (reportType) {
      this.setState({ config: reportConfigs[reportType], offset: 0 });
    } else if (messageid) {
      this.setState({ config: conversationDetailsConfig });
    } else if (broadcastmessageid) {
      this.setState({ config: broadcastDetailsConfig });
    }
  }

  handlePaginate = direction => {
    const { sender, receiver, startDateTime, endDateTime, status, offset, content, sortColumn, sortType } = this.state;
    if (direction === 'left') {
      const newOffset = offset - 10;
      this.setState({ offset: newOffset, isLoading: true }, () => {
        this.fetchResults({ sender, receiver, startDateTime, endDateTime, status, offset: newOffset, content, sortColumn, sortType });
      });
    } else if (direction === 'right') {
      const newOffset = offset + 10;
      this.setState({ offset: newOffset, isLoading: true }, () => {
        this.fetchResults({ sender, receiver, startDateTime, endDateTime, status, offset: newOffset, content, sortColumn, sortType });
      });
    }
  };

  fetchResults = async (queryTerms = {}) => {
    const { match, client } = this.props;
    const { reportType, messageid, broadcastmessageid } = match.params;

    if (messageid) {
      queryTerms.reportType = 'Conversation Details';
      queryTerms.messageid = parseInt(messageid);
    } else if (broadcastmessageid) {
      queryTerms.reportType = 'Broadcast Details';
      queryTerms.broadcastmessageid = broadcastmessageid;
    } else {
      queryTerms.reportType = reportType;
    }

    queryTerms.limit = 10;

    try {
      const result = await client.query({
        query: ReportFilterQueries.GetFilteredReportMetrics,
        variables: { input: queryTerms },
      });
      if (result && result.data && result.data.getFilteredReportMetrics) {
        const { getFilteredReportMetrics } = result.data;

        this.setState({ data: [...getFilteredReportMetrics], isLoading: false });
      } else {
        throw new Error('Error getting metrics');
      }
    } catch (error) {
      console.log('ERROR querying', error);
      this.setState({ isLoading: false });
    }
  };

  fetchCSVResults = async (queryTerms = {}) => {
    const { match, client } = this.props;
    const { reportType, messageid, broadcastmessageid } = match.params;

    if (messageid) {
      queryTerms.reportType = 'Conversation Details';
      queryTerms.messageid = parseInt(messageid);
    } else if (broadcastmessageid) {
      queryTerms.reportType = 'Broadcast Details';
      queryTerms.broadcastmessageid = broadcastmessageid;
    } else {
      queryTerms.reportType = reportType;
    }

    try {
      const result = await client.query({
        query: ReportFilterQueries.GetFilteredReportMetrics,
        variables: { input: queryTerms },
      });
      if (result && result.data && result.data.getFilteredReportMetrics) {
        const { getFilteredReportMetrics } = result.data;

        const csvData = CSVConfig(getFilteredReportMetrics, queryTerms.reportType);
        this.setState({ csvData: [...csvData], isLoadingCSV: false });
      } else {
        throw new Error('Error getting metrics');
      }
    } catch (error) {
      console.log('ERROR querying', error);
      this.setState({ isLoading: false });
    }
  };

  handleCSVDownload = async () => {
    const { sender, receiver, status, startDateTime, endDateTime, content } = this.state;
    this.setState({ isLoadingCSV: true });
    await this.fetchCSVResults({ sender, receiver, status, startDateTime, endDateTime, content });
    this.csvDownload.current.link.click();
  };

  handleSort = async column => {
    if (column.title === 'Row') {
      return;
    }
    const { sortType, sortTitle } = this.state;
    let newSortType = '';
    if (sortType === 'ASC' && column.title === sortTitle) {
      newSortType = 'DESC';
    } else {
      newSortType = 'ASC';
    }

    this.setState({ offset: 0, sortType: newSortType, sortTitle: column.title, sortColumn: column.field }, () => {
      const { sender, receiver, startDateTime, endDateTime, status, offset, content } = this.state;
      this.fetchResults({ sender, receiver, startDateTime, endDateTime, status, offset, content, sortColumn: column.field, sortType: newSortType });
    });
  };

  render() {
    const { match } = this.props;
    let { reportType, messageid, broadcastmessageid } = match.params;
    if (messageid) {
      reportType = 'Conversation Details';
    } else if (broadcastmessageid) {
      reportType = 'Broadcast Details';
    }
    const { config, data, csvData, offset, sender, receiver, status, startDateTime, endDateTime, content, isLoading, isLoadingCSV, sortType, sortTitle } = this.state;
    return (
      <div className="master-container">
        <div className="master-content">
          <Subheader title={reportType || 'Conversation Details'} />
          <div className={`report-table-container ${config.length < 5 && reportType !== 'Broadcast Messages' ? 'table-small' : 'table-wide'}`}>
            <div className="report-filters">
              <ReportFilters
                reportType={reportType}
                handleCSVDownload={this.handleCSVDownload}
                data={csvData}
                isLoadingCSV={isLoadingCSV}
                handleChange={this.handleChange}
                handleDateChange={this.handleDateChange}
                status={status}
                sender={sender}
                receiver={receiver}
                startDateTime={startDateTime}
                endDateTime={endDateTime}
                clearFilters={this.clearFilters}
                csvDownload={this.csvDownload}
                content={content}
              />
            </div>
            <ReportTable config={config} data={data} handleSort={this.handleSort} sortType={sortType} sortTitle={sortTitle} />
            {isLoading ? (
              <div className="report spinner">
                <CircularProgress />
              </div>
            ) : (
              <div>
                <IconButton color="primary" disabled={offset < 10} onClick={() => this.handlePaginate('left')}>
                  <KeyboardArrowLeft />
                </IconButton>
                <IconButton color="primary" disabled={data.length < 10} onClick={() => this.handlePaginate('right')}>
                  <KeyboardArrowRight />
                </IconButton>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(ReportDetails);

ReportDetails.propTypes = {
  match: PropTypes.object,
  client: PropTypes.object,
};
