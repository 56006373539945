import React, { Component } from 'react';
import Launch from '@material-ui/icons/Launch';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { withSnackbar } from 'notistack';
import { AssetQueries } from '../../../queries/Assets';
import { AssetMutations } from '../../../mutations/Assets';

export class Assets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assetTypes: {},
      assets: [],
    };
  }

  componentDidMount() {
    this.getAssetTypes();
    this.fetchAssets();
  }

  async fetchAssets() {
    const { client, personkey } = this.props;

    try {
      const assetList = await client.query({
        query: AssetQueries.GetMyAssets,
        variables: { personkey },
      });

      if (assetList && assetList.data && assetList.data.getMyAssets) {
        const { getMyAssets } = assetList.data;
        this.setState({ assets: getMyAssets });
      }
    } catch (error) {
      this.showError(error);
    }
  }

  getAssetTypes = async () => {
    const { client } = this.props;

    try {
      const result = await client.query({
        query: AssetQueries.GetAssetTypes,
      });

      const { getAssetTypes } = result.data;
      const assetTypes = {};

      getAssetTypes.forEach(item => {
        assetTypes[item.title] = item.assettypekey;
      });

      this.setState({ assetTypes });
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  addRow = async newData => {
    const { assetTypes, assets } = this.state;
    const { client, personkey } = this.props;
    const { location, title, name } = newData;

    try {
      const result = await client.mutate({
        mutation: AssetMutations.CreateAssets,
        variables: {
          assettypekey: assetTypes[title],
          location,
          title,
          personkey,
          isactive: true,
          name,
        },
      });

      if (result && result !== undefined) {
        const { assetkey } = result.data.createAssets;

        const newAsset = { location, title, personkey, assetkey, name };
        this.setState({ assets: [...assets, newAsset] });
      } else {
        this.showError('Error Saving New Asset');
      }
    } catch (error) {
      console.log(error);
      this.showError('Error Saving Asset');
    }
  };

  deleteRow = async oldData => {
    const { client } = this.props;
    const { assetkey } = oldData;
    const { assets } = this.state;

    try {
      await client
        .mutate({
          mutation: AssetMutations.DeleteAssetById,
          variables: { assetkey },
        })
        .then(res => {
          const newAssets = [...assets];
          newAssets.splice(newAssets.indexOf(oldData), 1);
          this.setState({ assets: [...newAssets] });
        });
    } catch (error) {
      this.showError('Error deleting asset');
    }
  };

  viewAsset = assetkey => {
    const { history } = this.props;

    history.push({
      pathname: `/assets/${assetkey}`,
    });
  };

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'success' });
  };

  render() {
    const { assets, assetTypes } = this.state;
    const assetKeys = [...Object.keys(assetTypes)];
    const assetTypeList = {};

    assetKeys.forEach(item => {
      assetTypeList[item] = item;
    });

    const tableColumns = [{ title: 'Type', field: 'title', lookup: { ...assetTypeList } }, { title: 'location', field: 'location' }];

    return (
      <div className="paper-table">
        <MaterialTable
          style={{ padding: '1em' }}
          columns={tableColumns}
          data={assets}
          title={<p className="material-table-header">Member Assets</p>}
          actions={[
            {
              icon: () => <Launch />,
              tooltip: 'View Asset',
              onClick: (asset, rowData) => {
                if (rowData && rowData.assetkey) {
                  this.viewAsset(rowData.assetkey);
                }
              },
            },
          ]}
          editable={{
            onRowAdd: newData =>
              new Promise(resolve => {
                this.addRow(newData);
                resolve();
              }),

            onRowDelete: oldData =>
              new Promise(resolve => {
                this.deleteRow(oldData);
                resolve();
              }),
          }}
        />
      </div>
    );
  }
}
export default withSnackbar(withRouter(withApollo(Assets)));

Assets.propTypes = {
  history: PropTypes.object,
  client: PropTypes.object,
  personkey: PropTypes.number,
  enqueueSnackbar: PropTypes.any,
};
