import gql from 'graphql-tag';

const getPollsResultHistory = gql`
  query PollQuery($contains: String) {
    getPollsResultHistory(contains: $contains) {
      pollkey
      polltitle
      pollquestion
      pollresponsecount
      pollcreateddate
      pollcloseddate
      pollispublished
      pollstatus
      optionresults {
        pollkey
        polloptionkey
        text
        order
        count
      }
    }
  }
`;

const getPoll = gql`
  query PollQuery($pollkey: Int!) {
    getPoll(pollkey: $pollkey) {
      pollkey
      type
      title
      question
      ispublished
      closeddate
    }
  }
`;

export const PollQueries = {
  getPollsResultHistory,
  getPoll,
};
