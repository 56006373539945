import { Analytics } from 'aws-amplify';
import { LoggingConstants } from '../constants/loggingConstants';
import AuthHelper from '../services/AuthHelper.js';

/**
 * @param {string} message The value for the log.
 * @param {string} area The area of the app the log is related to. - See loggingConstants->LoggingConstants.AREA
 */
const logError = async (message, area) => {
  try {
    const isValid = await validateLog(message, area);
    if (!isValid) {
      throw new Error('Invalid Log Parameters');
    }

    const sub = await AuthHelper.getUserSub();
    Analytics.record(LoggingConstants.TYPE.ERROR, {
      error: message,
      sub,
      area,
      dateCreated: new Date().toLocaleString(),
    });
  } catch (error) {
    console.log('Error creating log: ', error);
  }
};

/**
 * @param {string} message The value for the log.
 * @param {string} area The area of the app the log is related to. - See loggingConstants->LoggingConstants.AREA
 */
const logEvent = async (message, area) => {
  try {
    const isValid = await validateLog(message, area);
    if (!isValid) {
      throw new Error('Invalid Log Parameters');
    }

    const sub = await AuthHelper.getUserSub();
    Analytics.record(LoggingConstants.TYPE.EVENT, {
      event: message,
      sub,
      area,
      dateCreated: new Date().toLocaleString(),
    });
  } catch (error) {
    console.log('Error creating log: ', error);
  }
};

const LogHelper = {
  logError,
  logEvent,
};

export default LogHelper;

function validateLog(message, area) {
  try {
    let result = Object.prototype.hasOwnProperty.call(LoggingConstants.AREA, area);

    if (message === null || message === undefined) {
      result = false;
    }

    return result;
  } catch (error) {
    console.log('Error validating log ', error);
    return false;
  }
}
