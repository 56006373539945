import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import EventBusy from '@material-ui/icons/EventBusy';
import EventAvailable from '@material-ui/icons/EventAvailable';
import Launch from '@material-ui/icons/Launch';
import Moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withRouter } from 'react-router-dom';
import { EventMutations } from '../../../mutations/Events';
import { EventQueries } from '../../../queries/Events';

const tableColumns = [
  { title: 'Event', field: 'eventname' },
  { title: 'Confirmed', field: 'confirmed' },
  { title: 'Date Confirmed', field: 'dateconfirmed' },
  { title: 'Date Canceled', field: 'datecanceled' },
  { title: 'Details', field: 'details' },
];

class MemberEvents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      eventkey: null,
      eventname: null,
      confirmDialogOpen: false,
      cancelDialogOpen: false,
    };
  }

  componentDidMount() {
    this.fetchMemberEvents();
  }

  fetchMemberEvents = async () => {
    const { client, personkey } = this.props;

    try {
      const events = await client.query({
        query: EventQueries.GetMemberEvents,
        variables: { personkey },
      });

      if (events && events.data && events.data.getMemberEvents) {
        this.setState({
          events: events.data.getMemberEvents.map(event => {
            const formattedEvent = { ...event };

            if (event.dateconfirmed) {
              formattedEvent.dateconfirmed = Moment.utc(event.dateconfirmed)
                .local()
                .format('MMMM D, YYYY h:mm a');
            } else {
              formattedEvent.dateconfirmed = '-';
            }

            if (event.datecanceled) {
              formattedEvent.datecanceled = Moment.utc(event.datecanceled)
                .local()
                .format('MMMM D, YYYY h:mm a');
            } else {
              formattedEvent.datecanceled = '-';
            }

            if (!event.details) {
              formattedEvent.details = '-';
            }

            return formattedEvent;
          }),
        });
      }
    } catch (error) {
      console.log('Error Fetching member events: ', error);
      this.showError('Error Fetching Member Events');
    }
  };

  viewEvent = eventkey => {
    const { history } = this.props;

    history.push({
      pathname: `/events/${eventkey}`,
    });
  };

  confirmAttendance = async () => {
    const { client, personkey } = this.props;
    const { eventkey, eventname } = this.state;

    try {
      const result = await client.mutate({
        mutation: EventMutations.ConfirmEventAttendance,
        variables: { eventkey, personkey },
      });

      if (result && result.data && result.data.confirmEventAttendance) {
        const response = result.data.confirmEventAttendance;

        if (response.wassuccessful === true) {
          this.showSuccess(`Attendance Confirmed for ${eventname}`);
          this.fetchMemberEvents();
          this.closeDialog();
        } else if (response.error) {
          this.showError(response.error);
        } else {
          this.showError('Could Not Confirm Attendance');
        }
      }
    } catch (error) {
      console.log('Error confirming event attendance: ', error);
      this.showError('Error Confirming Event Attendance');
    }
  };

  cancelAttendance = async () => {
    const { client, personkey } = this.props;
    const { eventkey, eventname } = this.state;

    try {
      const result = await client.mutate({
        mutation: EventMutations.CancelEventAttendance,
        variables: { eventkey, personkey },
      });

      if (result && result.data && result.data.cancelEventAttendance) {
        const response = result.data.cancelEventAttendance;

        if (response.wassuccessful === true) {
          this.showSuccess(`Attendance Canceled for ${eventname}`);
          this.fetchMemberEvents();
          this.closeDialog();
        } else if (response.error) {
          this.showError(response.error);
        } else {
          this.showError('Could Not Cancel Attendance');
        }
      }
    } catch (error) {
      console.log('Error canceling event attendance: ', error);
      this.showError('Error Canceling Event Attendance');
    }
  };

  confirmConfirmEvent = (eventkey, name) => {
    this.setState({ eventkey, eventname: name, confirmDialogOpen: true });
  };

  confirmCancelEvent = (eventkey, name) => {
    this.setState({ eventkey, eventname: name, cancelDialogOpen: true });
  };

  closeDialog = () => {
    this.setState({ eventkey: null, eventname: null, confirmDialogOpen: false, cancelDialogOpen: false });
  };

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'success' });
  };

  render() {
    const { events, confirmDialogOpen, cancelDialogOpen, eventname } = this.state;

    return (
      <div className="paper-table">
        <Dialog open={confirmDialogOpen} onClose={this.closeDialog}>
          <DialogTitle>Confirm Event Attendance</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>Are you sure you want to confirm attendance for {eventname}?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog}>Back</Button>
            <Button onClick={this.confirmAttendance} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={cancelDialogOpen} onClose={this.closeDialog}>
          <DialogTitle>Cancel Event Attendance</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>Are you sure you want to cancel attendance for {eventname}?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog}>Back</Button>
            <Button onClick={this.cancelAttendance} color="primary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>

        <MaterialTable
          style={{ padding: '1em' }}
          title={<p className="material-table-header">Member Events</p>}
          columns={tableColumns}
          data={events}
          actions={[
            {
              icon: () => <Launch />,
              tooltip: 'View Event',
              onClick: (event, rowData) => {
                if (rowData && rowData.eventkey) {
                  this.viewEvent(rowData.eventkey);
                }
              },
            },
            rowData => ({
              icon: () => <EventAvailable />,
              tooltip: 'Confirm Attendance',
              onClick: event => this.confirmConfirmEvent(rowData.eventkey, rowData.eventname),
              disabled: rowData.confirmed === 'Yes',
            }),
            rowData => ({
              icon: () => <EventBusy />,
              tooltip: 'Cancel Attendance',
              onClick: event => this.confirmCancelEvent(rowData.eventkey, rowData.eventname),
              disabled: rowData.confirmed === 'No',
            }),
          ]}
        />
      </div>
    );
  }
}

export default withSnackbar(withRouter(withApollo(MemberEvents)));

MemberEvents.propTypes = {
  client: PropTypes.object.isRequired,
  personkey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  history: PropTypes.any,
};
