/* eslint-disable react/no-string-refs */
import React from 'react';
import moment from 'moment';
import DocumentPhoto from '../DocumentPhoto/DocumentPhoto';
import r10Logo from '../../assets/images/R10_Logo.svg';

const ADMIN_SUPPORT = 'Admin Support';
const R10_HEADQUARTERS = 'R10 Headquarters';

const renderedItems = parent =>
  parent.data.map((item, index) => {
    let fromName = item.fromname;
    if (item.role === 'Admin') {
      if (item.issupport) {
        fromName = ADMIN_SUPPORT;
      } else if (item.isbroadcast) {
        fromName = R10_HEADQUARTERS;
      }
    }
    return (
      <div key={index} className="two-col-item-container mb-2">
        <div className="two-column profile-pic">{item.role === 'Admin' ? <img src={r10Logo} alt="img" /> : <DocumentPhoto document={item.profilepicture} />}</div>
        <div className="two-column details">
          <ul className="title-date">
            <li className="title">{fromName}</li>
            <li className="date">{moment(item.createddate).format('MMM D hh:mm a')}</li>
          </ul>
          <p className="content-messages">{item.content}</p>
        </div>
      </div>
    );
  });
const MessageItems = parent => <>{renderedItems(parent)}</>;

export default MessageItems;
