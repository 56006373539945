import { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { PollQueries } from '../../queries/Poll';

class PollsProps extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      options: undefined,
      data: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    this.fetchPolls();
  }

  componentDidUpdate(prevProps) {
    const { contains } = this.props;
    if (contains !== prevProps.contains) {
      this.fetchPolls();
    }
  }

  async fetchPolls() {
    const { client, contains } = this.props;

    this.setState({ loading: true });
    const polls = await client.query({
      query: PollQueries.getPollsResultHistory,
      variables: { contains },
    });
    if (this._isMounted) {
      this.setState({
        loading: false,
        data: polls.data.getPollsResultHistory,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // eslint-disable-next-line react/destructuring-assignment
  render = () => this.props.children({ ...this.props, ...this.state });
}

PollsProps.propTypes = {
  client: PropTypes.object.isRequired,
  contains: PropTypes.string,
  children: PropTypes.any,
};

export default withApollo(PollsProps);
