/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dropzone from 'react-dropzone';
import { withSnackbar } from 'notistack';
import { withApollo } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AssetMutations } from '../../../mutations/Assets';
import { DocumentMutations } from '../../../mutations/Document';
import { AssetQueries } from '../../../queries/Assets';
import S3Helper from '../../../services/S3Helper';
import AuthHelper from '../../../services/AuthHelper';
import DocumentPhoto from '../../DocumentPhoto/DocumentPhoto';

const uuidv4 = require('uuid/v4');

class AssetPhotos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photos: [],
      photoSelections: [],
      uploading: false,
      uploaded: true,
      dialogOpen: false,
      deleteDialogOpen: false,
    };
  }

  async componentDidMount() {
    this.fetchAssetPhotos();

    const identityId = await AuthHelper.getIdentityId();
    const sub = await AuthHelper.getUserSub();
    console.log('CREDS', identityId, sub);
    this.setState({ identityId, sub });
  }

  fetchAssetPhotos = async () => {
    const { client, assetkey } = this.props;

    try {
      const result = await client.query({
        query: AssetQueries.GetAssetPhotos,
        variables: { assetkey },
      });
      console.log('RESULT', result);
      if (result && result !== undefined && result.data && result.data !== undefined && result.data.getAssetPhotos && result.data.getAssetPhotos !== undefined) {
        this.setState({ photos: result.data.getAssetPhotos });
      }
    } catch (error) {
      console.log('Error Fetching Asset Detail: ', error);
      this.showError('Error Fetching Asset Detail');
    }
  };

  openDialog = () => {
    this.setState({ dialogOpen: true, uploading: false, uploaded: false });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false, photoSelections: [], uploading: false, uploaded: false });
  };

  setPhotoSelections = photos => {
    this.setState({ photoSelections: photos });
  };

  uploadPhotos = async () => {
    const { client, assetkey } = this.props;
    const { photoSelections, identityId, sub } = this.state;

    let uploadSuccessful = true;

    try {
      this.setState({ uploading: true });

      await Promise.all(
        photoSelections.map(async file => {
          const filename = `${uuidv4()}.${file.name.split('.')[1]}`;
          const params = {
            name: filename,
            object: file,
            path: file.preview,
            access: 'protected',
            identityId,
            progressFunc: null,
          };

          const uploadResult = await S3Helper.putObject(params);
          console.log(uploadResult);
          if (uploadResult) {
            const assetPhoto = {
              assetkey,
              name: filename,
              sub,
              accesslevel: 'protected',
              isbanner: false,
            };

            const result = await client.mutate({
              mutation: AssetMutations.UploadAssetPhoto,
              variables: assetPhoto,
            });

            if (result && result !== undefined && result.data && result.data !== undefined && result.data.uploadAssetPhoto && result.data.uploadAssetPhoto !== undefined) {
              if (result.data.uploadAssetPhoto.wassuccessful === true) {
                this.showSuccess(`${file.name} uploaded`);
                this.fetchAssetPhotos();
              } else {
                this.showError(`Error uploading ${file.name}`);
                uploadSuccessful = false;
              }
            }
          } else {
            this.showError(`Error uploading ${file.name}`);
            uploadSuccessful = false;
          }
        })
      );
    } catch (error) {
      this.setState({ uploading: false });
      console.log(error);
      this.showError('Error Uploading Photo');
    }

    this.setState({ uploading: false, photoSelections: [] });

    if (uploadSuccessful) {
      this.setState({ uploaded: true });
    }

    this.fetchAssetPhotos();
  };

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'success' });
  };

  confirmDeletePhoto = documentkey => {
    this.setState({ photoToDelete: documentkey, deleteDialogOpen: true });
  };

  closeDeleteDialog = () => {
    this.setState({ photoToDelete: null, deleteDialogOpen: false });
  };

  deletePhoto = async () => {
    const { client } = this.props;
    const { photoToDelete } = this.state;

    try {
      const result = await client.mutate({
        mutation: DocumentMutations.DeleteDocument,
        variables: { documentkey: photoToDelete },
      });

      if (result && result !== undefined && result.data && result.data !== undefined && result.data.deleteDocument && result.data.deleteDocument !== undefined) {
        if (result.data.deleteDocument.wassuccessful === true) {
          this.closeDeleteDialog();
          this.showSuccess(`Photo Deleted`);
          this.fetchAssetPhotos();
        } else {
          throw new Error('Invalid API response');
        }
      }
    } catch (error) {
      this.closeDeleteDialog();
      console.log('ERROR: ', error);
      this.showError('Error Deleting Photo');
    }
  };

  render() {
    const { photos, dialogOpen, deleteDialogOpen, photoSelections, uploading, uploaded, identityId } = this.state;

    let emptyPhotoText = null;

    if (!photos || photos === undefined || photos.length === 0) {
      emptyPhotoText = <div className="photo-empty-text">No photos have been added yet</div>;
    }

    let dialogTitleFeedback = null;

    if (uploading === true) {
      dialogTitleFeedback = <CircularProgress className="event-photo-upload-feedback" />;
    } else if (uploaded === true) {
      dialogTitleFeedback = <DoneIcon className="event-photo-upload-feedback" />;
    }

    return (
      <form className="event-photos-card">
        <div className="row section">
          <Dialog open={dialogOpen} onClose={this.closeDialog}>
            <DialogTitle id="photo-dialog-title">Upload Asset Photos {dialogTitleFeedback}</DialogTitle>
            <DialogContent dividers>
              <Dropzone
                accept="image/*"
                onDrop={acceptedFiles =>
                  this.setPhotoSelections(
                    acceptedFiles.map(file =>
                      Object.assign(file, {
                        preview: URL.createObjectURL(file),
                      })
                    )
                  )
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="photo-drop-container">
                    <input {...getInputProps()} />
                    <p>Drag and drop photos here, or click to select files</p>
                  </div>
                )}
              </Dropzone>

              <div className="preview-container">
                {photoSelections.map(photo => (
                  <img src={photo.preview} className="event-preview-image" alt="upload-preview" />
                ))}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog} className="event-dialog-button">
                Cancel
              </Button>
              <Button onClick={this.uploadPhotos} className="event-dialog-button primary" color="primary" disabled={!(photoSelections && photoSelections.length > 0) || uploading === true}>
                Upload
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={deleteDialogOpen} onClose={this.closeDeleteDialog}>
            <DialogTitle>Delete Asset Photo</DialogTitle>
            <DialogContent dividers>
              <DialogContentText className="event-dialogue-text">Are you sure you want to delete this photo?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDeleteDialog} className="event-dialog-button">
                Cancel
              </Button>
              <Button onClick={this.deletePhoto} className="event-dialog-button error">
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <Tooltip title="Add Photo">
            <IconButton variant="contained" component="span" className="plus-button" onClick={this.openDialog}>
              <AddBoxIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>

        {emptyPhotoText}

        {identityId && (
          <div className="event-image-gallery">
            {photos.map(photo => (
              <div className="event-photo-container" key={photo.documentkey.toString()}>
                <DocumentPhoto document={photo} identityId={identityId} />
                <Tooltip title="Delete Photo">
                  <IconButton variant="contained" component="span" className="event-photo-delete-button" onClick={() => this.confirmDeletePhoto(photo.documentkey)}>
                    <DeleteForeverIcon fontSize="inherit" className="photo-delete-icon" />
                  </IconButton>
                </Tooltip>
              </div>
            ))}
          </div>
        )}
      </form>
    );
  }
}

export default withSnackbar(withApollo(AssetPhotos));

AssetPhotos.propTypes = {
  client: PropTypes.object.isRequired,
  assetkey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};
