import gql from 'graphql-tag';

const getMemberInterests = gql`
  query GetMemberInterests($personkey: Int!, $filtertype: String!) {
    getMemberInterests(personkey: $personkey, filtertype: $filtertype) {
      type
      key
      value
    }
  }
`;

const getMemberFilter = gql`
  query getMemberFilter($personkey: Int!, $contains: String!, $filtertype: String!) {
    getMemberFilter(personkey: $personkey, contains: $contains, filtertype: $filtertype) {
      type
      key
      value
    }
  }
`;

const getMemberFilters = gql`
  query getMemberFilters($personkey: Int!, $contains: String!) {
    getMemberFilters(personkey: $personkey, contains: $contains) {
      type
      key
      value
    }
  }
`;

const getMembersByFilter = gql`
  query getMembersByFilter($personkey: Int!, $filtertype: String!, $filterkey: Int!) {
    getMembersByFilter(personkey: $personkey, filtertype: $filtertype, filterkey: $filterkey)
  }
`;

const globalSearch = gql`
  query GlobalSearch($contains: String!) {
    globalSearch(contains: $contains) {
      type
      key
      value
    }
  }
`;

export const FilterQueries = {
  getMemberInterests,
  getMemberFilter,
  getMemberFilters,
  getMembersByFilter,
  globalSearch,
};
