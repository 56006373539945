import React from 'react';
import moment from 'moment';
import { Paper, Typography } from '@material-ui/core';

const PollCardItems = parent => {
  function handleClick(item) {
    parent.getPoll(item);
  }
  if (!Array.isArray(parent.data) || !parent.data.length) {
    return null;
  }

  return parent.data.map((item, index) => (
    <Paper key={index} className="poll-item-container paper-base" onClick={() => handleClick(item)}>
      <p className="status" title={`Closes on: ${moment(item.pollcloseddate).format('dddd, MMMM Do YYYY, h:mm:ss a')}`}>
        {item.pollstatus}
      </p>
      <ul className="title-area">
        <li className="title">
          <Typography noWrap className="title">
            {item.polltitle}
          </Typography>
        </li>
        <li className="date">{moment(item.pollcreateddate).format('MMM d')}</li>
      </ul>
      <div className="details-area">
        <p className="question">{item.pollquestion}</p>
        <table className="poll-history-table">
          <tbody>
            {item.optionresults.map(o => (
              <tr key={o.polloptionkey}>
                <td>{o.count}</td>
                <td>{o.text}</td>
                <td>{o.count > 0 ? `${Math.round((o.count / item.pollresponsecount) * 100)}%` : '0%'}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>
          Votes Received: <span className="vote-total">{item.pollresponsecount}</span>
        </p>
      </div>
    </Paper>
  ));
};

export default PollCardItems;
