import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

export default class SortIcon extends Component {
  render() {
    const { column, sortTitle, sortType } = this.props;
    console.log(sortType);
    if (sortTitle !== column || sortTitle === 'Row') {
      return '';
    }
    if (sortType === 'DESC') {
      return <ArrowDownwardIcon fontSize="inherit" />;
    }
    if (sortType === 'ASC') {
      return <ArrowUpwardIcon fontSize="inherit" />;
    }
  }
}

SortIcon.propTypes = {
  sortTitle: PropTypes.string,
  column: PropTypes.string,
  sortType: PropTypes.string,
};
