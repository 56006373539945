import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';

import { Paper, TextField, Button, FormControl } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { MessageMutations } from '../../mutations/Message';
import { ConversationMutations } from '../../mutations/Conversation';

const uuidv4 = require('uuid/v4');

class BroadcastSender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
    };
  }

  handleChange = event => {
    const { value } = event.target;
    if (value || value !== undefined) {
      this.setState({ message: value });
    }
  };

  broadcastMessage = async () => {
    const { client, sub, onMessageSent, topersonkeys, enqueueSnackbar } = this.props;
    const { message } = this.state;
    const broadcastmessageid = uuidv4();
    if (!message || message === undefined || message.length === 0) {
      enqueueSnackbar('Message required.', { variant: 'error' });
    }

    await client.mutate({
      mutation: ConversationMutations.BroadcastMessage,
      variables: { sub, content: message, topersonkeys, broadcastmessageid },
    });

    this.setState({ message: '' }, () => {
      onMessageSent();
      enqueueSnackbar('Broadcast sent.', { variant: 'success' });
    });
  };

  sendMessage = async () => {
    const { client, sub, conversationKey, onMessageSent, enqueueSnackbar } = this.props;
    const { message } = this.state;

    if (!message || message === undefined || message.length === 0) {
      enqueueSnackbar('Message required.', { variant: 'error' });
      return;
    }

    if (conversationKey === 0) {
      enqueueSnackbar('No conversation is selected.', { variant: 'error' });
      return;
    }


    await client.mutate({
      mutation: MessageMutations.SendMessage,
      variables: { conversationkey: conversationKey, sub, content: message, type: 'broadcast' },
    });

    this.setState({ message: '' }, () => {
      onMessageSent();
      enqueueSnackbar('Message sent.', { variant: 'success' });
    });
  };

  renderMessageButton = () => {
    const { conversationKey } = this.props;
    const { message } = this.state;
    return (
      <Button disabled={conversationKey === 0 || message.length === 0 || message.length >= 1001} variant="contained" className="message-submit" onClick={this.sendMessage} color="primary">
        Send
      </Button>
    );
  };

  renderBroadcastButton = () => {
    const { topersonkeys } = this.props;
    const { message } = this.state;
    const disabled = topersonkeys.length === 0 || message.length === 0 || message.length >= 1001;
    return (
      <Button disabled={disabled} variant="contained" className="message-submit" onClick={this.broadcastMessage} color="primary">
        Broadcast
      </Button>
    );
  };

  render() {
    const { message } = this.state;
    const { conversationKey } = this.props;

    return (
      <Paper className="compose-message-container paper-base">
        <div className="form-card">
          <FormControl className="form-control">
            <TextField
              label="Write a message..."
              inputProps={{
                id: 'message',
              }}
              multiline
              fullWidth
              className="message-text"
              onChange={this.handleChange}
              value={message}
            />
            <p className={message && message.length >= 1001 ? 'message-warn' : ''}>{`${message ? message.length : 0}/1000`}</p>
            <div>{conversationKey > 0 ? this.renderMessageButton() : this.renderBroadcastButton()}</div>
          </FormControl>
        </div>
      </Paper>
    );
  }
}

BroadcastSender.propTypes = {
  client: PropTypes.object.isRequired,
  sub: PropTypes.string.isRequired,
  conversationKey: PropTypes.number.isRequired,
  enqueueSnackbar: PropTypes.func,
  onMessageSent: PropTypes.func.isRequired,
  topersonkeys: PropTypes.array.isRequired,
};

export default withSnackbar(withApollo(BroadcastSender));
