import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import { NotesQueries } from '../../../queries/Notes';
import { NotesMutations } from '../../../mutations/Notes';

export class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: [],
    };
  }

  componentDidMount() {
    const { entitykey, keyType } = this.props;
    if (keyType && keyType === 'person') {
      this.fetchNotesByPerson(entitykey);
    } else if (keyType && keyType === 'event') {
      this.fetchNotesByEvent(parseInt(entitykey));
    }
  }

  fetchNotesByPerson = async personkey => {
    const { client } = this.props;
    try {
      const result = await client.query({
        query: NotesQueries.GetNotesByPerson,
        variables: { personkey },
      });
      if (result && result !== undefined) {
        const { data } = result;

        if (data && data !== undefined && data.getNotesByPerson && data.getNotesByPerson !== undefined) {
          const notesList = data.getNotesByPerson;
          notesList.map(note => {
            const formattedDate = new Date(note.createddate);
            note.formattedDate = formattedDate.toLocaleDateString('en-US');
            return note;
          });
          this.setState({
            notes: [...notesList],
          });
        } else {
          console.log('Unexpected response format');
        }
      }
    } catch (error) {
      console.log(`Error retreiving notes: ${error}`);
    }
  };

  fetchNotesByEvent = async eventkey => {
    const { client } = this.props;
    try {
      const result = await client.query({
        query: NotesQueries.GetNotesByEvent,
        variables: { eventkey },
      });
      if (result && result !== undefined) {
        const { data } = result;

        if (data && data !== undefined && data.getNotesByEvent && data.getNotesByEvent !== undefined) {
          const notesList = data.getNotesByEvent;

          notesList.map(note => {
            const formattedDate = new Date(note.createddate);
            note.formattedDate = formattedDate.toLocaleDateString('en-US');
            return note;
          });

          this.setState({
            notes: [...notesList],
          });
        } else {
          console.log('Unexpected response format');
        }
      }
    } catch (error) {
      console.log(`Error retreiving notes: ${error}`);
    }
  };

  addRow = async newData => {
    const { client, keyType, entitykey } = this.props;
    const capitalKeyType = keyType[0].toUpperCase() + keyType.slice(1);
    newData[`${keyType}key`] = parseInt(entitykey);
    try {
      const result = await client.mutate({
        mutation: NotesMutations[`Create${capitalKeyType}Note`],
        variables: { input: newData },
      });
      const { data } = result;
      if (data && data !== undefined && data[`create${capitalKeyType}Note`] && data[`create${capitalKeyType}Note`] !== undefined) {
        const { notes } = this.state;
        const newNote = data[`create${capitalKeyType}Note`];
        const formattedDate = new Date();
        newNote.formattedDate = formattedDate.toLocaleDateString('en-US');
        this.setState({ notes: [...notes, newNote] });
      }
    } catch (error) {
      if (!newData.title) {
        this.showError('Title is required for note');
      } else {
        this.showError('note update unsuccessful');
        console.error(`Error adding note: ${error}`);
      }
    }
  };

  updateRow = async (newData, oldData) => {
    const { client } = this.props;
    const { notekey, title, details } = newData;
    try {
      const result = await client.mutate({
        mutation: NotesMutations.EditNoteByID,
        variables: { title, notekey: parseInt(notekey), details },
      });
      const { data } = result;
      if (data && data !== undefined && data.editNoteByID && data.editNoteByID !== undefined) {
        const { notes } = this.state;
        const { wassuccessful } = data.editNoteByID;
        if (wassuccessful) {
          const newNotes = [...notes];
          newNotes[newNotes.indexOf(oldData)] = newData;
          this.setState({ notes: [...newNotes] });
        } else {
          this.showError('note update unsuccessful');
          throw new Error('note update unsuccessful');
        }
      }
    } catch (error) {
      console.error(`Error adding note: ${error}`);
    }
  };

  deleteRow = async oldData => {
    const { client } = this.props;
    const { notekey } = oldData;
    try {
      const result = await client.mutate({
        mutation: NotesMutations.RemoveNoteByID,
        variables: { notekey: parseInt(notekey) },
      });
      const { data } = result;
      if (data && data !== undefined && data.removeNoteByID && data.removeNoteByID !== undefined) {
        const { notes } = this.state;
        const { wassuccessful } = data.removeNoteByID;
        if (wassuccessful) {
          const newNotes = [...notes];
          newNotes.splice(newNotes.indexOf(oldData), 1);
          this.setState({ notes: [...newNotes] });
        } else {
          throw new Error('note removal unsuccessful');
        }
      }
    } catch (error) {
      console.error(`Error adding note: ${error}`);
    }
  };

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'success' });
  };

  render() {
    const { notes } = this.state;

    return (
      <div className="paper-table">
        <MaterialTable
          style={{ padding: '1em' }}
          columns={[
            { title: 'Title', field: 'title' },
            { title: 'Details', field: 'details' },
            {
              title: 'Date Added',
              field: 'formattedDate',
              defaultSort: 'asc',
              editable: 'never',
            },
          ]}
          data={notes}
          title={<p className="material-table-header">Notes</p>}
          editable={{
            onRowAdd: newData =>
              new Promise(resolve => {
                this.addRow(newData);
                resolve();
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                this.updateRow(newData, oldData);
                resolve();
              }),
            onRowDelete: oldData =>
              new Promise(resolve => {
                this.deleteRow(oldData);
                resolve();
              }),
          }}
        />
      </div>
    );
  }
}

export default withSnackbar(withApollo(Notes));

Notes.propTypes = {
  client: PropTypes.object,
  entitykey: PropTypes.number,
  enqueueSnackbar: PropTypes.func,
  keyType: PropTypes.string,
};
