import AssetDetails from './AssetDetails/AssetDetails';
import Dashboard from './Dashboard/Dashboard';
import HomePage from './HomePage/HomePage';
import Members from './Members/Members';
import Events from './Events/Events';
import EventDetails from './EventDetails/EventDetails';
import Login from './Login/Login';
import MemberDetails from './MemberDetails/MemberDetails';
import SupportMessages from './SupportMessages/SupportMessages';
import BroadcastMessages from './BroadcastMessages/BroadcastMessages';
import News from './News/News';
import Polls from './Polls/Polls';
import ReportDetails from './ReportDetails/ReportDetails';

export default {
  AssetDetails,
  HomePage,
  Dashboard,
  Members,
  Events,
  EventDetails,
  Login,
  MemberDetails,
  SupportMessages,
  BroadcastMessages,
  News,
  Polls,
  ReportDetails,
};
