import gql from 'graphql-tag';

const createInterest = gql`
  mutation CreateInterest($input: MemberFilterInput!) {
    createInterest(input: $input)
  }
`;

const deleteInterest = gql`
  mutation DeleteInterest($input: MemberFilterInput!) {
    deleteInterest(input: $input)
  }
`;

const deleteInterestOption = gql`
  mutation DeleteInterestOption($input: InterestOptionInput!) {
    deleteInterestOption(input: $input)
  }
`;

const deleteAllInterests = gql`
  mutation DeleteAllInterests($personkey: Int!, $filtertype: String!) {
    deleteAllInterests(personkey: $personkey, filtertype: $filtertype)
  }
`;

export const InterestMutations = {
  createInterest,
  deleteInterest,
  deleteInterestOption,
  deleteAllInterests,
};
