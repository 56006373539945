import gql from 'graphql-tag';

const CreateConversation = gql`
  mutation($sub: String!, $topersonkeys: [Int!]) {
    createConversation(sub: $sub, topersonkeys: $topersonkeys)
  }
`;

const AddPersonsToConversation = gql`
  mutation($sub: String!, $topersonkeys: [Int!], $conversationkey: Int!) {
    addPersonsToConversation(sub: $sub, topersonkeys: $topersonkeys, conversationkey: $conversationkey)
  }
`;

const BroadcastMessage = gql`
  mutation($sub: String!, $topersonkeys: [Int!], $content: String!, $broadcastmessageid: String) {
    broadcastMessage(sub: $sub, topersonkeys: $topersonkeys, content: $content, broadcastmessageid: $broadcastmessageid)
  }
`;

export const ConversationMutations = {
  CreateConversation,
  AddPersonsToConversation,
  BroadcastMessage,
};
