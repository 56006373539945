import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ProfilePic } from './ProfilePic';

const NotificationDashboardItems = parent => {
  if (!Array.isArray(parent.data) || !parent.data.length) {
    return null;
  }
  return parent.data.map((item, index) => (
    <Link key={index} to={`/supportmessages/${item.externalid}`} className="two-col-item-container">
      <div className="two-column profile-pic">
        <ProfilePic document={item.fromprofilepicture} />
      </div>
      <div className="two-column details">
        <ul className="title-date">
          <li className="title">{!item.fromperson.firstname ? 'You' : `${item.fromperson.firstname} ${item.fromperson.lastname}`}</li>
          <li className="date">{moment(item.createddate).format('MMM d')}</li>
        </ul>
        <p className="content">{item.message}</p>
      </div>
    </Link>
  ));
};

export default NotificationDashboardItems;
