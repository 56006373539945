import { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { ConversationQueries } from '../../queries/Conversation';

class ConversationProps extends Component {
  _intervalId = 0;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      refresh: false,
    };
  }

  componentDidMount() {
    this.fetchConversation();
    this._intervalId = setInterval(() => this.fetchConversation(), 5000);
  }

  componentDidUpdate(prevProps) {
    const { conversationKey, refresh } = this.props;

    if (conversationKey !== prevProps.conversationKey || refresh !== prevProps.refresh) {
      this.fetchConversation();
    }
  }

  componentWillUnmount() {
    clearInterval(this._intervalId);
  }

  async fetchConversation() {
    const { client, conversationKey } = this.props;
    if (conversationKey === 0) {
      this.setState({ data: [] });
      return;
    }

    this.setState({ loading: true });
    const messages = await client.query({
      query: ConversationQueries.GetConversation,
      variables: { conversationkey: conversationKey },
    });

    this.setState({
      loading: false,
      data: messages.data.getConversation,
    });
  }

  // eslint-disable-next-line react/destructuring-assignment
  render = () => this.props.children(this.state);
}
ConversationProps.propTypes = {
  client: PropTypes.object.isRequired,
  conversationKey: PropTypes.number.isRequired,
  refresh: PropTypes.any,
  children: PropTypes.any,
};

export default withApollo(ConversationProps);
