import gql from 'graphql-tag';

const GetAssetTypes = gql`
  query GetAssetTypes {
    getAssetTypes {
      title
      assettypekey
    }
  }
`;

const GetMyAssets = gql`
  query GetMyAssets($personkey: Int!) {
    getMyAssets(personkey: $personkey) {
      assetkey
      assettypekey
      location
      title
      name
    }
  }
`;

const GetAssetById = gql`
  query getAssetById($assetkey: Int!) {
    getAssetById(assetkey: $assetkey) {
      name
      personkey
      location
      description
      assetkey
      assettypekey
      title
      featuredphoto {
        documentkey
        name
        accesslevel
        identityid
      }
    }
  }
`;

const GetAssetPhotos = gql`
  query getAssetPhotos($assetkey: Int!) {
    getAssetPhotos(assetkey: $assetkey) {
      documentkey
      name
      accesslevel
      assetkey
      identityid
    }
  }
`;

export const AssetQueries = {
  GetMyAssets,
  GetAssetById,
  GetAssetTypes,
  GetAssetPhotos,
};
