import gql from 'graphql-tag';

const CreatePersonNote = gql`
  mutation($input: NewNoteInput!) {
    createPersonNote(input: $input) {
      notekey
      createddate
      title
      details
    }
  }
`;

const CreateEventNote = gql`
  mutation($input: NewNoteInput!) {
    createEventNote(input: $input) {
      notekey
      createddate
      title
      details
    }
  }
`;

const EditNoteByID = gql`
  mutation EditNoteById($title: String!, $details: String, $notekey: Int!) {
    editNoteByID(title: $title, details: $details, notekey: $notekey) {
      wassuccessful
    }
  }
`;

const RemoveNoteByID = gql`
  mutation RemoveNoteByID($notekey: Int!) {
    removeNoteByID(notekey: $notekey) {
      wassuccessful
    }
  }
`;

export const NotesMutations = {
  CreatePersonNote,
  CreateEventNote,
  EditNoteByID,
  RemoveNoteByID,
};
