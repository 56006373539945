import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import ReportSummaryCard from '../../components/ReportSummaryCard/ReportSummaryCard';
import Subheader from '../../components/Subheader/Subheader';
import MaterialDatePicker from '../../components/DatePicker';
import { ReportFilterQueries } from '../../queries/ReportFilters';

export class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDateTime: '',
      endDateTime: '',
      data: {},
      isLoading: false,
    };
  }

  componentDidMount = () => {
    this.setState({ isLoading: true });
    this.fetchSummaries();
  };

  handleChange = (date, fieldname) => {
    this.setState({ [fieldname]: date });
  };

  openReport = report => {
    const { history } = this.props;
    history.push(`/reports/${report}`);
  };

  handleClearFilters = () => {
    this.setState({ startDateTime: '', endDateTime: '' }, () => {
      this.fetchSummaries();
    });
  };

  handleSubmitFilter = () => {
    const { startDateTime, endDateTime } = this.state;
    const queryTerms = { startDateTime, endDateTime };
    this.setState({ isLoading: true });
    console.log('getting new results');
    this.fetchSummaries(queryTerms);
  };

  fetchSummaries = async (queryTerms = {}) => {
    const { client } = this.props;
    try {
      const result = await client.query({
        query: ReportFilterQueries.GetSummaryReportMetrics,
        variables: { input: queryTerms },
      });
      console.log('result', result);
      if (result && result.data && result.data.getSummaryReportMetrics) {
        const { getSummaryReportMetrics } = result.data;
        this.setState({ data: { ...getSummaryReportMetrics }, isLoading: false });
      } else {
        throw new Error('Error getting metrics');
      }
    } catch (error) {
      console.log('ERROR querying', error);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { startDateTime, endDateTime, data, isLoading } = this.state;

    return (
      <div className="master-container">
        <div className="master-content">
          <Subheader title="Reports" />
          <div className="flex justify-end mb-2 align-center row summary-filter-form">
            <div className="summary-date-input">
              <MaterialDatePicker onChangeFunc={this.handleChange} value={startDateTime || null} label="Start Date:" fieldname="startDateTime" className="member-input" />
            </div>
            <div className="summary-date-input">
              <MaterialDatePicker onChangeFunc={this.handleChange} value={endDateTime || null} label="End Date:" fieldname="endDateTime" className="member-input" />
            </div>
            <div className="summary-form-button clear-filters">
              <Button color="primary" variant="outlined" size="small" onClick={this.handleClearFilters}>
                CLEAR FILTERS
              </Button>
            </div>
            <div className="summary-form-button">
              <Button color="primary" variant="outlined" size="small" onClick={this.handleSubmitFilter}>
                FILTER
              </Button>
            </div>
          </div>
          <div className="card-wrap-container">
            <ReportSummaryCard openReport={this.openReport} theme="request" summary={data.requestSummary || null} isLoading={isLoading} summaryHeader="Call Requests" />
            <ReportSummaryCard openReport={this.openReport} theme="voice-call" summary={data.voiceCallSummary || null} isLoading={isLoading} summaryHeader="Voice Calling" />
            <ReportSummaryCard theme="video-call" summary={data.videoCallSummary || null} isLoading={isLoading} summaryHeader="Video Calling" />
            <ReportSummaryCard openReport={this.openReport} theme="vcard" summary={data.vCardSummary || null} isLoading={isLoading} summaryHeader="VCard Sharing" />
            <ReportSummaryCard openReport={this.openReport} theme="broadcast" summary={data.broadcastSummary || null} isLoading={isLoading} summaryHeader="Broadcast Messages" />
            <ReportSummaryCard openReport={this.openReport} theme="messaging" summary={data.messagingSummary || null} isLoading={isLoading} summaryHeader="Messaging" />
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(HomePage);

HomePage.propTypes = {
  history: PropTypes.object,
  client: PropTypes.object,
};
