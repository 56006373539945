import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AppContainer } from 'react-hot-loader';
import './styles/index.scss';
import Amplify from 'aws-amplify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { App } from './App';
import config from './config';

Amplify.configure(config.admin);
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
  },
});

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component />
    </AppContainer>,
    document.getElementById('root')
  );
};

render(hot(App));
