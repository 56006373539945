import gql from 'graphql-tag';

const CreateNews = gql`
  mutation CreateNews($title: String!, $content: String!, $isactive: Boolean!) {
    createNews(title: $title, content: $content, isactive: $isactive) {
      key
      wassuccessful
    }
  }
`;

const EditNewsById = gql`
  mutation editNewsById($newskey: Int!, $title: String!, $content: String!, $isactive: Boolean!) {
    editNewsById(newskey: $newskey, title: $title, content: $content, isactive: $isactive) {
      wassuccessful
    }
  }
`;

const PublishNews = gql`
  mutation publishNews($newskey: Int!) {
    publishNews(newskey: $newskey) {
      wassuccessful
    }
  }
`;

const InsertNewsPhoto = gql`
  mutation insertNewsPhoto($newskey: Int!, $name: String!, $userkey: Int!, $accesslevel: String!, $isnewsbanner: Boolean!, $isactive: Boolean!) {
    insertNewsPhoto(newskey: $newskey, name: $name, userkey: $userkey, accesslevel: $accesslevel, isnewsbanner: $isnewsbanner, isactive: $isactive)
  }
`;

const InsertNewsPhotoWithKey = gql`
  mutation insertNewsPhoto($newskey: Int!, $name: String!, $userkey: Int!, $accesslevel: String!, $isnewsbanner: Boolean!, $isactive: Boolean!, $documentkey: Int!) {
    insertNewsPhoto(newskey: $newskey, name: $name, userkey: $userkey, accesslevel: $accesslevel, isnewsbanner: $isnewsbanner, isactive: $isactive, documentkey: $documentkey)
  }
`;

export const NewsMutations = {
  CreateNews,
  EditNewsById,
  InsertNewsPhoto,
  InsertNewsPhotoWithKey,
  PublishNews,
};
