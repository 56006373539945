import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { FormControl, TextField, Paper, Fab, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Subheader from '../../components/Subheader/Subheader';
import PollProps from './PollProps';
import PollCardItems from '../../components/Polls/PollCardItems';
import PollForm from '../../components/Polls/PollForm';

class Polls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchContains: undefined,
      pollKey: 0,
    };
  }

  getPollHandler = item => {
    this.setState({
      pollKey: parseInt(item.pollkey),
    });
  };

  searchPollHandler = event => {
    const searchTerm = event.target.value;
    this.setState({ searchContains: searchTerm });
  };

  createPollHandler = () => {
    this.setState({
      pollKey: 0,
    });
  };

  pollSavedHandler = pollKey => {
    this.setState({
      pollKey,
    });
  };

  render() {
    const { searchContains, pollKey } = this.state;

    return (
      <div className="master-container">
        <div className="master-content">
          <Subheader title="Polling" />
          <div className="poll-history-container two-col-container">
            <div className="column col-one">
              <Paper className="paper-base">
                <div className="form-card search-action">
                  <FormControl className="form-control">
                    <TextField className="search" label="Search" variant="outlined" onChange={this.searchPollHandler} />
                  </FormControl>
                  <Fab color="primary" aria-label="Add" size="small" onClick={this.createPollHandler}>
                    <AddIcon />
                  </Fab>
                </div>
                <Typography>Results</Typography>
                <PollProps contains={searchContains} children={PollCardItems} getPoll={this.getPollHandler} />
              </Paper>
            </div>
            <div className="column col-two">
              <PollForm pollKey={pollKey} onSaved={this.pollSavedHandler} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(Polls);
