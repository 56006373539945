import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, Button, Paper } from '@material-ui/core';
import { withApollo } from 'react-apollo';
import { withSnackbar } from 'notistack';
import { GetMeasurements } from '../../../queries/Measurements';
import { EditMeasurements } from '../../../mutations/Measurements';

export class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      measurements: {},
    };
  }

  componentDidMount() {
    this.fetchMeasurements();
  }

  fetchMeasurements = async () => {
    const { client, personkey } = this.props;
    try {
      const result = await client.query({
        query: GetMeasurements,
        variables: { personkey },
      });
      console.log(result);
      if (result && result !== undefined) {
        const { data } = result;

        if (data && data !== undefined && data.getMeasurements !== undefined) {
          this.setState({
            initialMeasurements: { ...data.getMeasurements },
            measurements: { ...data.getMeasurements },
          });
        } else {
          console.log('Unexpected response format');
        }
      }
    } catch (error) {
      console.log(`Error retreiving measurements: ${error}`);
    }
  };

  handleSubmit = async () => {
    await this.setState({ isSaving: true });
    const { client } = this.props;
    const { measurements } = this.state;
    const newMeasurements = { ...measurements };
    delete newMeasurements.__typename;
    try {
      const result = await client.mutate({
        mutation: EditMeasurements,
        variables: {
          input: newMeasurements,
        },
      });
      const { data } = result;
      if (data && data !== undefined && data.editMeasurements && data.editMeasurements !== undefined) {
        const { wassuccessful } = data.editMeasurements;
        if (wassuccessful) {
          this.showSuccess('Measurements updated');
          this.setState({ initialMeasurements: { ...measurements }, isSaving: false });
        } else {
          throw new Error('measurement update unsuccessful');
        }
      }
    } catch (error) {
      this.setState({ isSaving: false });
      this.showError(`Error adding measurements: ${error}`);
    }
  };

  handleCancel = () => {
    const { initialMeasurements } = this.state;
    this.setState({ measurements: { ...initialMeasurements } });
  };

  handleChange = event => {
    const { name, value } = event.target;
    const { measurements } = this.state;
    const newMeasurements = measurements;
    newMeasurements[name] = value;
    this.setState({ measurements: newMeasurements });
  };

  showError = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  };

  showSuccess = message => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'success' });
  };

  render() {
    const { measurements } = this.state;
    const { isSaving } = this.state;

    return (
      <Paper className="measurements-form-container paper-base">
        <div className="row section">
          <h5 className="paper-header">Member Measurements</h5>
        </div>
        <form>
          <div className="row wrap justify-between mt-2">
            <TextField name="height" label="Height" className="member-input" value={measurements.height || ''} onChange={this.handleChange} />
            <TextField name="weight" label="Weight" className="member-input" value={measurements.weight || ''} onChange={this.handleChange} />
            <TextField name="shirtsize" label="Shirt Size" className="member-input" value={measurements.shirtsize || ''} onChange={this.handleChange} />
            <TextField name="necksize" label="Neck Size" className="member-input" value={measurements.necksize || ''} onChange={this.handleChange} />
            <TextField name="sleevelength" label="Sleeve Size" className="member-input" value={measurements.sleevelength || ''} onChange={this.handleChange} />
            <TextField name="pantsize" label="Pant Size" className="member-input" value={measurements.pantsize || ''} onChange={this.handleChange} />
            <TextField name="shoesize" label="Shoe Size" className="member-input" value={measurements.shoesize || ''} onChange={this.handleChange} />
          </div>
          <div className="row mt-4">
            <div className="column flex-3">
              <span>Fit Details</span>
              <br />
              <textarea name="fitdetails" id="bio" rows="10" value={measurements.fitdetails || ''} onChange={this.handleChange} />
            </div>
            <div className="column flex-1 justify-evenly align-center">
              {!isSaving ? (
                <Button color="primary" variant="contained" onClick={this.handleSubmit}>
                  Save
                </Button>
              ) : (
                <Button color="secondary" variant="contained" disabled>
                  Save
                </Button>
              )}
              <Button color="primary" variant="outlined" onClick={this.handleCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </Paper>
    );
  }
}

export default withSnackbar(withApollo(Notes));

Notes.propTypes = {
  client: PropTypes.object,
  personkey: PropTypes.number,
  enqueueSnackbar: PropTypes.func.isRequired,
};
