import gql from 'graphql-tag';

const GetConversation = gql`
  query ConversationQuery($conversationkey: Int!) {
    getConversation(conversationkey: $conversationkey) {
      messagekey
      conversationkey
      fromname
      content
      createddate
      issupport
      isbroadcast
      role
      profilepicture {
        documentkey
        name
        personkey
        accesslevel
        identityid
      }
    }
  }
`;

const GetConversations = gql`
  query ConversationQuery($sub: String!, $contains: String, $conversationtype: String) {
    getConversations(sub: $sub, contains: $contains, conversationtype: $conversationtype) {
      conversationkey
      createddate
      lastmessage {
        messagekey
        conversationkey
        fromname
        content
        createddate
        profilepicture {
          documentkey
          name
          personkey
          accesslevel
          identityid
        }
      }
    }
  }
`;

export const ConversationQueries = {
  GetConversation,
  GetConversations,
};
